import createLucideIcon from '../createLucideIcon';

const BookX = createLucideIcon('BookX', [
  [
    'path',
    {
      d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20',
      key: 't4utmx',
    },
  ],
  ['path', { d: 'm14.5 7-5 5', key: 'dy991v' }],
  ['path', { d: 'm9.5 7 5 5', key: 's45iea' }],
]);

export default BookX;
