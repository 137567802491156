import createLucideIcon from '../createLucideIcon';

const FileCheck2 = createLucideIcon('FileCheck2', [
  [
    'path',
    { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: '702lig' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'm3 15 2 2 4-4', key: '1lhrkk' }],
]);

export default FileCheck2;
