import createLucideIcon from '../createLucideIcon';

const FileX2 = createLucideIcon('FileX2', [
  [
    'path',
    { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: '702lig' },
  ],
  ['path', { d: 'M14 2v6h6', key: '1kof46' }],
  ['path', { d: 'm3 12.5 5 5', key: '1qls4r' }],
  ['path', { d: 'm8 12.5-5 5', key: 'b853mi' }],
]);

export default FileX2;
