import createLucideIcon from '../createLucideIcon';

const UtilityPole = createLucideIcon('UtilityPole', [
  ['path', { d: 'M12 2v20', key: 't6zp3m' }],
  ['path', { d: 'M2 5h20', key: '1fs1ex' }],
  ['path', { d: 'M3 3v2', key: '9imdir' }],
  ['path', { d: 'M7 3v2', key: 'n0os7' }],
  ['path', { d: 'M17 3v2', key: '1l2re6' }],
  ['path', { d: 'M21 3v2', key: '1duuac' }],
  ['path', { d: 'm19 5-7 7-7-7', key: '133zxf' }],
]);

export default UtilityPole;
