import createLucideIcon from '../createLucideIcon';

const BarChartBig = createLucideIcon('BarChartBig', [
  ['path', { d: 'M3 3v18h18', key: '1s2lah' }],
  [
    'rect',
    { width: '4', height: '7', x: '7', y: '10', rx: '1', key: '14u6mf' },
  ],
  [
    'rect',
    { width: '4', height: '12', x: '15', y: '5', rx: '1', key: 'b3pek6' },
  ],
]);

export default BarChartBig;
