import {ViewProps} from '../components';

import {IconType} from './IconType';
import {LocalizedTextType} from './LocalizedTextType';
import {PageSection} from './PageDesign';

export enum AppPageType {
  SERVICE,
  CONTENT,
}

export interface AppPageCommon {
  id: string;
  type?: AppPageType;
}

export type AppServicePage = AppPageCommon & {
  appletId: string;
  serviceId: string;
};

export type AppContentPage = AppPageCommon & {
  title: LocalizedTextType;
  design: PageSection[];
  props: ViewProps;
  icon?: IconType;
};

export type AppPagesGroup<T = any> = {
  id: string;
  title?: LocalizedTextType;
  pages: T[];
};

export type AppPage = AppServicePage | AppContentPage;

export class LauncherOptions {
  pageTop?: AppContentPage;
  pageBottom?: AppContentPage;
}

export class AppStructure {
  launcher = new LauncherOptions();
  pages: AppPage[] = [];
  auxiliaryPages: AppPagesGroup[] = [];
  defaultHomePage?: string;
}

// Type Guards
export function isAppServicePage(page: AppPageCommon): page is AppServicePage {
  return page.type === AppPageType.SERVICE;
}

export function isAppContentPage(page: AppPageCommon): page is AppContentPage {
  return page.type === AppPageType.CONTENT;
}
