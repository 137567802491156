import createLucideIcon from '../createLucideIcon';

const Speaker = createLucideIcon('Speaker', [
  [
    'rect',
    {
      width: '16',
      height: '20',
      x: '4',
      y: '2',
      rx: '2',
      ry: '2',
      key: '76otgf',
    },
  ],
  ['circle', { cx: '12', cy: '14', r: '4', key: '1jruaj' }],
  ['line', { x1: '12', x2: '12.01', y1: '6', y2: '6', key: '16cbga' }],
]);

export default Speaker;
