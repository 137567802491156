import React, {useMemo} from 'react';

import {useContentElementsResolver} from '../../../context';
import {ViewportSizes, AppContentPage} from '../../../types';
import {usePropsByViewPortSize} from '../../../utils';
import {ErrorBoundary} from '../../ErrorBoundary';
import {PageContainer} from '../../ScreenPage';
import {View, ViewProps} from '../../View';

import {SectionPreview} from './components/SectionPreview';

type Props = ViewProps & {
  page: AppContentPage | null;
  elements?: Record<string, any>;
  viewportSize?: ViewportSizes;
};

export const PagePreview = ({
  page,
  viewportSize = ViewportSizes.MOBILE,
  elements,
  ...props
}: Props): JSX.Element | null => {
  const builderContentElements = elements || useContentElementsResolver();
  const pageProps = usePropsByViewPortSize(page?.props);

  if (!page?.design || !Object.keys(builderContentElements).length) return null;

  const content = useMemo(
    () => (
      <ErrorBoundary>
        <View width="100%" {...pageProps} {...props} backgroundColor="$background">
          {page.design.map((section) => (
            <SectionPreview
              key={section.id}
              viewportSize={viewportSize}
              pageSection={section}
              builderContentElements={builderContentElements}
            />
          ))}
        </View>
      </ErrorBoundary>
    ),
    [page.design, viewportSize],
  );

  if (viewportSize === ViewportSizes.MOBILE) {
    return <PageContainer>{content}</PageContainer>;
  }

  return content;
};
