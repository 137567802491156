import createLucideIcon from '../createLucideIcon';

const Network = createLucideIcon('Network', [
  [
    'rect',
    { x: '16', y: '16', width: '6', height: '6', rx: '1', key: '4q2zg0' },
  ],
  [
    'rect',
    { x: '2', y: '16', width: '6', height: '6', rx: '1', key: '8cvhb9' },
  ],
  ['rect', { x: '9', y: '2', width: '6', height: '6', rx: '1', key: '1egb70' }],
  ['path', { d: 'M5 16v-3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3', key: '1jsf9p' }],
  ['path', { d: 'M12 12V8', key: '2874zd' }],
]);

export default Network;
