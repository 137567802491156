import createLucideIcon from '../createLucideIcon';

const Pipette = createLucideIcon('Pipette', [
  ['path', { d: 'm2 22 1-1h3l9-9', key: '1sre89' }],
  ['path', { d: 'M3 21v-3l9-9', key: 'hpe2y6' }],
  [
    'path',
    {
      d: 'm15 6 3.4-3.4a2.1 2.1 0 1 1 3 3L18 9l.4.4a2.1 2.1 0 1 1-3 3l-3.8-3.8a2.1 2.1 0 1 1 3-3l.4.4Z',
      key: '196du1',
    },
  ],
]);

export default Pipette;
