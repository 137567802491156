import { composeRefs } from "@tamagui/compose-refs";
import { useEvent } from "@tamagui/web";
import { useCallback, useEffect, useRef } from "react";
import { registerFocusable } from "./registerFocusable.mjs";
import { jsx } from "react/jsx-runtime";
function useFocusable({
  isInput,
  props,
  ref
}) {
  const {
      id,
      onChangeText,
      value,
      defaultValue
    } = props,
    inputValue = useRef(value || defaultValue || ""),
    unregisterFocusable = useRef(),
    inputRef = useCallback(input => {
      id && input && (unregisterFocusable.current?.(), unregisterFocusable.current = registerFocusable(id, {
        focus: input.focus,
        ...(isInput && {
          // react-native doesn't support programmatic .select()
          focusAndSelect() {
            input.focus(), input.setSelection && typeof inputValue.current == "string" && input.setSelection(0, inputValue.current.length);
          }
        })
      }));
    }, [isInput, id]),
    combinedRefs = composeRefs(ref, inputRef);
  return useEffect(() => () => {
    unregisterFocusable.current?.();
  }, []), {
    ref: combinedRefs,
    onChangeText: useEvent(value2 => {
      inputValue.current = value2, onChangeText?.(value2);
    })
  };
}
function focusableInputHOC(Component) {
  return Component.styleable((props, ref) => {
    const isInput = Component.staticConfig?.isInput,
      {
        ref: combinedRef,
        onChangeText
      } = useFocusable({
        ref,
        props,
        isInput
      }),
      finalProps = isInput ? {
        ...props,
        onChangeText
      } : props;
    return /* @__PURE__ */jsx(Component, {
      ref: combinedRef,
      ...finalProps
    });
  });
}
export { focusableInputHOC, useFocusable };