import createLucideIcon from '../createLucideIcon';

const TestTube2 = createLucideIcon('TestTube2', [
  [
    'path',
    {
      d: 'M21 7 6.82 21.18a2.83 2.83 0 0 1-3.99-.01v0a2.83 2.83 0 0 1 0-4L17 3',
      key: 'dg8b2p',
    },
  ],
  ['path', { d: 'm16 2 6 6', key: '1gw87d' }],
  ['path', { d: 'M12 16H4', key: '1cjfip' }],
]);

export default TestTube2;
