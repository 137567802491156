import createLucideIcon from '../createLucideIcon';

const RectangleHorizontal = createLucideIcon('RectangleHorizontal', [
  [
    'rect',
    { width: '20', height: '12', x: '2', y: '6', rx: '2', key: '9lu3g6' },
  ],
]);

export default RectangleHorizontal;
