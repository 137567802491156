import { createStyledContext } from "../helpers/createStyledContext.mjs";
const ComponentContext = createStyledContext({
  disableSSR: void 0,
  inText: !1,
  language: null,
  animationDriver: null,
  groups: {
    emit: null,
    subscribe: null,
    state: {}
  }
});
export { ComponentContext };