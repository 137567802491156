import createLucideIcon from '../createLucideIcon';

const Frame = createLucideIcon('Frame', [
  ['line', { x1: '22', x2: '2', y1: '6', y2: '6', key: '15w7dq' }],
  ['line', { x1: '22', x2: '2', y1: '18', y2: '18', key: '1ip48p' }],
  ['line', { x1: '6', x2: '6', y1: '2', y2: '22', key: 'a2lnyx' }],
  ['line', { x1: '18', x2: '18', y1: '2', y2: '22', key: '8vb6jd' }],
]);

export default Frame;
