import createLucideIcon from '../createLucideIcon';

const AlignVerticalJustifyEnd = createLucideIcon('AlignVerticalJustifyEnd', [
  [
    'rect',
    { width: '14', height: '6', x: '5', y: '12', rx: '2', key: '4l4tp2' },
  ],
  [
    'rect',
    { width: '10', height: '6', x: '7', y: '2', rx: '2', key: 'ypihtt' },
  ],
  ['path', { d: 'M2 22h20', key: '272qi7' }],
]);

export default AlignVerticalJustifyEnd;
