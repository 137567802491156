import { isAndroid, isIos, isWeb, useIsomorphicLayoutEffect } from "@tamagui/constants";
import { isTouchable } from "@tamagui/constants";
import { useMedia } from "@tamagui/core";
import { withStaticProperties } from "@tamagui/helpers";
import { createContext, createElement, useContext, useMemo, useState } from "react";
import { jsx } from "react/jsx-runtime";
const AdaptParentContext = createContext(null),
  AdaptContents = props => {
    const context = useContext(AdaptParentContext);
    if (!context?.Contents) throw new Error(process.env.NODE_ENV === "production" ? "tamagui.dev/docs/intro/errors#warning-002" : "You're rendering a Tamagui <Adapt /> component without nesting it inside a parent that is able to adapt.");
    return createElement(context.Contents, props);
  };
AdaptContents.shouldForwardSpace = !0;
const useAdaptParent = ({
    Contents
  }) => {
    const [when, setWhen] = useState(null);
    return {
      AdaptProvider: useMemo(() => {
        const context = {
          Contents,
          setWhen
        };
        function AdaptProviderView(props) {
          return /* @__PURE__ */jsx(AdaptParentContext.Provider, {
            value: context,
            children: props.children
          });
        }
        return AdaptProviderView;
      }, [Contents]),
      when
    };
  },
  Adapt = withStaticProperties(function ({
    platform,
    when,
    children
  }) {
    const context = useContext(AdaptParentContext),
      media = useMedia();
    let enabled = !platform;
    return platform === "touch" && (enabled = isTouchable), platform === "native" && (enabled = !isWeb), platform === "web" && (enabled = isWeb), platform === "ios" && (enabled = isIos), platform === "android" && (enabled = isAndroid), when && !media[when] && (enabled = !1), useIsomorphicLayoutEffect(() => {
      enabled && context?.setWhen(when || enabled);
    }, [when, context, enabled]), enabled ? children : null;
  }, {
    Contents: AdaptContents
  });
export { Adapt, AdaptContents, AdaptParentContext, useAdaptParent };