import createLucideIcon from '../createLucideIcon';

const UserX2 = createLucideIcon('UserX2', [
  ['path', { d: 'M14 19a6 6 0 0 0-12 0', key: 'vej9p1' }],
  ['circle', { cx: '8', cy: '9', r: '4', key: '143rtg' }],
  ['line', { x1: '17', x2: '22', y1: '8', y2: '13', key: '3nzzx3' }],
  ['line', { x1: '22', x2: '17', y1: '8', y2: '13', key: '1swrse' }],
]);

export default UserX2;
