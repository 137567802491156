import createLucideIcon from '../createLucideIcon';

const Workflow = createLucideIcon('Workflow', [
  ['rect', { width: '8', height: '8', x: '3', y: '3', rx: '2', key: 'by2w9f' }],
  ['path', { d: 'M7 11v4a2 2 0 0 0 2 2h4', key: 'xkn7yn' }],
  [
    'rect',
    { width: '8', height: '8', x: '13', y: '13', rx: '2', key: '1cgmvn' },
  ],
]);

export default Workflow;
