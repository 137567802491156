import createLucideIcon from '../createLucideIcon';

const BrainCircuit = createLucideIcon('BrainCircuit', [
  [
    'path',
    {
      d: 'M12 4.5a2.5 2.5 0 0 0-4.96-.46 2.5 2.5 0 0 0-1.98 3 2.5 2.5 0 0 0-1.32 4.24 3 3 0 0 0 .34 5.58 2.5 2.5 0 0 0 2.96 3.08 2.5 2.5 0 0 0 4.91.05L12 20V4.5Z',
      key: 'ixwj2a',
    },
  ],
  ['path', { d: 'M16 8V5c0-1.1.9-2 2-2', key: '13dx7u' }],
  ['path', { d: 'M12 13h4', key: '1ku699' }],
  ['path', { d: 'M12 18h6a2 2 0 0 1 2 2v1', key: '105ag5' }],
  ['path', { d: 'M12 8h8', key: '1lhi5i' }],
  ['path', { d: 'M20.5 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z', key: '1s25gz' }],
  ['path', { d: 'M16.5 13a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z', key: '127460' }],
  ['path', { d: 'M20.5 21a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z', key: 'fys062' }],
  ['path', { d: 'M18.5 3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z', key: '1vib61' }],
]);

export default BrainCircuit;
