import createLucideIcon from '../createLucideIcon';

const IceCream2 = createLucideIcon('IceCream2', [
  [
    'path',
    {
      d: 'M12 17c5 0 8-2.69 8-6H4c0 3.31 3 6 8 6Zm-4 4h8m-4-3v3M5.14 11a3.5 3.5 0 1 1 6.71 0',
      key: 'g86ewz',
    },
  ],
  ['path', { d: 'M12.14 11a3.5 3.5 0 1 1 6.71 0', key: '4k3m1s' }],
  ['path', { d: 'M15.5 6.5a3.5 3.5 0 1 0-7 0', key: 'zmuahr' }],
]);

export default IceCream2;
