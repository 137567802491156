import createLucideIcon from '../createLucideIcon';

const Swords = createLucideIcon('Swords', [
  ['polyline', { points: '14.5 17.5 3 6 3 3 6 3 17.5 14.5', key: '1hfsw2' }],
  ['line', { x1: '13', x2: '19', y1: '19', y2: '13', key: '1vrmhu' }],
  ['line', { x1: '16', x2: '20', y1: '16', y2: '20', key: '1bron3' }],
  ['line', { x1: '19', x2: '21', y1: '21', y2: '19', key: '13pww6' }],
  ['polyline', { points: '14.5 6.5 18 3 21 3 21 6 17.5 9.5', key: 'hbey2j' }],
  ['line', { x1: '5', x2: '9', y1: '14', y2: '18', key: '1hf58s' }],
  ['line', { x1: '7', x2: '4', y1: '17', y2: '20', key: 'pidxm4' }],
  ['line', { x1: '3', x2: '5', y1: '19', y2: '21', key: '1pehsh' }],
]);

export default Swords;
