import { fullscreenStyle } from "@tamagui/stacks";
import { styled } from "@tamagui/web";
import { ScrollView as ScrollViewNative } from "react-native-web";
const ScrollView = styled(ScrollViewNative, {
  name: "ScrollView",
  scrollEnabled: !0,
  variants: {
    fullscreen: {
      true: fullscreenStyle
    }
  }
}, {
  accept: {
    contentContainerStyle: "style"
  }
});
export { ScrollView };