import createLucideIcon from '../createLucideIcon';

const Squirrel = createLucideIcon('Squirrel', [
  [
    'path',
    {
      d: 'M18 6a4 4 0 0 0-4 4 7 7 0 0 0-7 7c0-5 4-5 4-10.5a4.5 4.5 0 1 0-9 0 2.5 2.5 0 0 0 5 0C7 10 3 11 3 17c0 2.8 2.2 5 5 5h10',
      key: '980v8a',
    },
  ],
  [
    'path',
    {
      d: 'M16 20c0-1.7 1.3-3 3-3h1a2 2 0 0 0 2-2v-2a4 4 0 0 0-4-4V4',
      key: '19wibc',
    },
  ],
  ['path', { d: 'M15.2 22a3 3 0 0 0-2.2-5', key: '13bpac' }],
  ['path', { d: 'M18 13h.01', key: '9veqaj' }],
]);

export default Squirrel;
