import createLucideIcon from '../createLucideIcon';

const AlignEndHorizontal = createLucideIcon('AlignEndHorizontal', [
  [
    'rect',
    { width: '6', height: '16', x: '4', y: '2', rx: '2', key: 'z5wdxg' },
  ],
  [
    'rect',
    { width: '6', height: '9', x: '14', y: '9', rx: '2', key: 'um7a8w' },
  ],
  ['path', { d: 'M22 22H2', key: '19qnx5' }],
]);

export default AlignEndHorizontal;
