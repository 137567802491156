import createLucideIcon from '../createLucideIcon';

const RefreshCwOff = createLucideIcon('RefreshCwOff', [
  [
    'path',
    {
      d: 'M21 8L18.74 5.74A9.75 9.75 0 0 0 12 3C11 3 10.03 3.16 9.13 3.47',
      key: '1krf6h',
    },
  ],
  ['path', { d: 'M8 16H3v5', key: '1cv678' }],
  ['path', { d: 'M3 12C3 9.51 4 7.26 5.64 5.64', key: 'ruvoct' }],
  [
    'path',
    {
      d: 'm3 16 2.26 2.26A9.75 9.75 0 0 0 12 21c2.49 0 4.74-1 6.36-2.64',
      key: '19q130',
    },
  ],
  ['path', { d: 'M21 12c0 1-.16 1.97-.47 2.87', key: '4w8emr' }],
  ['path', { d: 'M21 3v5h-5', key: '1q7to0' }],
  ['path', { d: 'M22 22 2 2', key: '1r8tn9' }],
]);

export default RefreshCwOff;
