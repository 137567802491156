import createLucideIcon from '../createLucideIcon';

const PanelTop = createLucideIcon('PanelTop', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['line', { x1: '3', x2: '21', y1: '9', y2: '9', key: '1vqk6q' }],
]);

export default PanelTop;
