import createLucideIcon from '../createLucideIcon';

const AlignCenterHorizontal = createLucideIcon('AlignCenterHorizontal', [
  ['path', { d: 'M2 12h20', key: '9i4pu4' }],
  ['path', { d: 'M10 16v4a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-4', key: '11f1s0' }],
  ['path', { d: 'M10 8V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v4', key: 't14dx9' }],
  ['path', { d: 'M20 16v1a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-1', key: '1w07xs' }],
  ['path', { d: 'M14 8V7c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v1', key: '1apec2' }],
]);

export default AlignCenterHorizontal;
