import createLucideIcon from '../createLucideIcon';

const ShowerHead = createLucideIcon('ShowerHead', [
  ['path', { d: 'm4 4 2.5 2.5', key: 'uv2vmf' }],
  ['path', { d: 'M13.5 6.5a4.95 4.95 0 0 0-7 7', key: 'frdkwv' }],
  ['path', { d: 'M15 5 5 15', key: '1ag8rq' }],
  ['path', { d: 'M14 17v.01', key: 'eokfpp' }],
  ['path', { d: 'M10 16v.01', key: '14uyyl' }],
  ['path', { d: 'M13 13v.01', key: '1v1k97' }],
  ['path', { d: 'M16 10v.01', key: '5169yg' }],
  ['path', { d: 'M11 20v.01', key: 'cj92p8' }],
  ['path', { d: 'M17 14v.01', key: '11cswd' }],
  ['path', { d: 'M20 11v.01', key: '19e0od' }],
]);

export default ShowerHead;
