import createLucideIcon from '../createLucideIcon';

const BadgeX = createLucideIcon('BadgeX', [
  [
    'path',
    {
      d: 'M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z',
      key: '3c2336',
    },
  ],
  ['line', { x1: '15', x2: '9', y1: '9', y2: '15', key: 'f7djnv' }],
  ['line', { x1: '9', x2: '15', y1: '9', y2: '15', key: '1shsy8' }],
]);

export default BadgeX;
