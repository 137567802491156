import React from 'react';

import {BuilderElement, NodeType} from '../../../types';
import {View, ViewProps} from '../../View';
import {YoutubePlayer, YoutubePlayerProps} from '../../YoutubePlayer';

type YoutubePlayerElementProps = ViewProps & YoutubePlayerProps;

const defaultProps: YoutubePlayerElementProps = {
  style: {maxWidth: '100%', height: 200},
  url: 'https://www.youtube.com/watch?v=Zi1TEZ9PDHI',
};

export const Component = (props: YoutubePlayerElementProps) => {
  return (
    <View {...props}>
      <YoutubePlayer key={props.url} url={props.url} />
    </View>
  );
};

export const YoutubePlayerElement: BuilderElement<YoutubePlayerElementProps> = {
  id: 'YoutubePlayerElement',
  icon: 'Youtube',
  nodeId: '',
  Component,
  type: NodeType.VIDEO,
  props: defaultProps,
};
