import createLucideIcon from '../createLucideIcon';

const CaseLower = createLucideIcon('CaseLower', [
  ['circle', { cx: '7', cy: '12', r: '3', key: '12clwm' }],
  ['path', { d: 'M10 9v6', key: '17i7lo' }],
  ['circle', { cx: '17', cy: '12', r: '3', key: 'gl7c2s' }],
  ['path', { d: 'M14 7v8', key: 'dl84cr' }],
]);

export default CaseLower;
