import createLucideIcon from '../createLucideIcon';

const Slack = createLucideIcon('Slack', [
  [
    'rect',
    { width: '3', height: '8', x: '13', y: '2', rx: '1.5', key: 'diqz80' },
  ],
  ['path', { d: 'M19 8.5V10h1.5A1.5 1.5 0 1 0 19 8.5', key: '183iwg' }],
  [
    'rect',
    { width: '3', height: '8', x: '8', y: '14', rx: '1.5', key: 'hqg7r1' },
  ],
  ['path', { d: 'M5 15.5V14H3.5A1.5 1.5 0 1 0 5 15.5', key: '76g71w' }],
  [
    'rect',
    { width: '8', height: '3', x: '14', y: '13', rx: '1.5', key: '1kmz0a' },
  ],
  ['path', { d: 'M15.5 19H14v1.5a1.5 1.5 0 1 0 1.5-1.5', key: 'jc4sz0' }],
  [
    'rect',
    { width: '8', height: '3', x: '2', y: '8', rx: '1.5', key: '1omvl4' },
  ],
  ['path', { d: 'M8.5 5H10V3.5A1.5 1.5 0 1 0 8.5 5', key: '16f3cl' }],
]);

export default Slack;
