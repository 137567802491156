import createLucideIcon from '../createLucideIcon';

const SaveAll = createLucideIcon('SaveAll', [
  [
    'path',
    {
      d: 'M6 4a2 2 0 0 1 2-2h10l4 4v10.2a2 2 0 0 1-2 1.8H8a2 2 0 0 1-2-2Z',
      key: '1unput',
    },
  ],
  ['path', { d: 'M10 2v4h6', key: '1p5sg6' }],
  ['path', { d: 'M18 18v-7h-8v7', key: '1oniuk' }],
  ['path', { d: 'M18 22H4a2 2 0 0 1-2-2V6', key: 'pblm9e' }],
]);

export default SaveAll;
