import createLucideIcon from '../createLucideIcon';

const AlignHorizontalDistributeEnd = createLucideIcon(
  'AlignHorizontalDistributeEnd',
  [
    [
      'rect',
      { width: '6', height: '14', x: '4', y: '5', rx: '2', key: '1wwnby' },
    ],
    [
      'rect',
      { width: '6', height: '10', x: '14', y: '7', rx: '2', key: '1fe6j6' },
    ],
    ['path', { d: 'M10 2v20', key: 'uyc634' }],
    ['path', { d: 'M20 2v20', key: '1tx262' }],
  ],
);

export default AlignHorizontalDistributeEnd;
