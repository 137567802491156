import createLucideIcon from '../createLucideIcon';

const ParkingSquareOff = createLucideIcon('ParkingSquareOff', [
  [
    'path',
    {
      d: 'M3.6 3.6A2 2 0 0 1 5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-.59 1.41',
      key: '9l1ft6',
    },
  ],
  ['path', { d: 'M3 8.7V19a2 2 0 0 0 2 2h10.3', key: '17knke' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  ['path', { d: 'M13 13a3 3 0 1 0 0-6H9v2', key: 'uoagbd' }],
  ['path', { d: 'M9 17v-2.3', key: '1jxgo2' }],
]);

export default ParkingSquareOff;
