import createLucideIcon from '../createLucideIcon';

const Club = createLucideIcon('Club', [
  [
    'path',
    {
      d: 'M17.28 9.05a5.5 5.5 0 1 0-10.56 0A5.5 5.5 0 1 0 12 17.66a5.5 5.5 0 1 0 5.28-8.6Z',
      key: '27yuqz',
    },
  ],
  ['path', { d: 'M12 17.66L12 22', key: 'ogfahf' }],
]);

export default Club;
