import createLucideIcon from '../createLucideIcon';

const TextQuote = createLucideIcon('TextQuote', [
  ['path', { d: 'M17 6H3', key: '16j9eg' }],
  ['path', { d: 'M21 12H8', key: 'scolzb' }],
  ['path', { d: 'M21 18H8', key: '1wfozv' }],
  ['path', { d: 'M3 12v6', key: 'fv4c87' }],
]);

export default TextQuote;
