import { forwardRef } from "react";
import { Theme } from "../views/Theme.mjs";
import { jsx } from "react/jsx-runtime";
function themeable(Component, staticConfig) {
  const withTheme = forwardRef(function (props, ref) {
    const {
        themeInverse,
        theme,
        componentName,
        themeReset,
        ...rest
      } = props,
      element =
      // @ts-expect-error its ok
      /* @__PURE__ */
      jsx(Component, {
        ref,
        ...rest,
        "data-disable-theme": !0
      });
    return /* @__PURE__ */jsx(Theme, {
      componentName: componentName || staticConfig?.componentName,
      name: theme,
      "disable-child-theme": !0,
      debug: props.debug,
      inverse: themeInverse,
      reset: themeReset,
      children: element
    });
  });
  return withTheme.displayName = `Themed(${Component?.displayName || Component?.name || "Anonymous"})`, withTheme;
}
export { themeable };