import createLucideIcon from '../createLucideIcon';

const BookLock = createLucideIcon('BookLock', [
  ['path', { d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H10', key: '18wgow' }],
  ['path', { d: 'M20 15v7H6.5a2.5 2.5 0 0 1 0-5H20', key: 'dpch1j' }],
  [
    'rect',
    { width: '8', height: '5', x: '12', y: '6', rx: '1', key: '9nqwug' },
  ],
  ['path', { d: 'M18 6V4a2 2 0 1 0-4 0v2', key: '1aquzs' }],
]);

export default BookLock;
