import createLucideIcon from '../createLucideIcon';

const SquareEqual = createLucideIcon('SquareEqual', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M7 10h10', key: '1101jm' }],
  ['path', { d: 'M7 14h10', key: '1mhdw3' }],
]);

export default SquareEqual;
