import createLucideIcon from '../createLucideIcon';

const Clover = createLucideIcon('Clover', [
  [
    'path',
    {
      d: 'M16.2 3.8a2.7 2.7 0 0 0-3.81 0l-.4.38-.4-.4a2.7 2.7 0 0 0-3.82 0C6.73 4.85 6.67 6.64 8 8l4 4 4-4c1.33-1.36 1.27-3.15.2-4.2z',
      key: '1gxwox',
    },
  ],
  [
    'path',
    {
      d: 'M8 8c-1.36-1.33-3.15-1.27-4.2-.2a2.7 2.7 0 0 0 0 3.81l.38.4-.4.4a2.7 2.7 0 0 0 0 3.82C4.85 17.27 6.64 17.33 8 16',
      key: 'il7z7z',
    },
  ],
  [
    'path',
    {
      d: 'M16 16c1.36 1.33 3.15 1.27 4.2.2a2.7 2.7 0 0 0 0-3.81l-.38-.4.4-.4a2.7 2.7 0 0 0 0-3.82C19.15 6.73 17.36 6.67 16 8',
      key: '15bpx2',
    },
  ],
  [
    'path',
    {
      d: 'M7.8 20.2a2.7 2.7 0 0 0 3.81 0l.4-.38.4.4a2.7 2.7 0 0 0 3.82 0c1.06-1.06 1.12-2.85-.21-4.21l-4-4-4 4c-1.33 1.36-1.27 3.15-.2 4.2z',
      key: 'v9mug8',
    },
  ],
  ['path', { d: 'm7 17-5 5', key: '1py3mz' }],
]);

export default Clover;
