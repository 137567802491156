function createShallowSetState(setter, isDisabled, debug) {
  return next => setter(prev => mergeIfNotShallowEqual(prev, next, isDisabled, debug));
}
function mergeIfNotShallowEqual(prev, next, isDisabled, debug) {
  if (isDisabled || !prev || !next || isEqualShallow(prev, next)) return prev || next;
  if (process.env.NODE_ENV === "development" && debug && (console.warn("setStateShallow CHANGE", {
    prev,
    next
  }), debug === "break")) debugger;
  return {
    ...prev,
    ...next
  };
}
function isEqualShallow(prev, next) {
  for (const key in next) if (prev[key] !== next[key]) return !1;
  return !0;
}
export { createShallowSetState, isEqualShallow, mergeIfNotShallowEqual };