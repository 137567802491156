import React, {forwardRef, ReactNode, useEffect, useRef} from 'react';

import {Platform} from 'react-native';
import {Input as BaseInput, YStack} from 'tamagui';

import {IconType} from '../../types';
import {useAppDirection} from '../../utils/useAppDirection';
import {IconView} from '../IconView';
import {Typography} from '../Typography';
import {View} from '../View';

import {InputProps} from './types';

const iconPerInputType: {[key: string]: IconType} = {
  search: 'Search',
  email: 'Mail',
};

// todo : change the implementation

export const _Input = (
  {
    label,
    type,
    value,
    placeholder,
    prefix,
    suffix,
    disabled,
    error,
    subtext,
    selectTextOnFocus = true,
    editable = true,
    textAlign,
    id,
    className,
    size,
    borderRadius,
    onChange,
    onChangeText,
    onBlur,
    inline,
    baseInputProps,
    containerRef,
    ...props
  }: InputProps,
  ref,
) => {
  const {isLTR, isRTL, direction} = useAppDirection();
  const inputRef = useRef<any>();

  const typeIcon = type && iconPerInputType[type];
  const iconPosition = isRTL ? {right: '$18'} : {left: '$18'};
  const disabledBackgroundColor = disabled ? '$neutral-200' : '$transparent';
  const containerFlexDir = isLTR ? 'row' : 'row-reverse';
  const inputTextAlign = (isRTL && 'right') || undefined;
  const inputPadding = prefix || typeIcon ? '$44' : '$4.5';
  const borderColor = inline ? '$transparent' : error ? '$red-600' : '$border';
  const focusBorderColor = inline ? '$transparent' : disabled ? undefined : '$accent';
  const hoverBorderColor = inline ? '$transparent' : disabled ? undefined : '$borderHover';

  useEffect(() => {
    if (Platform.OS === 'web' && inputRef?.current && type) {
      inputRef.current.type = type === 'numeric' ? 'number' : type;
    }
  }, [inputRef?.current]);

  return (
    <InputWithLabel label={label} {...props}>
      <View
        theme="translucent"
        backgroundColor="$background"
        ref={containerRef}
        flexDirection="column"
        width="100%"
        {...props}>
        <View position="relative" width="100%" flexDirection={containerFlexDir} alignItems="center">
          {typeIcon &&
            renderPrefix(
              {right: iconPosition.right, left: iconPosition.left},
              <IconView icon={iconPerInputType[type]} size={17} color="$neutral-600" />,
            )}
          {!typeIcon && prefix && renderPrefix({right: iconPosition.right, left: iconPosition.left}, prefix)}
          <BaseInput
            className={className}
            id={id}
            direction={direction}
            inputMode={type as any}
            onBlur={(e) => onBlur && onBlur(e)}
            secureTextEntry={type === 'password'}
            keyboardType={type === 'number' ? 'number-pad' : 'default'}
            editable={editable && !disabled}
            selectTextOnFocus={selectTextOnFocus && !disabled}
            borderRadius={borderRadius}
            paddingLeft={inputPadding}
            disabled={disabled}
            paddingRight={inputPadding}
            fontWeight="600"
            backgroundColor={disabledBackgroundColor}
            textAlign={textAlign || inputTextAlign}
            size={size}
            placeholderTextColor={disabled ? 'neutral-600' : '$placeholder'}
            borderColor={borderColor}
            focusStyle={{borderColor: focusBorderColor}}
            hoverStyle={{borderColor: hoverBorderColor}}
            outlineColor="$transparent"
            placeholder={placeholder}
            value={value}
            onChangeText={onChangeText}
            onChange={onChange}
            flex={1}
            alignItems="center"
            ref={(element) => {
              inputRef.current = element;
              if (ref) ref.current = element;
            }}
            {...baseInputProps}
          />
          {suffix && renderPrefix({right: iconPosition.left, left: iconPosition.right}, suffix)}
        </View>
        {subtext && <Typography color={error ? '$red-600' : ''}>{subtext}</Typography>}
      </View>
    </InputWithLabel>
  );
};

const InputWithLabel = ({children, label, ...props}: {children: ReactNode; label?: ReactNode}) => {
  if (label) {
    return (
      <YStack gap="$8" {...props}>
        {label}
        {children}
      </YStack>
    );
  }

  return children;
};

const renderPrefix = ({right, left}, icon) => (
  <View position="absolute" backgroundColor="transparent" zIndex={4} right={right} left={left}>
    {icon}
  </View>
);

export const Input = forwardRef(_Input);
