import React from 'react';

import {AnimatePresence, Tabs as BaseTabs} from 'tamagui';

import {IconView} from '../../IconView';
import {Typography} from '../../Typography';
import {View} from '../../View';
import {TabsRovingIndicator} from '../components/TabsRovingIndicator';
import {TabsProps, TabsState, TabsTabProps} from '../types';

type Props = {
  tabs: TabsProps['tabs'];
  appDirection: 'ltr' | 'rtl';
  currentTab: number;
  handleOnInteraction: TabsTabProps['handleOnInteraction'];
  activeAt: TabsState['activeAt'];
};

export const BaseTabsList = ({tabs, appDirection, currentTab, activeAt, handleOnInteraction}: Props) => {
  return (
    <BaseTabs.List width="100%" disablePassBorderRadius loop={false} direction={appDirection} space="$2">
      {tabs?.map((tab, index) => {
        const isActiveTab = currentTab === index;
        const activeTabColor = isActiveTab ? '$accent' : '';

        return (
          <BaseTabs.Tab
            direction={appDirection}
            key={index}
            unstyled
            $gtXs={{flex: 1, width: '100%'}}
            value={'' + index}
            onInteraction={handleOnInteraction}
            padding="$10"
            backgroundColor="$transparent"
            borderWidth="$0">
            {tab.icon && (
              <View marginHorizontal="$2">
                <IconView size={19} icon={tab.icon} color={!!activeTabColor ? '$accent' : '$neutral-600'} />
              </View>
            )}

            <Typography color={activeTabColor}>{tab.label}</Typography>

            <AnimatePresence>
              {isActiveTab && (
                <TabsRovingIndicator
                  key={index}
                  active={!!activeTabColor}
                  width={activeAt?.width || 0}
                  height="$0.5"
                  bottom={0}
                />
              )}
            </AnimatePresence>
          </BaseTabs.Tab>
        );
      })}
    </BaseTabs.List>
  );
};
