import createLucideIcon from '../createLucideIcon';

const School = createLucideIcon('School', [
  ['path', { d: 'm4 6 8-4 8 4', key: '1q0ilc' }],
  [
    'path',
    { d: 'm18 10 4 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8l4-2', key: '1vwozw' },
  ],
  ['path', { d: 'M14 22v-4a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v4', key: 'cpkuc4' }],
  ['path', { d: 'M18 5v17', key: '1sw6gf' }],
  ['path', { d: 'M6 5v17', key: '1xfsm0' }],
  ['circle', { cx: '12', cy: '9', r: '2', key: '1092wv' }],
]);

export default School;
