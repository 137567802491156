import { autoUpdate, offset, useFloating } from "@floating-ui/react";
import { useComposedRefs } from "@tamagui/compose-refs";
import { YStack } from "@tamagui/stacks";
import * as React from "react";
import { flushSync } from "react-dom";
import { useSelectContext } from "./context.mjs";
import { jsx } from "react/jsx-runtime";
const SCROLL_UP_BUTTON_NAME = "SelectScrollUpButton",
  SelectScrollUpButton = React.forwardRef((props, forwardedRef) => /* @__PURE__ */jsx(SelectScrollButtonImpl, {
    componentName: SCROLL_UP_BUTTON_NAME,
    ...props,
    dir: "up",
    ref: forwardedRef
  }));
SelectScrollUpButton.displayName = SCROLL_UP_BUTTON_NAME;
const SCROLL_DOWN_BUTTON_NAME = "SelectScrollDownButton",
  SelectScrollDownButton = React.forwardRef((props, forwardedRef) => /* @__PURE__ */jsx(SelectScrollButtonImpl, {
    componentName: SCROLL_DOWN_BUTTON_NAME,
    ...props,
    dir: "down",
    ref: forwardedRef
  }));
SelectScrollDownButton.displayName = SCROLL_DOWN_BUTTON_NAME;
const SelectScrollButtonImpl = React.memo(React.forwardRef((props, forwardedRef) => {
  const {
      __scopeSelect,
      dir,
      componentName,
      ...scrollIndicatorProps
    } = props,
    {
      forceUpdate,
      open,
      fallback,
      setScrollTop,
      setInnerOffset,
      ...context
    } = useSelectContext(componentName, __scopeSelect),
    floatingRef = context.floatingContext?.refs.floating,
    statusRef = React.useRef("idle"),
    isVisible = context[dir === "down" ? "canScrollDown" : "canScrollUp"],
    frameRef = React.useRef(),
    {
      x,
      y,
      refs,
      strategy
    } = useFloating({
      open: open && isVisible,
      strategy: "fixed",
      elements: {
        reference: floatingRef?.current
      },
      placement: dir === "up" ? "top" : "bottom",
      middleware: [offset(({
        rects
      }) => -rects.floating.height)],
      whileElementsMounted: (...args) => autoUpdate(...args, {
        animationFrame: !0
      })
    }),
    composedRef = useComposedRefs(forwardedRef, refs.setFloating);
  if (!isVisible) return null;
  const onScroll = amount => {
    const floating = floatingRef;
    floating && (fallback ? floating.current && (floating.current.scrollTop -= amount, flushSync(() => setScrollTop(floating.current?.scrollTop ?? 0))) : flushSync(() => setInnerOffset(value => value - amount)));
  };
  return /* @__PURE__ */jsx(YStack, {
    ref: composedRef,
    componentName,
    "aria-hidden": !0,
    ...scrollIndicatorProps,
    zIndex: 1e3,
    position: strategy,
    left: x || 0,
    top: y || 0,
    width: `calc(${(floatingRef?.current?.offsetWidth ?? 0) - 2}px)`,
    onPointerEnter: () => {
      statusRef.current = "active";
      let prevNow = Date.now();
      function frame() {
        const element = floatingRef?.current;
        if (element) {
          const currentNow = Date.now(),
            msElapsed = currentNow - prevNow;
          prevNow = currentNow;
          const pixelsToScroll = msElapsed / 2,
            remainingPixels = dir === "up" ? element.scrollTop : element.scrollHeight - element.clientHeight - element.scrollTop,
            scrollRemaining = dir === "up" ? element.scrollTop - pixelsToScroll > 0 : element.scrollTop + pixelsToScroll < element.scrollHeight - element.clientHeight;
          onScroll(dir === "up" ? Math.min(pixelsToScroll, remainingPixels) : Math.max(-pixelsToScroll, -remainingPixels)), scrollRemaining && (frameRef.current = requestAnimationFrame(frame));
        }
      }
      cancelAnimationFrame(frameRef.current), frameRef.current = requestAnimationFrame(frame);
    },
    onPointerLeave: () => {
      statusRef.current = "idle", cancelAnimationFrame(frameRef.current);
    }
  });
}));
export { SelectScrollDownButton, SelectScrollUpButton };