import { createCollection } from "@tamagui/collection";
import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import { Stack, createStyledContext, useEvent } from "@tamagui/core";
import { composeEventHandlers, withStaticProperties } from "@tamagui/helpers";
import { useControllableState } from "@tamagui/use-controllable-state";
import { useDirection } from "@tamagui/use-direction";
import * as React from "react";
import { jsx } from "react/jsx-runtime";
const ENTRY_FOCUS = "rovingFocusGroup.onEntryFocus",
  EVENT_OPTIONS = {
    bubbles: !1,
    cancelable: !0
  },
  RovingFocusGroupImpl = React.forwardRef((props, forwardedRef) => {
    const {
        __scopeRovingFocusGroup,
        orientation,
        loop = !1,
        dir,
        currentTabStopId: currentTabStopIdProp,
        defaultCurrentTabStopId,
        onCurrentTabStopIdChange,
        onEntryFocus,
        ...groupProps
      } = props,
      ref = React.useRef(null),
      composedRefs = useComposedRefs(forwardedRef, ref),
      direction = useDirection(dir),
      [currentTabStopId = null, setCurrentTabStopId] = useControllableState({
        prop: currentTabStopIdProp,
        defaultProp: defaultCurrentTabStopId ?? null,
        onChange: onCurrentTabStopIdChange
      }),
      [isTabbingBackOut, setIsTabbingBackOut] = React.useState(!1),
      handleEntryFocus = useEvent(onEntryFocus),
      getItems = useCollection(__scopeRovingFocusGroup || ROVING_FOCUS_GROUP_CONTEXT),
      isClickFocusRef = React.useRef(!1),
      [focusableItemsCount, setFocusableItemsCount] = React.useState(0);
    return React.useEffect(() => {
      const node = ref.current;
      if (node) return node.addEventListener(ENTRY_FOCUS, handleEntryFocus), () => node.removeEventListener(ENTRY_FOCUS, handleEntryFocus);
    }, [handleEntryFocus]), /* @__PURE__ */jsx(RovingFocusProvider, {
      scope: __scopeRovingFocusGroup,
      orientation,
      dir: direction,
      loop,
      currentTabStopId,
      onItemFocus: React.useCallback(tabStopId => setCurrentTabStopId(tabStopId), [setCurrentTabStopId]),
      onItemShiftTab: React.useCallback(() => setIsTabbingBackOut(!0), []),
      onFocusableItemAdd: React.useCallback(() => setFocusableItemsCount(prevCount => prevCount + 1), []),
      onFocusableItemRemove: React.useCallback(() => setFocusableItemsCount(prevCount => prevCount - 1), []),
      children: /* @__PURE__ */jsx(Stack, {
        tabIndex: isTabbingBackOut || focusableItemsCount === 0 ? -1 : 0,
        "data-orientation": orientation,
        ...groupProps,
        ref: composedRefs,
        style: [{
          outline: "none"
        }, props.style],
        onMouseDown: composeEventHandlers(props.onMouseDown, () => {
          isClickFocusRef.current = !0;
        }),
        onFocus: composeEventHandlers(props.onFocus, event => {
          const isKeyboardFocus = !isClickFocusRef.current;
          if (event.target === event.currentTarget && isKeyboardFocus && !isTabbingBackOut) {
            const entryFocusEvent = new CustomEvent(ENTRY_FOCUS, EVENT_OPTIONS);
            if (event.currentTarget.dispatchEvent(entryFocusEvent), !entryFocusEvent.defaultPrevented) {
              const items = getItems().filter(item => item.focusable),
                activeItem = items.find(item => item.active),
                currentItem = items.find(item => item.id === currentTabStopId),
                candidateNodes = [activeItem, currentItem, ...items].filter(Boolean).map(item => item.ref.current);
              focusFirst(candidateNodes);
            }
          }
          isClickFocusRef.current = !1;
        }),
        onBlur: composeEventHandlers(props.onBlur, () => setIsTabbingBackOut(!1))
      })
    });
  }),
  ITEM_NAME = "RovingFocusGroupItem",
  RovingFocusGroupItem = React.forwardRef((props, forwardedRef) => {
    const {
        __scopeRovingFocusGroup,
        focusable = !0,
        active = !1,
        tabStopId,
        ...itemProps
      } = props,
      autoId = React.useId(),
      id = tabStopId || autoId,
      context = useRovingFocusContext(__scopeRovingFocusGroup),
      isCurrentTabStop = context.currentTabStopId === id,
      getItems = useCollection(__scopeRovingFocusGroup || ROVING_FOCUS_GROUP_CONTEXT),
      {
        onFocusableItemAdd,
        onFocusableItemRemove
      } = context;
    return React.useEffect(() => {
      if (focusable) return onFocusableItemAdd(), () => onFocusableItemRemove();
    }, [focusable, onFocusableItemAdd, onFocusableItemRemove]), /* @__PURE__ */jsx(Collection.ItemSlot, {
      __scopeCollection: __scopeRovingFocusGroup || ROVING_FOCUS_GROUP_CONTEXT,
      id,
      focusable,
      active,
      children: /* @__PURE__ */jsx(Stack, {
        tabIndex: isCurrentTabStop ? 0 : -1,
        "data-orientation": context.orientation,
        ...itemProps,
        ref: forwardedRef,
        onMouseDown: composeEventHandlers(props.onMouseDown, event => {
          focusable ? context.onItemFocus(id) : event.preventDefault();
        }),
        onFocus: composeEventHandlers(props.onFocus, () => context.onItemFocus(id)),
        ...(isWeb && {
          onKeyDown: composeEventHandlers(props.onKeyDown, event => {
            if (event.key === "Tab" && event.shiftKey) {
              context.onItemShiftTab();
              return;
            }
            if (event.target !== event.currentTarget) return;
            const focusIntent = getFocusIntent(event, context.orientation, context.dir);
            if (focusIntent !== void 0) {
              event.preventDefault();
              let candidateNodes = getItems().filter(item => item.focusable).map(item => item.ref.current);
              if (focusIntent === "last") candidateNodes.reverse();else if (focusIntent === "prev" || focusIntent === "next") {
                focusIntent === "prev" && candidateNodes.reverse();
                const currentIndex = candidateNodes.indexOf(event.currentTarget);
                candidateNodes = context.loop ? wrapArray(candidateNodes, currentIndex + 1) : candidateNodes.slice(currentIndex + 1);
              }
              setTimeout(() => focusFirst(candidateNodes));
            }
          })
        })
      })
    });
  });
RovingFocusGroupItem.displayName = ITEM_NAME;
const GROUP_NAME = "RovingFocusGroup",
  [Collection, useCollection] = createCollection(GROUP_NAME),
  {
    Provider: RovingFocusProvider,
    useStyledContext: useRovingFocusContext
  } = createStyledContext(),
  ROVING_FOCUS_GROUP_CONTEXT = "RovingFocusGroupContext",
  RovingFocusGroup = withStaticProperties(React.forwardRef((props, forwardedRef) => /* @__PURE__ */jsx(Collection.Provider, {
    __scopeCollection: props.__scopeRovingFocusGroup || ROVING_FOCUS_GROUP_CONTEXT,
    children: /* @__PURE__ */jsx(Collection.Slot, {
      __scopeCollection: props.__scopeRovingFocusGroup || ROVING_FOCUS_GROUP_CONTEXT,
      children: /* @__PURE__ */jsx(RovingFocusGroupImpl, {
        ...props,
        ref: forwardedRef
      })
    })
  })), {
    Item: RovingFocusGroupItem
  });
RovingFocusGroup.displayName = GROUP_NAME;
const MAP_KEY_TO_FOCUS_INTENT = {
  ArrowLeft: "prev",
  ArrowUp: "prev",
  ArrowRight: "next",
  ArrowDown: "next",
  PageUp: "first",
  Home: "first",
  PageDown: "last",
  End: "last"
};
function getDirectionAwareKey(key, dir) {
  return dir !== "rtl" ? key : key === "ArrowLeft" ? "ArrowRight" : key === "ArrowRight" ? "ArrowLeft" : key;
}
function getFocusIntent(event, orientation, dir) {
  const key = getDirectionAwareKey(event.key, dir);
  if (!(orientation === "vertical" && ["ArrowLeft", "ArrowRight"].includes(key)) && !(orientation === "horizontal" && ["ArrowUp", "ArrowDown"].includes(key))) return MAP_KEY_TO_FOCUS_INTENT[key];
}
function focusFirst(candidates) {
  const PREVIOUSLY_FOCUSED_ELEMENT = document.activeElement;
  for (const candidate of candidates) if (candidate === PREVIOUSLY_FOCUSED_ELEMENT || (candidate.focus(), document.activeElement !== PREVIOUSLY_FOCUSED_ELEMENT)) return;
}
function wrapArray(array, startIndex) {
  return array.map((_, index) => array[(startIndex + index) % array.length]);
}
export { RovingFocusGroup };