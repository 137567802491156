import createLucideIcon from '../createLucideIcon';

const AlignStartVertical = createLucideIcon('AlignStartVertical', [
  [
    'rect',
    { width: '9', height: '6', x: '6', y: '14', rx: '2', key: 'lpm2y7' },
  ],
  [
    'rect',
    { width: '16', height: '6', x: '6', y: '4', rx: '2', key: 'rdj6ps' },
  ],
  ['path', { d: 'M2 2v20', key: '1ivd8o' }],
]);

export default AlignStartVertical;
