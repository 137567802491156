import createLucideIcon from '../createLucideIcon';

const BarChartHorizontal = createLucideIcon('BarChartHorizontal', [
  ['path', { d: 'M3 3v18h18', key: '1s2lah' }],
  ['path', { d: 'M7 16h8', key: 'srdodz' }],
  ['path', { d: 'M7 11h12', key: '127s9w' }],
  ['path', { d: 'M7 6h3', key: 'w9rmul' }],
]);

export default BarChartHorizontal;
