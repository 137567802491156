import createLucideIcon from '../createLucideIcon';

const FerrisWheel = createLucideIcon('FerrisWheel', [
  ['circle', { cx: '12', cy: '12', r: '2', key: '1c9p78' }],
  ['path', { d: 'M12 2v4', key: '3427ic' }],
  ['path', { d: 'm6.8 15-3.5 2', key: 'hjy98k' }],
  ['path', { d: 'm20.7 7-3.5 2', key: 'f08gto' }],
  ['path', { d: 'M6.8 9 3.3 7', key: '1aevh4' }],
  ['path', { d: 'm20.7 17-3.5-2', key: '1liqo3' }],
  ['path', { d: 'm9 22 3-8 3 8', key: 'wees03' }],
  ['path', { d: 'M8 22h8', key: 'rmew8v' }],
  ['path', { d: 'M18 18.7a9 9 0 1 0-12 0', key: 'dhzg4g' }],
]);

export default FerrisWheel;
