import createLucideIcon from '../createLucideIcon';

const Table2 = createLucideIcon('Table2', [
  [
    'path',
    {
      d: 'M9 3H5a2 2 0 0 0-2 2v4m6-6h10a2 2 0 0 1 2 2v4M9 3v18m0 0h10a2 2 0 0 0 2-2V9M9 21H5a2 2 0 0 1-2-2V9m0 0h18',
      key: 'gugj83',
    },
  ],
]);

export default Table2;
