import createLucideIcon from '../createLucideIcon';

const PcCase = createLucideIcon('PcCase', [
  [
    'rect',
    { width: '14', height: '20', x: '5', y: '2', rx: '2', key: '1uq1d7' },
  ],
  ['path', { d: 'M15 14h.01', key: '1kp3bh' }],
  ['path', { d: 'M9 6h6', key: 'dgm16u' }],
  ['path', { d: 'M9 10h6', key: '9gxzsh' }],
]);

export default PcCase;
