import createLucideIcon from '../createLucideIcon';

const Contact = createLucideIcon('Contact', [
  ['path', { d: 'M17 18a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2', key: '1mghuy' }],
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '4', rx: '2', key: '1hopcy' },
  ],
  ['circle', { cx: '12', cy: '10', r: '2', key: '1yojzk' }],
  ['line', { x1: '8', x2: '8', y1: '2', y2: '4', key: '1ff9gb' }],
  ['line', { x1: '16', x2: '16', y1: '2', y2: '4', key: '1ufoma' }],
]);

export default Contact;
