import createLucideIcon from '../createLucideIcon';

const ConciergeBell = createLucideIcon('ConciergeBell', [
  [
    'path',
    { d: 'M2 18a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v2H2v-2Z', key: '1co3i8' },
  ],
  ['path', { d: 'M20 16a8 8 0 1 0-16 0', key: '1pa543' }],
  ['path', { d: 'M12 4v4', key: '1bq03y' }],
  ['path', { d: 'M10 4h4', key: '1xpv9s' }],
]);

export default ConciergeBell;
