import createLucideIcon from '../createLucideIcon';

const AlignEndVertical = createLucideIcon('AlignEndVertical', [
  [
    'rect',
    { width: '16', height: '6', x: '2', y: '4', rx: '2', key: '10wcwx' },
  ],
  [
    'rect',
    { width: '9', height: '6', x: '9', y: '14', rx: '2', key: '4p5bwg' },
  ],
  ['path', { d: 'M22 22V2', key: '12ipfv' }],
]);

export default AlignEndVertical;
