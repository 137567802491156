import createLucideIcon from '../createLucideIcon';

const Dice1 = createLucideIcon('Dice1', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['path', { d: 'M12 12h.01', key: '1mp3jc' }],
]);

export default Dice1;
