import createLucideIcon from '../createLucideIcon';

const Bird = createLucideIcon('Bird', [
  ['path', { d: 'M16 7h.01', key: '1kdx03' }],
  [
    'path',
    { d: 'M3.4 18H12a8 8 0 0 0 8-8V7a4 4 0 0 0-7.28-2.3L2 20', key: 'oj1oa8' },
  ],
  ['path', { d: 'm20 7 2 .5-2 .5', key: '12nv4d' }],
  ['path', { d: 'M10 18v3', key: '1yea0a' }],
  ['path', { d: 'M14 17.75V21', key: '1pymcb' }],
  ['path', { d: 'M7 18a6 6 0 0 0 3.84-10.61', key: '1npnn0' }],
]);

export default Bird;
