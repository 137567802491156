import createLucideIcon from '../createLucideIcon';

const Usb = createLucideIcon('Usb', [
  ['circle', { cx: '10', cy: '7', r: '1', key: 'dypaad' }],
  ['circle', { cx: '4', cy: '20', r: '1', key: '22iqad' }],
  ['path', { d: 'M4.7 19.3 19 5', key: '1enqfc' }],
  ['path', { d: 'm21 3-3 1 2 2Z', key: 'd3ov82' }],
  ['path', { d: 'M9.26 7.68 5 12l2 5', key: '1esawj' }],
  ['path', { d: 'm10 14 5 2 3.5-3.5', key: 'v8oal5' }],
  ['path', { d: 'm18 12 1-1 1 1-1 1Z', key: '1bh22v' }],
]);

export default Usb;
