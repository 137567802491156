import createLucideIcon from '../createLucideIcon';

const TestTube = createLucideIcon('TestTube', [
  [
    'path',
    {
      d: 'M14.5 2v17.5c0 1.4-1.1 2.5-2.5 2.5h0c-1.4 0-2.5-1.1-2.5-2.5V2',
      key: '187lwq',
    },
  ],
  ['path', { d: 'M8.5 2h7', key: 'csnxdl' }],
  ['path', { d: 'M14.5 16h-5', key: '1ox875' }],
]);

export default TestTube;
