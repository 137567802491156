import React from 'react';

import {BuilderElement, NodeType} from '../../../types';
import {View, ViewProps} from '../../View';
import {WebView, WebViewProps} from '../../WebView';

export type WebViewElementType = ViewProps &
  WebViewProps & {
    uri: string;
  };

const defaultProps: WebViewElementType = {
  style: {
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    overflow: 'scroll',
  },
  uri: 'https://openai.com/index/chatgpt/',
  overflow: 'scroll',
  height: 500,
};

export const Component = ({uri, style, ...props}: WebViewElementType) => {
  return (
    <View {...props}>
      <WebView source={{uri}} style={[style]} />
    </View>
  );
};

export const WebViewElement: BuilderElement<WebViewElementType> = {
  id: 'WebViewElement',
  icon: 'Globe',
  nodeId: '',
  Component,
  type: NodeType.WEB_VIEW,
  props: defaultProps,
};
