import React from 'react';

import {PageSection, ViewportSizes} from '../../../../types';
import {usePropsByViewPortSize} from '../../../../utils';
import {View} from '../../../View';

import {RowPreview} from './RowPreview';

type Props = {
  pageSection: PageSection;
  viewportSize: ViewportSizes;
  builderContentElements: Record<string, any>;
};

export const SectionPreview = ({pageSection, viewportSize, builderContentElements}: Props) => {
  const {rows, props} = pageSection;

  const sectionProps = usePropsByViewPortSize(props);

  return (
    <View {...sectionProps}>
      {rows.map((row) => (
        <RowPreview
          key={row.id}
          row={row}
          viewportSize={viewportSize}
          builderContentElements={builderContentElements}
        />
      ))}
    </View>
  );
};
