import createLucideIcon from '../createLucideIcon';

const PanelBottomClose = createLucideIcon('PanelBottomClose', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['line', { x1: '3', x2: '21', y1: '15', y2: '15', key: 'o2sbyz' }],
  ['path', { d: 'm15 8-3 3-3-3', key: '1oxy1z' }],
]);

export default PanelBottomClose;
