import createLucideIcon from '../createLucideIcon';

const ArrowUp10 = createLucideIcon('ArrowUp10', [
  ['path', { d: 'm3 8 4-4 4 4', key: '11wl7u' }],
  ['path', { d: 'M7 4v16', key: '1glfcx' }],
  ['path', { d: 'M17 10V4h-2', key: 'zcsr5x' }],
  ['path', { d: 'M15 10h4', key: 'id2lce' }],
  [
    'rect',
    { x: '15', y: '14', width: '4', height: '6', ry: '2', key: '33xykx' },
  ],
]);

export default ArrowUp10;
