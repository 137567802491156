import createLucideIcon from '../createLucideIcon';

const Castle = createLucideIcon('Castle', [
  [
    'path',
    { d: 'M22 20v-9H2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2Z', key: '109fe4' },
  ],
  ['path', { d: 'M18 11V4H6v7', key: 'mon5oj' }],
  ['path', { d: 'M15 22v-4a3 3 0 0 0-3-3v0a3 3 0 0 0-3 3v4', key: 'jdggr9' }],
  ['path', { d: 'M22 11V9', key: '3zbp94' }],
  ['path', { d: 'M2 11V9', key: '1x5rnq' }],
  ['path', { d: 'M6 4V2', key: '1rsq15' }],
  ['path', { d: 'M18 4V2', key: '1jsdo1' }],
  ['path', { d: 'M10 4V2', key: '75d9ly' }],
  ['path', { d: 'M14 4V2', key: '8nj3z6' }],
]);

export default Castle;
