import createLucideIcon from '../createLucideIcon';

const BookPlus = createLucideIcon('BookPlus', [
  [
    'path',
    {
      d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20',
      key: 't4utmx',
    },
  ],
  ['path', { d: 'M9 10h6', key: '9gxzsh' }],
  ['path', { d: 'M12 7v6', key: 'lw1j43' }],
]);

export default BookPlus;
