import createLucideIcon from '../createLucideIcon';

const Blinds = createLucideIcon('Blinds', [
  ['path', { d: 'M3 3h18', key: 'o7r712' }],
  ['path', { d: 'M20 7H8', key: 'gd2fo2' }],
  ['path', { d: 'M20 11H8', key: '1ynp89' }],
  ['path', { d: 'M10 19h10', key: '19hjk5' }],
  ['path', { d: 'M8 15h12', key: '1yqzne' }],
  ['path', { d: 'M4 3v14', key: 'fggqzn' }],
  ['circle', { cx: '4', cy: '19', r: '2', key: 'p3m9r0' }],
]);

export default Blinds;
