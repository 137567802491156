import createLucideIcon from '../createLucideIcon';

const Briefcase = createLucideIcon('Briefcase', [
  [
    'rect',
    {
      width: '20',
      height: '14',
      x: '2',
      y: '7',
      rx: '2',
      ry: '2',
      key: 'eto64e',
    },
  ],
  ['path', { d: 'M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16', key: 'zwj3tp' }],
]);

export default Briefcase;
