import createLucideIcon from '../createLucideIcon';

const AlignStartHorizontal = createLucideIcon('AlignStartHorizontal', [
  [
    'rect',
    { width: '6', height: '16', x: '4', y: '6', rx: '2', key: '1n4dg1' },
  ],
  [
    'rect',
    { width: '6', height: '9', x: '14', y: '6', rx: '2', key: '17khns' },
  ],
  ['path', { d: 'M22 2H2', key: 'fhrpnj' }],
]);

export default AlignStartHorizontal;
