import createLucideIcon from '../createLucideIcon';

const ArrowUpWideNarrow = createLucideIcon('ArrowUpWideNarrow', [
  ['path', { d: 'm3 8 4-4 4 4', key: '11wl7u' }],
  ['path', { d: 'M7 4v16', key: '1glfcx' }],
  ['path', { d: 'M11 12h10', key: '1438ji' }],
  ['path', { d: 'M11 16h7', key: 'uosisv' }],
  ['path', { d: 'M11 20h4', key: '1krc32' }],
]);

export default ArrowUpWideNarrow;
