import { createMedia } from "@tamagui/react-native-media-driver";
const demoMedia = [500, 620, 780, 900],
  widths = [660, 800, 1020, 1280],
  media = createMedia({
    // for site
    xl: {
      maxWidth: 1650
    },
    lg: {
      maxWidth: 1280
    },
    md: {
      maxWidth: 1020
    },
    sm: {
      maxWidth: 800
    },
    xs: {
      maxWidth: 660
    },
    xxs: {
      maxWidth: 390
    },
    gtXs: {
      minWidth: 661
    },
    gtSm: {
      minWidth: 801
    },
    gtMd: {
      minWidth: 1021
    },
    gtLg: {
      minWidth: 1281
    },
    gtXl: {
      minWidth: 1651
    }
  }),
  mediaQueryDefaultActive = {
    xl: !0,
    lg: !0,
    md: !0,
    sm: !0,
    xs: !0,
    // false
    xxs: !1
  };
export { demoMedia, media, mediaQueryDefaultActive, widths };