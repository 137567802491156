import createLucideIcon from '../createLucideIcon';

const Receipt = createLucideIcon('Receipt', [
  [
    'path',
    {
      d: 'M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1-2-1Z',
      key: 'wqdwcb',
    },
  ],
  ['path', { d: 'M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8', key: '1h4pet' }],
  ['path', { d: 'M12 17V7', key: 'pyj7ub' }],
]);

export default Receipt;
