import createLucideIcon from '../createLucideIcon';

const RollerCoaster = createLucideIcon('RollerCoaster', [
  ['path', { d: 'M6 19V5', key: '1r845m' }],
  ['path', { d: 'M10 19V6.8', key: '9j2tfs' }],
  ['path', { d: 'M14 19v-7.8', key: '10s8qv' }],
  ['path', { d: 'M18 5v4', key: '1tajlv' }],
  ['path', { d: 'M18 19v-6', key: 'ielfq3' }],
  ['path', { d: 'M22 19V9', key: '158nzp' }],
  [
    'path',
    {
      d: 'M2 19V9a4 4 0 0 1 4-4c2 0 4 1.33 6 4s4 4 6 4a4 4 0 1 0-3-6.65',
      key: '1930oh',
    },
  ],
]);

export default RollerCoaster;
