import createLucideIcon from '../createLucideIcon';

const PanelRightClose = createLucideIcon('PanelRightClose', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['line', { x1: '15', x2: '15', y1: '3', y2: '21', key: '1hpv9i' }],
  ['path', { d: 'm8 9 3 3-3 3', key: '12hl5m' }],
]);

export default PanelRightClose;
