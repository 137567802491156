import createLucideIcon from '../createLucideIcon';

const Radiation = createLucideIcon('Radiation', [
  ['path', { d: 'M12 12h0', key: '1tz7lj' }],
  [
    'path',
    {
      d: 'M7.5 4.2c-.3-.5-.9-.7-1.3-.4C3.9 5.5 2.3 8.1 2 11c-.1.5.4 1 1 1h5c0-1.5.8-2.8 2-3.4-1.1-1.9-2-3.5-2.5-4.4z',
      key: 'wy49g3',
    },
  ],
  [
    'path',
    {
      d: 'M21 12c.6 0 1-.4 1-1-.3-2.9-1.8-5.5-4.1-7.1-.4-.3-1.1-.2-1.3.3-.6.9-1.5 2.5-2.6 4.3 1.2.7 2 2 2 3.5h5z',
      key: 'vklnvr',
    },
  ],
  [
    'path',
    {
      d: 'M7.5 19.8c-.3.5-.1 1.1.4 1.3 2.6 1.2 5.6 1.2 8.2 0 .5-.2.7-.8.4-1.3-.5-.9-1.4-2.5-2.5-4.3-1.2.7-2.8.7-4 0-1.1 1.8-2 3.4-2.5 4.3z',
      key: 'wkdf1o',
    },
  ],
]);

export default Radiation;
