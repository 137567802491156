import createLucideIcon from '../createLucideIcon';

const MonitorDot = createLucideIcon('MonitorDot', [
  ['circle', { cx: '19', cy: '6', r: '3', key: '108a5v' }],
  [
    'path',
    {
      d: 'M22 12v3a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h9',
      key: '1fet9y',
    },
  ],
  ['path', { d: 'M12 17v4', key: '1riwvh' }],
  ['path', { d: 'M8 21h8', key: '1ev6f3' }],
]);

export default MonitorDot;
