import createLucideIcon from '../createLucideIcon';

const Sword = createLucideIcon('Sword', [
  ['polyline', { points: '14.5 17.5 3 6 3 3 6 3 17.5 14.5', key: '1hfsw2' }],
  ['line', { x1: '13', x2: '19', y1: '19', y2: '13', key: '1vrmhu' }],
  ['line', { x1: '16', x2: '20', y1: '16', y2: '20', key: '1bron3' }],
  ['line', { x1: '19', x2: '21', y1: '21', y2: '19', key: '13pww6' }],
]);

export default Sword;
