import createLucideIcon from '../createLucideIcon';

const WholeWord = createLucideIcon('WholeWord', [
  ['circle', { cx: '7', cy: '12', r: '3', key: '12clwm' }],
  ['path', { d: 'M10 9v6', key: '17i7lo' }],
  ['circle', { cx: '17', cy: '12', r: '3', key: 'gl7c2s' }],
  ['path', { d: 'M14 7v8', key: 'dl84cr' }],
  ['path', { d: 'M22 17v1c0 .5-.5 1-1 1H3c-.5 0-1-.5-1-1v-1', key: 'lt2kga' }],
]);

export default WholeWord;
