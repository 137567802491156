import createLucideIcon from '../createLucideIcon';

const Ghost = createLucideIcon('Ghost', [
  ['path', { d: 'M9 10h.01', key: 'qbtxuw' }],
  ['path', { d: 'M15 10h.01', key: '1qmjsl' }],
  [
    'path',
    {
      d: 'M12 2a8 8 0 0 0-8 8v12l3-3 2.5 2.5L12 19l2.5 2.5L17 19l3 3V10a8 8 0 0 0-8-8z',
      key: 'uwwb07',
    },
  ],
]);

export default Ghost;
