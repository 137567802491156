import { useEffect, useLayoutEffect } from "react";
const isWeb = !0,
  isWindowDefined = typeof window < "u",
  isServer = isWeb && !isWindowDefined,
  isClient = isWeb && isWindowDefined,
  useIsomorphicLayoutEffect = isServer ? useEffect : useLayoutEffect,
  isChrome = typeof navigator < "u" && /Chrome/.test(navigator.userAgent || ""),
  isWebTouchable = isClient && ("ontouchstart" in window || navigator.maxTouchPoints > 0),
  isTouchable = !isWeb || isWebTouchable,
  isAndroid = !1,
  isIos = !1,
  currentPlatform = "web";
export { currentPlatform, isAndroid, isChrome, isClient, isIos, isServer, isTouchable, isWeb, isWebTouchable, isWindowDefined, useIsomorphicLayoutEffect };