import createLucideIcon from '../createLucideIcon';

const Pizza = createLucideIcon('Pizza', [
  ['path', { d: 'M15 11h.01', key: 'rns66s' }],
  ['path', { d: 'M11 15h.01', key: 'k85uqc' }],
  ['path', { d: 'M16 16h.01', key: '1f9h7w' }],
  ['path', { d: 'm2 16 20 6-6-20A20 20 0 0 0 2 16', key: 'e4slt2' }],
  ['path', { d: 'M5.71 17.11a17.04 17.04 0 0 1 11.4-11.4', key: 'rerf8f' }],
]);

export default Pizza;
