import createLucideIcon from '../createLucideIcon';

const AlignHorizontalSpaceAround = createLucideIcon(
  'AlignHorizontalSpaceAround',
  [
    [
      'rect',
      { width: '6', height: '10', x: '9', y: '7', rx: '2', key: 'yn7j0q' },
    ],
    ['path', { d: 'M4 22V2', key: 'tsjzd3' }],
    ['path', { d: 'M20 22V2', key: '1bnhr8' }],
  ],
);

export default AlignHorizontalSpaceAround;
