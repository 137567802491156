import { isWeb } from "@tamagui/constants";
import { configListeners, setConfig, setTokens } from "./config.mjs";
import { createVariables } from "./createVariables.mjs";
import { getThemeCSSRules } from "./helpers/getThemeCSSRules.mjs";
import { getAllRules, listenForSheetChanges, scanAllSheets } from "./helpers/insertStyleRule.mjs";
import { proxyThemesToParents } from "./helpers/proxyThemeToParents.mjs";
import { registerCSSVariable, variableToCSS } from "./helpers/registerCSSVariable.mjs";
import { ensureThemeVariable } from "./helpers/themes.mjs";
import { configureMedia } from "./hooks/useMedia.mjs";
import { parseFont, registerFontVariables } from "./insertFont.mjs";
import { Tamagui } from "./Tamagui.mjs";
const createdConfigs = /* @__PURE__ */new WeakMap();
function createTamagui(configIn) {
  if (createdConfigs.has(configIn)) return configIn;
  const tokensParsed = {},
    tokens = createVariables(configIn.tokens || {});
  if (configIn.tokens) {
    const tokensMerged = {};
    for (const cat in tokens) {
      tokensParsed[cat] = {}, tokensMerged[cat] = {};
      const tokenCat = tokens[cat];
      for (const key in tokenCat) {
        const val = tokenCat[key],
          prefixedKey = `$${key}`;
        tokensParsed[cat][prefixedKey] = val, tokensMerged[cat][prefixedKey] = val, tokensMerged[cat][key] = val;
      }
    }
    setTokens(tokensMerged);
  }
  let foundThemes;
  if (configIn.themes) {
    const noThemes = Object.keys(configIn.themes).length === 0;
    foundThemes = scanAllSheets(noThemes, tokensParsed);
  }
  listenForSheetChanges();
  let fontSizeTokens = null,
    fontsParsed;
  if (configIn.fonts) {
    const fontTokens = Object.fromEntries(Object.entries(configIn.fonts).map(([k, v]) => [k, createVariables(v, "f", !0)]));
    fontsParsed = (() => {
      const res = {};
      for (const familyName in fontTokens) {
        const font = fontTokens[familyName],
          fontParsed = parseFont(font);
        res[`$${familyName}`] = fontParsed, !fontSizeTokens && fontParsed.size && (fontSizeTokens = new Set(Object.keys(fontParsed.size)));
      }
      return res;
    })();
  }
  const specificTokens = {},
    themeConfig = (() => {
      const cssRuleSets = [],
        declarations = [],
        fontDeclarations = {};
      for (const key in tokens) for (const skey in tokens[key]) {
        const variable = tokens[key][skey];
        if (specificTokens[`$${key}.${skey}`] = variable, process.env.NODE_ENV === "development" && typeof variable > "u") throw new Error(`No value for tokens.${key}.${skey}:
${JSON.stringify(variable, null, 2)}`);
        isWeb && (registerCSSVariable(variable), declarations.push(variableToCSS(variable, key === "zIndex")));
      }
      if (isWeb) {
        let declarationsToRuleSet = function (decs, selector = "") {
          return `:root${selector} {${sep}${[...decs].join(`;${sep}`)}${sep}}`;
        };
        for (const key in fontsParsed) {
          const fontParsed = fontsParsed[key],
            [name, language] = key.includes("_") ? key.split("_") : [key],
            fontVars = registerFontVariables(fontParsed);
          fontDeclarations[key] = {
            name: name.slice(1),
            declarations: fontVars,
            language
          };
        }
        const sep = configIn.cssStyleSeparator || "";
        if (cssRuleSets.push(declarationsToRuleSet(declarations)), fontDeclarations) for (const key in fontDeclarations) {
          const {
              name,
              declarations: declarations2,
              language = "default"
            } = fontDeclarations[key],
            fontSelector = `.font_${name}`,
            langSelector = `:root .t_lang-${name}-${language} ${fontSelector}`,
            selectors = language === "default" ? ` ${fontSelector}, ${langSelector}` : langSelector,
            specificRuleSet = declarationsToRuleSet(declarations2, selectors);
          cssRuleSets.push(specificRuleSet);
        }
      }
      const themesIn = {
          ...configIn.themes
        },
        dedupedThemes = foundThemes ?? getThemesDeduped(themesIn);
      return {
        themes: proxyThemesToParents(dedupedThemes),
        cssRuleSets,
        getThemeRulesSets() {
          let themeRuleSets = [];
          if (isWeb) for (const {
            names,
            theme
          } of dedupedThemes) {
            const nextRules = getThemeCSSRules({
              config: configIn,
              themeName: names[0],
              names,
              theme
            });
            themeRuleSets = [...themeRuleSets, ...nextRules];
          }
          return themeRuleSets;
        }
      };
    })(),
    shorthands = configIn.shorthands || {};
  let lastCSSInsertedRulesIndex = -1;
  const getCSS = (opts = {}) => {
      const {
        separator = `
`,
        sinceLastCall,
        exclude
      } = opts;
      if (sinceLastCall && lastCSSInsertedRulesIndex >= 0) {
        const rules = getAllRules();
        return lastCSSInsertedRulesIndex = rules.length, rules.slice(lastCSSInsertedRulesIndex).join(separator);
      }
      lastCSSInsertedRulesIndex = 0;
      const runtimeStyles = getAllRules().join(separator);
      return exclude === "design-system" ? runtimeStyles : `${`._ovs-contain {overscroll-behavior:contain;}
.is_Text .is_Text {display:inline-flex;}
._dsp_contents {display:contents;}
${themeConfig.cssRuleSets.join(separator)}`}
${exclude ? "" : themeConfig.getThemeRulesSets().join(separator)}
${runtimeStyles}`;
    },
    getNewCSS = opts => getCSS({
      ...opts,
      sinceLastCall: !0
    });
  let defaultFontName = configIn.defaultFont ||
  // uses font named "body" if present for compat
  configIn.fonts && ("body" in configIn.fonts ? "body" : "");
  !defaultFontName && configIn.fonts && (defaultFontName = Object.keys(configIn.fonts)[0]), defaultFontName?.[0] === "$" && (defaultFontName = defaultFontName.slice(1));
  const defaultFont = `$${defaultFontName}`,
    config = {
      fonts: {},
      onlyAllowShorthands: !1,
      fontLanguages: [],
      animations: {},
      media: {},
      ...configIn,
      unset: {
        fontFamily: configIn.defaultFont ? defaultFont : void 0,
        ...configIn.unset
      },
      settings: {
        webContainerType: "inline-size",
        ...configIn.settings
      },
      tokens,
      // vite made this into a function if it wasn't set
      shorthands,
      inverseShorthands: shorthands ? Object.fromEntries(Object.entries(shorthands).map(([k, v]) => [v, k])) : {},
      themes: themeConfig.themes,
      fontsParsed: fontsParsed || {},
      themeConfig,
      tokensParsed,
      parsed: !0,
      getNewCSS,
      getCSS,
      defaultFont,
      fontSizeTokens: fontSizeTokens || /* @__PURE__ */new Set(),
      specificTokens
      // const tokens = [...getToken(tokens.size[0])]
      // .spacer-sm + ._dsp_contents._dsp-sm-hidden { margin-left: -var(--${}) }
    };
  return configureMedia(config), setConfig(config), createdConfigs.set(config, !0), configListeners.size && (configListeners.forEach(cb => cb(config)), configListeners.clear()), process.env.NODE_ENV === "development" && (process.env.DEBUG?.startsWith("tamagui") && console.info("Tamagui config:", config), globalThis.Tamagui || (globalThis.Tamagui = Tamagui)), config;
}
function getThemesDeduped(themes) {
  const dedupedThemes = [],
    existing = /* @__PURE__ */new Map();
  for (const themeName in themes) {
    const darkOrLightSpecificPrefix = themeName.startsWith("dark") ? "dark" : themeName.startsWith("light") ? "light" : "",
      rawTheme = themes[themeName],
      key = darkOrLightSpecificPrefix + JSON.stringify(rawTheme);
    if (existing.has(key)) {
      existing.get(key).names.push(themeName);
      continue;
    }
    const theme = {
      ...rawTheme
    };
    for (const key2 in theme) ensureThemeVariable(theme, key2);
    const deduped = {
      names: [themeName],
      theme
    };
    dedupedThemes.push(deduped), existing.set(key, deduped);
  }
  return dedupedThemes;
}
export { createTamagui };