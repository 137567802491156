import createLucideIcon from '../createLucideIcon';

const Library = createLucideIcon('Library', [
  ['path', { d: 'm16 6 4 14', key: 'ji33uf' }],
  ['path', { d: 'M12 6v14', key: '1n7gus' }],
  ['path', { d: 'M8 8v12', key: '1gg7y9' }],
  ['path', { d: 'M4 4v16', key: '6qkkli' }],
]);

export default Library;
