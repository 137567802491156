import createLucideIcon from '../createLucideIcon';

const BellPlus = createLucideIcon('BellPlus', [
  [
    'path',
    {
      d: 'M19.3 14.8C20.1 16.4 21 17 21 17H3s3-2 3-9c0-3.3 2.7-6 6-6 1 0 1.9.2 2.8.7',
      key: 'guizqy',
    },
  ],
  ['path', { d: 'M10.3 21a1.94 1.94 0 0 0 3.4 0', key: 'qgo35s' }],
  ['path', { d: 'M15 8h6', key: '8ybuxh' }],
  ['path', { d: 'M18 5v6', key: 'g5ayrv' }],
]);

export default BellPlus;
