import createLucideIcon from '../createLucideIcon';

const SquareSlash = createLucideIcon('SquareSlash', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['line', { x1: '9', x2: '15', y1: '15', y2: '9', key: '1dfufj' }],
]);

export default SquareSlash;
