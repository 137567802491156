import createLucideIcon from '../createLucideIcon';

const ArrowDown01 = createLucideIcon('ArrowDown01', [
  ['path', { d: 'm3 16 4 4 4-4', key: '1co6wj' }],
  ['path', { d: 'M7 20V4', key: '1yoxec' }],
  [
    'rect',
    { x: '15', y: '4', width: '4', height: '6', ry: '2', key: '1bwicg' },
  ],
  ['path', { d: 'M17 20v-6h-2', key: '1qp1so' }],
  ['path', { d: 'M15 20h4', key: '1j968p' }],
]);

export default ArrowDown01;
