import { useIsomorphicLayoutEffect } from "@tamagui/constants";
import { getBoundingClientRect } from "../helpers/getBoundingClientRect.mjs";
const LayoutHandlers = /* @__PURE__ */new WeakMap(),
  resizeListeners = /* @__PURE__ */new Set();
let resizeObserver = null;
if (typeof window < "u" && "ResizeObserver" in window && (resizeObserver = new ResizeObserver(entries => {
  for (const {
    target
  } of entries) {
    const onLayout = LayoutHandlers.get(target);
    if (typeof onLayout != "function") return;
    measureElement(target).then(event => {
      onLayout(event);
    });
  }
}), typeof window.addEventListener == "function")) {
  let tm;
  window.addEventListener("resize", () => {
    clearTimeout(tm), tm = setTimeout(() => {
      resizeListeners.forEach(cb => cb());
    }, 4);
  });
}
const measureElement = async target => new Promise(res => {
    measureLayout(target, null, (x, y, width, height, left, top) => {
      res({
        nativeEvent: {
          layout: {
            x,
            y,
            width,
            height,
            left,
            top
          },
          target
        },
        timeStamp: Date.now()
      });
    });
  }),
  cache = /* @__PURE__ */new WeakMap(),
  measureLayout = (node, relativeTo, callback) => {
    const relativeNode = relativeTo || node?.parentNode;
    if (relativeNode instanceof HTMLElement) {
      const now = Date.now();
      cache.set(node, now), Promise.all([getBoundingClientRectAsync(node), getBoundingClientRectAsync(relativeNode)]).then(([nodeDim, relativeNodeDim]) => {
        if (relativeNodeDim && nodeDim && cache.get(node) === now) {
          const {
            x,
            y,
            width,
            height,
            left,
            top
          } = getRelativeDimensions(nodeDim, relativeNodeDim);
          callback(x, y, width, height, left, top);
        }
      });
    }
  },
  getRelativeDimensions = (a, b) => {
    const {
        height,
        left,
        top,
        width
      } = a,
      x = left - b.left,
      y = top - b.top;
    return {
      x,
      y,
      width,
      height,
      left,
      top
    };
  },
  getBoundingClientRectAsync = element => new Promise(resolve => {
    function fallbackToSync() {
      resolve(getBoundingClientRect(element));
    }
    const tm = setTimeout(fallbackToSync, 10);
    new IntersectionObserver((entries, ob) => {
      clearTimeout(tm), ob.disconnect(), resolve(entries[0]?.boundingClientRect);
    }, {
      threshold: 1e-4
    }).observe(element);
  });
function useElementLayout(ref, onLayout) {
  useIsomorphicLayoutEffect(() => {
    if (!onLayout) return;
    const node = ref.current;
    node && LayoutHandlers.set(node, onLayout);
  }, [ref, onLayout]), useIsomorphicLayoutEffect(() => {
    if (!resizeObserver) return;
    const node = ref.current;
    if (!node || !LayoutHandlers.has(node)) return;
    const onResize = () => {
      measureElement(node).then(onLayout);
    };
    return resizeListeners.add(onResize), resizeObserver.observe(node), () => {
      resizeListeners.delete(onResize), resizeObserver?.unobserve(node);
    };
  }, [ref]);
}
export { measureElement, measureLayout, useElementLayout };