import createLucideIcon from '../createLucideIcon';

const Server = createLucideIcon('Server', [
  [
    'rect',
    {
      width: '20',
      height: '8',
      x: '2',
      y: '2',
      rx: '2',
      ry: '2',
      key: 'ngkwjq',
    },
  ],
  [
    'rect',
    {
      width: '20',
      height: '8',
      x: '2',
      y: '14',
      rx: '2',
      ry: '2',
      key: 'iecqi9',
    },
  ],
  ['line', { x1: '6', x2: '6.01', y1: '6', y2: '6', key: '16zg32' }],
  ['line', { x1: '6', x2: '6.01', y1: '18', y2: '18', key: 'nzw8ys' }],
]);

export default Server;
