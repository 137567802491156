import createLucideIcon from '../createLucideIcon';

const Dice4 = createLucideIcon('Dice4', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['path', { d: 'M16 8h.01', key: 'cr5u4v' }],
  ['path', { d: 'M8 8h.01', key: '1e4136' }],
  ['path', { d: 'M8 16h.01', key: '18s6g9' }],
  ['path', { d: 'M16 16h.01', key: '1f9h7w' }],
]);

export default Dice4;
