import createLucideIcon from '../createLucideIcon';

const MemoryStick = createLucideIcon('MemoryStick', [
  ['path', { d: 'M6 19v-3', key: '1nvgqn' }],
  ['path', { d: 'M10 19v-3', key: 'iu8nkm' }],
  ['path', { d: 'M14 19v-3', key: 'kcehxu' }],
  ['path', { d: 'M18 19v-3', key: '1vh91z' }],
  ['path', { d: 'M8 11V9', key: '63erz4' }],
  ['path', { d: 'M16 11V9', key: 'fru6f3' }],
  ['path', { d: 'M12 11V9', key: 'ha00sb' }],
  ['path', { d: 'M2 15h20', key: '16ne18' }],
  [
    'path',
    {
      d: 'M2 7a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v1.1a2 2 0 0 0 0 3.837V17a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-5.1a2 2 0 0 0 0-3.837Z',
      key: 'lhddv3',
    },
  ],
]);

export default MemoryStick;
