import createLucideIcon from '../createLucideIcon';

const Church = createLucideIcon('Church', [
  [
    'path',
    { d: 'm18 7 4 2v11a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9l4-2', key: 'gy5gyo' },
  ],
  ['path', { d: 'M14 22v-4a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v4', key: 'cpkuc4' }],
  ['path', { d: 'M18 22V5l-6-3-6 3v17', key: '1hsnhq' }],
  ['path', { d: 'M12 7v5', key: 'ma6bk' }],
  ['path', { d: 'M10 9h4', key: 'u4k05v' }],
]);

export default Church;
