import React from 'react';

import {isBuilderWidgetElement, PageNode} from '../../../../types';
import {usePropsByViewPortSize} from '../../../../utils';
import {YStack} from '../../../YStack';

type NodePreviewProps = {
  node: PageNode;
  builderContentElements: Record<string, any>;
};

export const NodePreview = ({node, builderContentElements}: NodePreviewProps) => {
  const nodeProps = usePropsByViewPortSize(node.props);

  const element = builderContentElements[node.id];
  const isWidgetElement = isBuilderWidgetElement(element);

  return (
    <YStack key={node.nodeId} width="100%" flex={isWidgetElement ? 1 : 0} {...(isWidgetElement ? node.props : {})}>
      <element.Component key={node.nodeId} flex={1} {...element.props} {...nodeProps} />
    </YStack>
  );
};
