import createLucideIcon from '../createLucideIcon';

const PanelBottom = createLucideIcon('PanelBottom', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['line', { x1: '3', x2: '21', y1: '15', y2: '15', key: 'o2sbyz' }],
]);

export default PanelBottom;
