import { useResponderEvents } from "@tamagui/react-native-use-responder-events";
import { Stack as WebStack, Text as WebText, View as WebView, setupHooks } from "@tamagui/web";
import { useMemo } from "react";
import "./createOptimizedView.mjs";
import { getBaseViews } from "./getBaseViews.mjs";
import { useElementLayout } from "./hooks/useElementLayout.mjs";
import { usePlatformMethods } from "./hooks/usePlatformMethods.mjs";
import "./vendor/Pressability.mjs";
export * from "@tamagui/web";
export * from "./reactNativeTypes.mjs";
const baseViews = getBaseViews();
setupHooks({
  getBaseViews,
  usePropsTransform(elementType, propsIn, stateRef, willHydrate) {
    {
      const isDOM = typeof elementType == "string",
        {
          // event props
          onMoveShouldSetResponder,
          onMoveShouldSetResponderCapture,
          onResponderEnd,
          onResponderGrant,
          onResponderMove,
          onResponderReject,
          onResponderRelease,
          onResponderStart,
          onResponderTerminate,
          onResponderTerminationRequest,
          onScrollShouldSetResponder,
          onScrollShouldSetResponderCapture,
          onSelectionChangeShouldSetResponder,
          onSelectionChangeShouldSetResponderCapture,
          onStartShouldSetResponder,
          onStartShouldSetResponderCapture,
          // android
          collapsable,
          focusable,
          // deprecated,
          accessible,
          accessibilityDisabled,
          onLayout,
          hrefAttrs,
          ...plainDOMProps
        } = propsIn;
      if (willHydrate || isDOM) {
        const hostRef = useMemo(() => ({
          get current() {
            return stateRef.current.host;
          }
        }), [stateRef]);
        usePlatformMethods(hostRef), useElementLayout(hostRef, isDOM ? onLayout : void 0), useResponderEvents(hostRef, isDOM ? {
          onMoveShouldSetResponder,
          onMoveShouldSetResponderCapture,
          onResponderEnd,
          onResponderGrant,
          onResponderMove,
          onResponderReject,
          onResponderRelease,
          onResponderStart,
          onResponderTerminate,
          onResponderTerminationRequest,
          onScrollShouldSetResponder,
          onScrollShouldSetResponderCapture,
          onSelectionChangeShouldSetResponder,
          onSelectionChangeShouldSetResponderCapture,
          onStartShouldSetResponder,
          onStartShouldSetResponderCapture
        } : void 0);
      }
      if (isDOM) {
        if (plainDOMProps.href && hrefAttrs) {
          const {
            download,
            rel,
            target
          } = hrefAttrs;
          download != null && (plainDOMProps.download = download), rel && (plainDOMProps.rel = rel), typeof target == "string" && (plainDOMProps.target = target.charAt(0) !== "_" ? `_${target}` : target);
        }
        return plainDOMProps;
      }
    }
  },
  useEvents(viewProps, events, {
    pseudos
  }, setStateShallow, staticConfig) {}
});
const View = WebView,
  Stack = WebStack,
  Text = WebText;
export { Stack, Text, View };