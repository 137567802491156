import createLucideIcon from '../createLucideIcon';

const PocketKnife = createLucideIcon('PocketKnife', [
  [
    'path',
    { d: 'M3 2v1c0 1 2 1 2 2S3 6 3 7s2 1 2 2-2 1-2 2 2 1 2 2', key: '19w3oe' },
  ],
  ['path', { d: 'M18 6h.01', key: '1v4wsw' }],
  ['path', { d: 'M6 18h.01', key: 'uhywen' }],
  [
    'path',
    {
      d: 'M20.83 8.83a4 4 0 0 0-5.66-5.66l-12 12a4 4 0 1 0 5.66 5.66Z',
      key: '6fykxj',
    },
  ],
  ['path', { d: 'M18 11.66V22a4 4 0 0 0 4-4V6', key: '1utzek' }],
]);

export default PocketKnife;
