import createLucideIcon from '../createLucideIcon';

const FileKey2 = createLucideIcon('FileKey2', [
  [
    'path',
    { d: 'M4 10V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2H4', key: '1nw5t3' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['circle', { cx: '4', cy: '16', r: '2', key: '1ehqvc' }],
  ['path', { d: 'm10 10-4.5 4.5', key: '7fwrp6' }],
  ['path', { d: 'm9 11 1 1', key: 'wa6s5q' }],
]);

export default FileKey2;
