import createLucideIcon from '../createLucideIcon';

const Meh = createLucideIcon('Meh', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['line', { x1: '8', x2: '16', y1: '15', y2: '15', key: '1xb1d9' }],
  ['line', { x1: '9', x2: '9.01', y1: '9', y2: '9', key: 'yxxnd0' }],
  ['line', { x1: '15', x2: '15.01', y1: '9', y2: '9', key: '1p4y9e' }],
]);

export default Meh;
