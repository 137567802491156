import createLucideIcon from '../createLucideIcon';

const Settings2 = createLucideIcon('Settings2', [
  ['path', { d: 'M20 7h-9', key: '3s1dr2' }],
  ['path', { d: 'M14 17H5', key: 'gfn3mx' }],
  ['circle', { cx: '17', cy: '17', r: '3', key: '18b49y' }],
  ['circle', { cx: '7', cy: '7', r: '3', key: 'dfmy0x' }],
]);

export default Settings2;
