export const RADIUS_TOKENS = {
  'rounded-none': 0,
  'rounded-sm': 2,
  rounded: 4,
  true: 7,
  'rounded-md': 6,
  'rounded-lg': 8,
  'rounded-xl': 10,
  'rounded-2xl': 10,
  'rounded-3xl': 10,
  'rounded-full': 1000,
  0: 0,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  8: 8,
  10: 10,
  12: 12,
  14: 14,
  16: 16,
  18: 18,
  20: 20,
  24: 24,
  28: 28,
  32: 32,
  36: 36,
  38: 36,
  40: 48,
  42: 48,
  44: 48,
  48: 48,
  50: 50,
};
