import React from 'react';

import {PageNode, PageCell, ViewportSizes} from '../../../../types';
import {usePropsByViewPortSize} from '../../../../utils';
import {YStack} from '../../../YStack';

import {NodePreview} from './NodePreview';

type CellPreviewProps = {
  cell: PageCell;
  viewportSize?: ViewportSizes;
  builderContentElements: Record<string, any>;
};

export const CellPreview = ({cell, viewportSize, builderContentElements}: CellPreviewProps) => {
  const cellProps = usePropsByViewPortSize(cell.props);

  return (
    <YStack key={cell.id} {...{[viewportSize === ViewportSizes.DESKTOP ? 'width' : 'minWidth']: '$20'}} {...cellProps}>
      {(cell.nodes as PageNode[]).map((node) => (
        <NodePreview key={node.nodeId} node={node} builderContentElements={builderContentElements} />
      ))}
    </YStack>
  );
};
