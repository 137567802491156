import createLucideIcon from '../createLucideIcon';

const BookCopy = createLucideIcon('BookCopy', [
  ['path', { d: 'M2 16V4a2 2 0 0 1 2-2h11', key: 'spzkk5' }],
  ['path', { d: 'M5 14H4a2 2 0 1 0 0 4h1', key: '16gqf9' }],
  [
    'path',
    { d: 'M22 18H11a2 2 0 1 0 0 4h11V6H11a2 2 0 0 0-2 2v12', key: '1owzki' },
  ],
]);

export default BookCopy;
