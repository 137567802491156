import createLucideIcon from '../createLucideIcon';

const Angry = createLucideIcon('Angry', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['path', { d: 'M16 16s-1.5-2-4-2-4 2-4 2', key: 'epbg0q' }],
  ['path', { d: 'M7.5 8 10 9', key: 'olxxln' }],
  ['path', { d: 'm14 9 2.5-1', key: '1j6cij' }],
  ['path', { d: 'M9 10h0', key: '1vxvly' }],
  ['path', { d: 'M15 10h0', key: '1j6oav' }],
]);

export default Angry;
