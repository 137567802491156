import createLucideIcon from '../createLucideIcon';

const PilcrowSquare = createLucideIcon('PilcrowSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M12 12H9.5a2.5 2.5 0 0 1 0-5H17', key: '1l9586' }],
  ['path', { d: 'M12 7v10', key: 'jspqdw' }],
  ['path', { d: 'M16 7v10', key: 'lavkr4' }],
]);

export default PilcrowSquare;
