import createLucideIcon from '../createLucideIcon';

const Diamond = createLucideIcon('Diamond', [
  [
    'path',
    {
      d: 'M2.7 10.3a2.41 2.41 0 0 0 0 3.41l7.59 7.59a2.41 2.41 0 0 0 3.41 0l7.59-7.59a2.41 2.41 0 0 0 0-3.41l-7.59-7.59a2.41 2.41 0 0 0-3.41 0Z',
      key: '1f1r0c',
    },
  ],
]);

export default Diamond;
