import createLucideIcon from '../createLucideIcon';

const BookUp2 = createLucideIcon('BookUp2', [
  ['path', { d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2', key: '1lorq7' }],
  ['path', { d: 'M18 2h2v20H6.5a2.5 2.5 0 0 1 0-5H20', key: '1nfm9i' }],
  ['path', { d: 'M12 13V7', key: 'h0r20n' }],
  ['path', { d: 'm9 10 3-3 3 3', key: '11gsxs' }],
  ['path', { d: 'm9 5 3-3 3 3', key: 'l8vdw6' }],
]);

export default BookUp2;
