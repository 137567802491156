import createLucideIcon from '../createLucideIcon';

const Move3d = createLucideIcon('Move3d', [
  ['path', { d: 'M5 3v16h16', key: '1mqmf9' }],
  ['path', { d: 'm5 19 6-6', key: 'jh6hbb' }],
  ['path', { d: 'm2 6 3-3 3 3', key: 'tkyvxa' }],
  ['path', { d: 'm18 16 3 3-3 3', key: '1d4glt' }],
]);

export default Move3d;
