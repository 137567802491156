import createLucideIcon from '../createLucideIcon';

const FoldHorizontal = createLucideIcon('FoldHorizontal', [
  ['path', { d: 'M2 12h6', key: '1wqiqv' }],
  ['path', { d: 'M22 12h-6', key: '1eg9hc' }],
  ['path', { d: 'M12 2v2', key: 'tus03m' }],
  ['path', { d: 'M12 8v2', key: '1woqiv' }],
  ['path', { d: 'M12 14v2', key: '8jcxud' }],
  ['path', { d: 'M12 20v2', key: '1lh1kg' }],
  ['path', { d: 'm19 9-3 3 3 3', key: '12ol22' }],
  ['path', { d: 'm5 15 3-3-3-3', key: '1kdhjc' }],
]);

export default FoldHorizontal;
