import createLucideIcon from '../createLucideIcon';

const IterationCcw = createLucideIcon('IterationCcw', [
  [
    'path',
    { d: 'M20 10c0-4.4-3.6-8-8-8s-8 3.6-8 8 3.6 8 8 8h8', key: '4znkd0' },
  ],
  ['polyline', { points: '16 14 20 18 16 22', key: '11njsm' }],
]);

export default IterationCcw;
