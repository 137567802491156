import createLucideIcon from '../createLucideIcon';

const ArrowUp01 = createLucideIcon('ArrowUp01', [
  ['path', { d: 'm3 8 4-4 4 4', key: '11wl7u' }],
  ['path', { d: 'M7 4v16', key: '1glfcx' }],
  [
    'rect',
    { x: '15', y: '4', width: '4', height: '6', ry: '2', key: '1bwicg' },
  ],
  ['path', { d: 'M17 20v-6h-2', key: '1qp1so' }],
  ['path', { d: 'M15 20h4', key: '1j968p' }],
]);

export default ArrowUp01;
