import { Stack, composeEventHandlers, isWeb, shouldRenderNativePlatform, useProps, withStaticProperties } from "@tamagui/core";
import { useSwitch } from "@tamagui/switch-headless";
import { useControllableState } from "@tamagui/use-controllable-state";
import * as React from "react";
import { Switch as NativeSwitch } from "react-native-web";
import { SwitchStyledContext } from "./StyledContext.mjs";
import { SwitchFrame as DefaultSwitchFrame, SwitchThumb } from "./Switch.mjs";
import { jsx, jsxs } from "react/jsx-runtime";
const SwitchContext = React.createContext({
  checked: !1,
  disabled: !1,
  frameWidth: 0
});
function createSwitch(createProps) {
  const {
    disableActiveTheme,
    Frame = DefaultSwitchFrame,
    Thumb = SwitchThumb
  } = createProps;
  process.env.NODE_ENV === "development" && (Frame !== DefaultSwitchFrame && Frame.staticConfig.context && Frame.staticConfig.context !== SwitchStyledContext || Thumb !== SwitchThumb && Thumb.staticConfig.context && Thumb.staticConfig.context !== SwitchStyledContext) && console.warn("Warning: createSwitch() needs to control context to pass checked state from Frame to Thumb, any custom context passed will be overridden."), Frame.staticConfig.context = SwitchStyledContext, Thumb.staticConfig.context = SwitchStyledContext;
  const SwitchThumbComponent = Thumb.styleable(function (props, forwardedRef) {
      const {
          size: sizeProp,
          unstyled: unstyledProp,
          nativeID,
          ...thumbProps
        } = props,
        context = React.useContext(SwitchContext),
        {
          checked,
          disabled,
          frameWidth
        } = context,
        styledContext = SwitchStyledContext.useStyledContext(),
        {
          unstyled: unstyledContext,
          size: sizeContext
        } = styledContext,
        unstyled = process.env.TAMAGUI_HEADLESS === "1" ? !0 : unstyledProp ?? unstyledContext ?? !1,
        size = sizeProp ?? sizeContext ?? "$true",
        initialChecked = React.useRef(checked).current,
        [thumbWidth, setThumbWidth] = React.useState(0),
        distance = frameWidth - thumbWidth,
        x = initialChecked ? checked ? 0 : -distance : checked ? distance : 0;
      return /* @__PURE__ */jsx(Thumb, {
        ref: forwardedRef,
        unstyled,
        ...(unstyled === !1 && {
          size,
          ...(!disableActiveTheme && {
            theme: checked ? "active" : null
          })
        }),
        alignSelf: initialChecked ? "flex-end" : "flex-start",
        x,
        onLayout: composeEventHandlers(props.onLayout, e => setThumbWidth(e.nativeEvent.layout.width)),
        checked,
        disabled,
        ...thumbProps
      });
    }),
    SwitchComponent = Frame.styleable(function (_props, forwardedRef) {
      const {
          native,
          nativeProps,
          checked: checkedProp,
          defaultChecked,
          onCheckedChange,
          ...props
        } = _props,
        [checked, setChecked] = useControllableState({
          prop: checkedProp,
          defaultProp: defaultChecked || !1,
          onChange: onCheckedChange,
          transition: !0
        }),
        styledContext = React.useContext(SwitchStyledContext.context),
        [frameWidth, setFrameWidth] = React.useState(0),
        propsActive = useProps(props, {
          noNormalize: !0,
          noExpand: !0,
          resolveValues: "none",
          forComponent: Frame
        }),
        {
          switchProps,
          bubbleInput,
          switchRef
        } = useSwitch(
        // @ts-ignore
        Object.assign({
          size: styledContext.size ?? props.size ?? "$true",
          unstyled: styledContext.unstyled ?? props.unstyled ?? !1
        }, propsActive), [checked, setChecked], forwardedRef),
        renderNative = shouldRenderNativePlatform(native);
      return renderNative === "android" || renderNative === "ios" ? /* @__PURE__ */jsx(NativeSwitch, {
        value: checked,
        onValueChange: setChecked,
        ...nativeProps
      }) : /* @__PURE__ */jsxs(SwitchContext.Provider, {
        value: {
          checked,
          disabled: switchProps.disabled,
          frameWidth
        },
        children: [/* @__PURE__ */jsx(Frame, {
          ref: switchRef,
          tag: "button",
          ...(isWeb && {
            type: "button"
          }),
          ...switchProps,
          ...(!disableActiveTheme && {
            theme: checked ? "active" : null,
            themeShallow: !0
          }),
          checked,
          disabled: switchProps.disabled,
          children: /* @__PURE__ */jsx(Stack, {
            alignSelf: "stretch",
            flex: 1,
            onLayout: e => {
              setFrameWidth(e.nativeEvent.layout.width);
            },
            children: switchProps.children
          })
        }), bubbleInput]
      });
    }, {
      disableTheme: !0
    });
  return withStaticProperties(SwitchComponent, {
    Thumb: SwitchThumbComponent
  });
}
export { SwitchContext, createSwitch };