import createLucideIcon from '../createLucideIcon';

const AlignHorizontalJustifyCenter = createLucideIcon(
  'AlignHorizontalJustifyCenter',
  [
    [
      'rect',
      { width: '6', height: '14', x: '2', y: '5', rx: '2', key: 'dy24zr' },
    ],
    [
      'rect',
      { width: '6', height: '10', x: '16', y: '7', rx: '2', key: '13zkjt' },
    ],
    ['path', { d: 'M12 2v20', key: 't6zp3m' }],
  ],
);

export default AlignHorizontalJustifyCenter;
