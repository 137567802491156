import createLucideIcon from '../createLucideIcon';

const AlignVerticalDistributeStart = createLucideIcon(
  'AlignVerticalDistributeStart',
  [
    [
      'rect',
      { width: '14', height: '6', x: '5', y: '14', rx: '2', key: 'jmoj9s' },
    ],
    [
      'rect',
      { width: '10', height: '6', x: '7', y: '4', rx: '2', key: 'aza5on' },
    ],
    ['path', { d: 'M2 14h20', key: 'myj16y' }],
    ['path', { d: 'M2 4h20', key: 'mda7wb' }],
  ],
);

export default AlignVerticalDistributeStart;
