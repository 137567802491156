import createLucideIcon from '../createLucideIcon';

const Skull = createLucideIcon('Skull', [
  ['circle', { cx: '9', cy: '12', r: '1', key: '1vctgf' }],
  ['circle', { cx: '15', cy: '12', r: '1', key: '1tmaij' }],
  ['path', { d: 'M8 20v2h8v-2', key: 'ded4og' }],
  ['path', { d: 'm12.5 17-.5-1-.5 1h1z', key: '3me087' }],
  [
    'path',
    {
      d: 'M16 20a2 2 0 0 0 1.56-3.25 8 8 0 1 0-11.12 0A2 2 0 0 0 8 20',
      key: 'xq9p5u',
    },
  ],
]);

export default Skull;
