import {appletInitTimeout} from '../../config';
import {getScopedUtils} from '../../context';
import {ApiConnector, Applet, AppletUpdateReasons} from '../../types';
import {timeout} from '../timers';

export const initApplets = async (
  applets: Applet[] = [],
  connectors: ApiConnector[],
  isAuthenticated: boolean,
  mainToken?: string,
  tenant?: string,
  updateReason: AppletUpdateReasons = AppletUpdateReasons.INIT,
) => {
  const failedToInit = new Set<Applet>();

  await Promise.all(
    applets.map(async (applet) => {
      try {
        applet._context = {utils: getScopedUtils(applet.id)};
        const setup = async () => {
          await applet.init(
            {...(applet?._context as any), tenant, mainToken, isAuthenticated},
            connectors.find((c) => c.id === applet.id),
          );
          await applet.updateState(updateReason, applet?._context as any);
        };

        await timeout(appletInitTimeout, setup());
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`Applet with id: ${applet.id} failed to init`);
        failedToInit.add(applet);
      }
    }),
  );

  return failedToInit;
};
