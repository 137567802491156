import createLucideIcon from '../createLucideIcon';

const Candy = createLucideIcon('Candy', [
  [
    'path',
    {
      d: 'm9.5 7.5-2 2a4.95 4.95 0 1 0 7 7l2-2a4.95 4.95 0 1 0-7-7Z',
      key: 'ue6khb',
    },
  ],
  ['path', { d: 'M14 6.5v10', key: '5xnk7c' }],
  ['path', { d: 'M10 7.5v10', key: '1uew51' }],
  [
    'path',
    {
      d: 'm16 7 1-5 1.37.68A3 3 0 0 0 19.7 3H21v1.3c0 .46.1.92.32 1.33L22 7l-5 1',
      key: 'b9cp6k',
    },
  ],
  [
    'path',
    {
      d: 'm8 17-1 5-1.37-.68A3 3 0 0 0 4.3 21H3v-1.3a3 3 0 0 0-.32-1.33L2 17l5-1',
      key: '5lney8',
    },
  ],
]);

export default Candy;
