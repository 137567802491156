import { isClient } from "@tamagui/constants";
import { useLayoutEffect } from "react";
import { THEME_CLASSNAME_PREFIX } from "../constants/constants.mjs";
import { Theme } from "./Theme.mjs";
import { jsx } from "react/jsx-runtime";
const ThemeProvider = props => (isClient && useLayoutEffect(() => {
  if (props.disableRootThemeClass) return;
  const cn = `${THEME_CLASSNAME_PREFIX}${props.defaultTheme}`,
    target = props.themeClassNameOnRoot ? document.documentElement : document.body;
  return target.classList.add(cn), () => {
    target.classList.remove(cn);
  };
}, [props.defaultTheme, props.disableRootThemeClass, props.themeClassNameOnRoot]), /* @__PURE__ */jsx(Theme, {
  className: props.className,
  name: props.defaultTheme,
  forceClassName: !props.disableRootThemeClass,
  _isRoot: !0,
  children: props.children
}));
export { ThemeProvider };