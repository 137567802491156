import createLucideIcon from '../createLucideIcon';

const PanelBottomOpen = createLucideIcon('PanelBottomOpen', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['line', { x1: '3', x2: '21', y1: '15', y2: '15', key: 'o2sbyz' }],
  ['path', { d: 'm9 10 3-3 3 3', key: '11gsxs' }],
]);

export default PanelBottomOpen;
