import createLucideIcon from '../createLucideIcon';

const AlignVerticalSpaceBetween = createLucideIcon(
  'AlignVerticalSpaceBetween',
  [
    [
      'rect',
      { width: '14', height: '6', x: '5', y: '15', rx: '2', key: '1w91an' },
    ],
    [
      'rect',
      { width: '10', height: '6', x: '7', y: '3', rx: '2', key: '17wqzy' },
    ],
    ['path', { d: 'M2 21h20', key: '1nyx9w' }],
    ['path', { d: 'M2 3h20', key: '91anmk' }],
  ],
);

export default AlignVerticalSpaceBetween;
