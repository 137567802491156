import createLucideIcon from '../createLucideIcon';

const SquareStack = createLucideIcon('SquareStack', [
  [
    'path',
    {
      d: 'M4 10c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2',
      key: '4i38lg',
    },
  ],
  [
    'path',
    {
      d: 'M10 16c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2',
      key: 'mlte4a',
    },
  ],
  [
    'rect',
    { width: '8', height: '8', x: '14', y: '14', rx: '2', key: '1fa9i4' },
  ],
]);

export default SquareStack;
