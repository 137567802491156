import createLucideIcon from '../createLucideIcon';

const BaggageClaim = createLucideIcon('BaggageClaim', [
  ['path', { d: 'M22 18H6a2 2 0 0 1-2-2V7a2 2 0 0 0-2-2', key: '4irg2o' }],
  ['path', { d: 'M17 14V4a2 2 0 0 0-2-2h-1a2 2 0 0 0-2 2v10', key: '14fcyx' }],
  [
    'rect',
    { width: '13', height: '8', x: '8', y: '6', rx: '1', key: 'o6oiis' },
  ],
  ['circle', { cx: '18', cy: '20', r: '2', key: 't9985n' }],
  ['circle', { cx: '9', cy: '20', r: '2', key: 'e5v82j' }],
]);

export default BaggageClaim;
