import createLucideIcon from '../createLucideIcon';

const ListMinus = createLucideIcon('ListMinus', [
  ['path', { d: 'M11 12H3', key: '51ecnj' }],
  ['path', { d: 'M16 6H3', key: '1wxfjs' }],
  ['path', { d: 'M16 18H3', key: '12xzn7' }],
  ['path', { d: 'M21 12h-6', key: 'bt1uis' }],
]);

export default ListMinus;
