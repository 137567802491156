import createLucideIcon from '../createLucideIcon';

const Projector = createLucideIcon('Projector', [
  ['path', { d: 'M5 7 3 5', key: '1yys58' }],
  ['path', { d: 'M9 6V3', key: '1ptz9u' }],
  ['path', { d: 'm13 7 2-2', key: '1w3vmq' }],
  ['circle', { cx: '9', cy: '13', r: '3', key: '1mma13' }],
  [
    'path',
    {
      d: 'M11.83 12H20a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h2.17',
      key: '2frwzc',
    },
  ],
  ['path', { d: 'M16 16h2', key: 'dnq2od' }],
]);

export default Projector;
