export const ar = {
  login: 'تسجيل الدخول',
  action: {
    save: 'حفظ',
    reset: 'إعادة ضبط',
    close: 'إغلاق',
    cancel: 'إلغاء',
    next: 'التالي',
    previous: 'السابق',
    seeMore: 'شاهد المزيد',
    accept: 'قبول',
    ok: 'قبول',
  },
  actions: 'أجراءات',
  settings: {
    title: 'الإعدادات',
    general: {
      _name: 'عام',
      connectors: 'التكاملات',
      connector: {
        enable: 'تفعيل',
        url: 'الرابط',
        username: 'اسم المستخدم',
        password: 'كلمة المرور',
        tenant: 'العميل',
        token: 'رمز المصادقة',
      },
    },
  },
  calendar: {
    title: 'التقويم',
    titleKey: 'العنوان',
    time: 'الوقت',
    noEvents: 'لا توجد أحداث',
    from: 'من',
    to: 'الى',
    day: 'يوم',
    week: 'أسبوع',
    month: 'شهر',
    filters: 'فلتر',
    today: 'اليوم',
    m: 'د',
    h: 'سا',
    months: {
      January: 'يناير',
      February: 'فبراير',
      March: 'مارس',
      April: 'أبريل',
      May: 'مايو',
      June: 'يونيو',
      July: 'يوليو',
      August: 'أغسطس',
      September: 'سبتمبر',
      October: 'أكتوبر',
      November: 'نوفمبر',
      December: 'ديسمبر',
    },
    days: {
      Sunday: 'الأحد',
      Monday: 'الاثنين',
      Tuesday: 'الثلاثاء',
      Wednesday: 'الأربعاء',
      Thursday: 'الخميس',
      Friday: 'الجمعة',
      Saturday: 'السبت',
    },
  },
  validation: {
    required: 'مطلوب',
  },
  success: 'نجاح',
  error: 'خطأ',
  generalErrorMsg: 'عذرا، هناك خطأ ما.',
  successMsg: 'تم, بنجاح',
  connectorMustBeDefined: 'الرجاء تهيئة الموصل أولا',
  connectorIsDisabled: 'برجاء العلم ان هذا الموصل غير مفعل',
  sortBy: 'رتب حسب',
  ago: 'منذ {{value}}',
  pagination: {
    rowsPerPage: 'الصفوف في الصفحة:',
    page: 'صفحة',
    of: 'من',
    perPage: 'كل صفحة',
  },
  hidjriMonths: {
    Muharram: 'مُحَرَّم',
    Safar: 'صفر',
    RabiAlAwwal: 'ربيع الأوّل',
    RabiAlThani: 'ربيع الثاني',
    JumadaAlUla: 'جمادى الأولى',
    JumadaAlThaniyah: 'جمادى الثانية',
    Rajab: 'رجب',
    Shaaban: 'شعبان',
    Ramadan: 'رمضان',
    Shawwal: 'شوّال',
    DhuAlQidah: 'ذو القعدة',
    DhuAlHijjah: 'ذو الحجة',
  },
  showAllColumns: 'اظهار كل الأعمدة',
  columns: 'الأعمدة',
  showOnly: 'أظهر فقط',
  showDetails: 'التفاصيل',
  testConnection: 'اختبار الاتصال',
  connectionFailed: 'فشل الاتصال',
  connectionSuccess: 'نجاح الاتصال',
  languageChangeMsg: 'لن تسري بعض التغييرات إلا بعد إعادة تشغيل التطبيق',
  noData: 'لاتوجد بيانات',
  fileUploader: {
    dragAndDropOrClickHere: 'قم بسحب وإفلات الملفات أو انقر هنا',
    dropItHere: 'أسقطها هنا',
    uploading: 'جاري التحميل',
    fileUploadedSuccessfully: 'تم تحميل الملف بنجاح',
    MB: 'ميغابايت',
  },
};
