import createLucideIcon from '../createLucideIcon';

const ScreenShareOff = createLucideIcon('ScreenShareOff', [
  [
    'path',
    {
      d: 'M13 3H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-3',
      key: 'i8wdob',
    },
  ],
  ['path', { d: 'M8 21h8', key: '1ev6f3' }],
  ['path', { d: 'M12 17v4', key: '1riwvh' }],
  ['path', { d: 'm22 3-5 5', key: '12jva0' }],
  ['path', { d: 'm17 3 5 5', key: 'k36vhe' }],
]);

export default ScreenShareOff;
