import createLucideIcon from '../createLucideIcon';

const Satellite = createLucideIcon('Satellite', [
  ['path', { d: 'M13 7 9 3 5 7l4 4', key: 'vyckw6' }],
  ['path', { d: 'm17 11 4 4-4 4-4-4', key: 'rchckc' }],
  ['path', { d: 'm8 12 4 4 6-6-4-4Z', key: '1sshf7' }],
  ['path', { d: 'm16 8 3-3', key: 'x428zp' }],
  ['path', { d: 'M9 21a6 6 0 0 0-6-6', key: '1iajcf' }],
]);

export default Satellite;
