import createLucideIcon from '../createLucideIcon';

const FileImage = createLucideIcon('FileImage', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['circle', { cx: '10', cy: '13', r: '2', key: '6v46hv' }],
  ['path', { d: 'm20 17-1.09-1.09a2 2 0 0 0-2.82 0L10 22', key: '17vly1' }],
]);

export default FileImage;
