import createLucideIcon from '../createLucideIcon';

const ClipboardPaste = createLucideIcon('ClipboardPaste', [
  [
    'path',
    {
      d: 'M15 2H9a1 1 0 0 0-1 1v2c0 .6.4 1 1 1h6c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1Z',
      key: '1pp7kr',
    },
  ],
  [
    'path',
    {
      d: 'M8 4H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2M16 4h2a2 2 0 0 1 2 2v2M11 14h10',
      key: '2ik1ml',
    },
  ],
  ['path', { d: 'm17 10 4 4-4 4', key: 'vp2hj1' }],
]);

export default ClipboardPaste;
