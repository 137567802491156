import createLucideIcon from '../createLucideIcon';

const HeartCrack = createLucideIcon('HeartCrack', [
  [
    'path',
    {
      d: 'M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z',
      key: 'c3ymky',
    },
  ],
  ['path', { d: 'm12 13-1-1 2-2-3-3 2-2', key: 'xjdxli' }],
]);

export default HeartCrack;
