import createLucideIcon from '../createLucideIcon';

const Unplug = createLucideIcon('Unplug', [
  ['path', { d: 'm19 5 3-3', key: 'yk6iyv' }],
  ['path', { d: 'm2 22 3-3', key: '19mgm9' }],
  [
    'path',
    {
      d: 'M6.3 20.3a2.4 2.4 0 0 0 3.4 0L12 18l-6-6-2.3 2.3a2.4 2.4 0 0 0 0 3.4Z',
      key: 'goz73y',
    },
  ],
  ['path', { d: 'M7.5 13.5 10 11', key: '7xgeeb' }],
  ['path', { d: 'M10.5 16.5 13 14', key: '10btkg' }],
  [
    'path',
    {
      d: 'm12 6 6 6 2.3-2.3a2.4 2.4 0 0 0 0-3.4l-2.6-2.6a2.4 2.4 0 0 0-3.4 0Z',
      key: '1snsnr',
    },
  ],
]);

export default Unplug;
