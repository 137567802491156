import createLucideIcon from '../createLucideIcon';

const LayoutPanelTop = createLucideIcon('LayoutPanelTop', [
  [
    'rect',
    { width: '18', height: '7', x: '3', y: '3', rx: '1', key: 'f1a2em' },
  ],
  [
    'rect',
    { width: '7', height: '7', x: '3', y: '14', rx: '1', key: '1bb6yr' },
  ],
  [
    'rect',
    { width: '7', height: '7', x: '14', y: '14', rx: '1', key: 'nxv5o0' },
  ],
]);

export default LayoutPanelTop;
