import createLucideIcon from '../createLucideIcon';

const Dog = createLucideIcon('Dog', [
  [
    'path',
    {
      d: 'M10 5.172C10 3.782 8.423 2.679 6.5 3c-2.823.47-4.113 6.006-4 7 .08.703 1.725 1.722 3.656 1 1.261-.472 1.96-1.45 2.344-2.5',
      key: '19br0u',
    },
  ],
  [
    'path',
    {
      d: 'M14.267 5.172c0-1.39 1.577-2.493 3.5-2.172 2.823.47 4.113 6.006 4 7-.08.703-1.725 1.722-3.656 1-1.261-.472-1.855-1.45-2.239-2.5',
      key: '11n1an',
    },
  ],
  ['path', { d: 'M8 14v.5', key: '1nzgdb' }],
  ['path', { d: 'M16 14v.5', key: '1lajdz' }],
  ['path', { d: 'M11.25 16.25h1.5L12 17l-.75-.75Z', key: '12kq1m' }],
  [
    'path',
    {
      d: 'M4.42 11.247A13.152 13.152 0 0 0 4 14.556C4 18.728 7.582 21 12 21s8-2.272 8-6.444c0-1.061-.162-2.2-.493-3.309m-9.243-6.082A8.801 8.801 0 0 1 12 5c.78 0 1.5.108 2.161.306',
      key: 'wsu29d',
    },
  ],
]);

export default Dog;
