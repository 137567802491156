import createLucideIcon from '../createLucideIcon';

const SpellCheck2 = createLucideIcon('SpellCheck2', [
  ['path', { d: 'm6 16 6-12 6 12', key: '1b4byz' }],
  ['path', { d: 'M8 12h8', key: '1wcyev' }],
  [
    'path',
    {
      d: 'M4 21c1.1 0 1.1-1 2.3-1s1.1 1 2.3 1c1.1 0 1.1-1 2.3-1 1.1 0 1.1 1 2.3 1 1.1 0 1.1-1 2.3-1 1.1 0 1.1 1 2.3 1 1.1 0 1.1-1 2.3-1',
      key: '8mdmtu',
    },
  ],
]);

export default SpellCheck2;
