import createLucideIcon from '../createLucideIcon';

const ChefHat = createLucideIcon('ChefHat', [
  [
    'path',
    {
      d: 'M6 13.87A4 4 0 0 1 7.41 6a5.11 5.11 0 0 1 1.05-1.54 5 5 0 0 1 7.08 0A5.11 5.11 0 0 1 16.59 6 4 4 0 0 1 18 13.87V21H6Z',
      key: 'z3ra2g',
    },
  ],
  ['line', { x1: '6', x2: '18', y1: '17', y2: '17', key: '12q60k' }],
]);

export default ChefHat;
