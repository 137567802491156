import createLucideIcon from '../createLucideIcon';

const BookmarkMinus = createLucideIcon('BookmarkMinus', [
  [
    'path',
    { d: 'm19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z', key: '1fy3hk' },
  ],
  ['line', { x1: '15', x2: '9', y1: '10', y2: '10', key: '1gty7f' }],
]);

export default BookmarkMinus;
