import createLucideIcon from '../createLucideIcon';

const SigmaSquare = createLucideIcon('SigmaSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M16 8.9V7H8l4 5-4 5h8v-1.9', key: '9nih0i' }],
]);

export default SigmaSquare;
