import createLucideIcon from '../createLucideIcon';

const FlaskRound = createLucideIcon('FlaskRound', [
  ['path', { d: 'M10 2v7.31', key: '5d1hyh' }],
  ['path', { d: 'M14 9.3V1.99', key: '14k4l0' }],
  ['path', { d: 'M8.5 2h7', key: 'csnxdl' }],
  ['path', { d: 'M14 9.3a6.5 6.5 0 1 1-4 0', key: '1r8fvy' }],
  ['path', { d: 'M5.52 16h12.96', key: '46hh1i' }],
]);

export default FlaskRound;
