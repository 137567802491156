import createLucideIcon from '../createLucideIcon';

const DivideSquare = createLucideIcon('DivideSquare', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['line', { x1: '8', x2: '16', y1: '12', y2: '12', key: '1jonct' }],
  ['line', { x1: '12', x2: '12', y1: '16', y2: '16', key: 'aqc6ln' }],
  ['line', { x1: '12', x2: '12', y1: '8', y2: '8', key: '1mkcni' }],
]);

export default DivideSquare;
