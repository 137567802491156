import createLucideIcon from '../createLucideIcon';

const Forklift = createLucideIcon('Forklift', [
  ['path', { d: 'M12 12H5a2 2 0 0 0-2 2v5', key: '7zsz91' }],
  ['circle', { cx: '13', cy: '19', r: '2', key: 'wjnkru' }],
  ['circle', { cx: '5', cy: '19', r: '2', key: 'v8kfzx' }],
  [
    'path',
    { d: 'M8 19h3m5-17v17h6M6 12V7c0-1.1.9-2 2-2h3l5 5', key: '13bk1p' },
  ],
]);

export default Forklift;
