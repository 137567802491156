import { isServer, isWeb } from "@tamagui/constants";
import { useRef, useSyncExternalStore } from "react";
import { getConfig } from "../config.mjs";
import { matchMedia } from "../helpers/matchMedia.mjs";
import { pseudoDescriptors } from "../helpers/pseudoDescriptors.mjs";
import { getDisableSSR } from "./useDisableSSR.mjs";
let mediaState =
// development only safeguard
process.env.NODE_ENV === "development" ? new Proxy({}, {
  get(target, key) {
    if (typeof key == "string" && key[0] === "$" &&
    // dont error on $$typeof
    key[1] !== "$") throw new Error(`Access mediaState should not use "$": ${key}`);
    return Reflect.get(target, key);
  }
}) : {};
const mediaQueryConfig = {},
  getMedia = () => mediaState,
  mediaKeys = /* @__PURE__ */new Set(),
  isMediaKey = key => {
    if (mediaKeys.has(key)) return !0;
    if (key[0] === "$") {
      if (key.startsWith("$platform-")) return "platform";
      if (key.startsWith("$theme-")) return "theme";
      if (key.startsWith("$group-")) return "group";
    }
    return !1;
  };
let initState;
const defaultMediaImportance = Object.keys(pseudoDescriptors).length;
let mediaKeysOrdered;
const getMediaKeyImportance = key => {
    if (process.env.NODE_ENV === "development" && key[0] === "$") throw new Error("use short key");
    return getConfig().settings.mediaPropOrder ? defaultMediaImportance : mediaKeysOrdered.indexOf(key) + 100;
  },
  dispose = /* @__PURE__ */new Set();
let mediaVersion = 0;
const configureMedia = config => {
  const {
    media,
    mediaQueryDefaultActive
  } = config;
  if (media) {
    mediaVersion++;
    for (const key in media) mediaState[key] = mediaQueryDefaultActive?.[key] || !1, mediaKeys.add(`$${key}`);
    Object.assign(mediaQueryConfig, media), initState = {
      ...mediaState
    }, mediaKeysOrdered = Object.keys(media), config.disableSSR ? setupMediaListeners() : updateCurrentState();
  }
};
function unlisten() {
  dispose.forEach(cb => cb()), dispose.clear();
}
let setupVersion = -1;
function setupMediaListeners() {
  if (!(isWeb && isServer) && setupVersion !== mediaVersion) {
    setupVersion = mediaVersion, unlisten();
    for (const key in mediaQueryConfig) {
      let update = function () {
        const next = !!getMatch().matches;
        next !== mediaState[key] && (mediaState = {
          ...mediaState,
          [key]: next
        }, updateCurrentState());
      };
      const str = mediaObjectToString(mediaQueryConfig[key], key),
        getMatch = () => matchMedia(str),
        match = getMatch();
      if (!match) throw new Error("\u26A0\uFE0F No match");
      match.addListener(update), dispose.add(() => {
        match.removeListener(update);
      }), update();
    }
  }
}
const listeners = /* @__PURE__ */new Set();
let flushing = !1,
  flushVersion = -1;
function updateCurrentState() {
  flushing && flushVersion === mediaVersion || (flushVersion = mediaVersion, flushing = !0, Promise.resolve().then(() => {
    flushing = !1, listeners.forEach(cb => cb(mediaState));
  }));
}
const shouldUpdate = /* @__PURE__ */new WeakMap();
function setMediaShouldUpdate(ref, props) {
  return shouldUpdate.set(ref, props);
}
function subscribe(subscriber) {
  return listeners.add(subscriber), () => listeners.delete(subscriber);
}
function useMedia(uid, componentContext) {
  const internal = useRef(),
    initialState = (getDisableSSR(componentContext) || !isWeb ? mediaState : initState) || {},
    state = useSyncExternalStore(subscribe, () => {
      if (!internal.current) return initialState;
      const {
          touched,
          prev
        } = internal.current,
        componentState = uid ? shouldUpdate.get(uid) : void 0;
      if (componentState && componentState.enabled === !1) return prev;
      const testKeys = componentState?.keys ?? ((!componentState || componentState.enabled) && touched ? [...touched] : null);
      return !testKeys || testKeys?.every(key => mediaState[key] === prev[key]) ? prev : (internal.current.prev = mediaState, mediaState);
    }, () => initialState);
  return new Proxy(state, {
    get(_, key) {
      return typeof key == "string" && (internal.current ||= {
        prev: initialState
      }, internal.current.touched ||= /* @__PURE__ */new Set(), internal.current.touched.add(key)), Reflect.get(state, key);
    }
  });
}
const getMediaImportanceIfMoreImportant = (mediaKey, key, importancesUsed, isSizeMedia) => {
  const conf = getConfig(),
    importance = isSizeMedia && !conf.settings.mediaPropOrder ? getMediaKeyImportance(mediaKey) : defaultMediaImportance;
  return !importancesUsed[key] || importance > importancesUsed[key] ? importance : null;
};
function camelToHyphen(str) {
  return str.replace(/[A-Z]/g, m => `-${m.toLowerCase()}`).toLowerCase();
}
const cache = /* @__PURE__ */new WeakMap(),
  cachedMediaKeyToQuery = {};
function mediaObjectToString(query, key) {
  if (typeof query == "string") return query;
  if (cache.has(query)) return cache.get(query);
  const res = Object.entries(query).map(([feature, value]) => (feature = camelToHyphen(feature), typeof value == "string" ? `(${feature}: ${value})` : (typeof value == "number" && /[height|width]$/.test(feature) && (value = `${value}px`), `(${feature}: ${value})`))).join(" and ");
  return key && (cachedMediaKeyToQuery[key] = res), cache.set(query, res), res;
}
function mediaKeyToQuery(key) {
  return cachedMediaKeyToQuery[key] || mediaObjectToString(mediaQueryConfig[key], key);
}
function mediaKeyMatch(key, dimensions) {
  const mediaQueries = mediaQueryConfig[key];
  return Object.keys(mediaQueries).every(query => {
    const expectedVal = +mediaQueries[query],
      isMax = query.startsWith("max"),
      isWidth = query.endsWith("Width"),
      givenVal = dimensions[isWidth ? "width" : "height"];
    return isMax ? givenVal < expectedVal : givenVal > expectedVal;
  });
}
export { configureMedia, getMedia, getMediaImportanceIfMoreImportant, getMediaKeyImportance, isMediaKey, mediaKeyMatch, mediaKeyToQuery, mediaKeys, mediaObjectToString, mediaQueryConfig, mediaState, setMediaShouldUpdate, setupMediaListeners, useMedia };