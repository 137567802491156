import {CommonElements, ViewProps} from '../components';

import {AppletWidget} from './Applet';
import {IconType} from './IconType';

export enum NodeType {
  VIEW = 'VIEW',
  LINK = 'LINK',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  BUTTON = 'BUTTON',
  ACCORDION = 'ACCORDION',
  WEB_VIEW = 'WEB_VIEW',
}

export enum SectionType {
  PAGE = 'PAGE',
  ROW = 'ROW',
  CELL = 'CELL',
  NODE = 'NODE',
}

export type CommonElementsNames = keyof typeof CommonElements;

export type RowLayout = Array<1 | 2>;

export interface BuilderBlockComponent {
  ({children, ...props}: any): React.JSX.Element | null;
}

export type PageSection = {
  id: string;
  rows: PageRow[];
  props: Record<string, any>;
};

export type PageRow = {
  id: string;
  cells: PageCell[];
  props: Record<string, any>;
};

export type PageCell = {
  id: string;
  nodes: PageNode[] | [];
  props: Record<string, any>;
};

export type PageNode = BuilderElement | BuilderWidgetElement;

export type BuilderElement<T = ViewProps> = {
  id: string;
  type: NodeType;
  icon?: IconType;
  nodeId: string;
  Component?: BuilderBlockComponent;
  props: T;
};

export type BuilderWidgetElement = AppletWidget & {
  nodeId: string;
  props: ViewProps;
};

// Type Guards
export function isBuilderWidgetElement(node: PageNode): node is BuilderWidgetElement {
  return node && 'appletId' in node;
}

export function isBuilderElement(node: PageNode): node is BuilderElement {
  return node && !('appletId' in node);
}
