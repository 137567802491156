import createLucideIcon from '../createLucideIcon';

const Microwave = createLucideIcon('Microwave', [
  [
    'rect',
    { width: '20', height: '15', x: '2', y: '4', rx: '2', key: '2no95f' },
  ],
  ['rect', { width: '8', height: '7', x: '6', y: '8', rx: '1', key: 'zh9wx' }],
  ['path', { d: 'M18 8v7', key: 'o5zi4n' }],
  ['path', { d: 'M6 19v2', key: '1loha6' }],
  ['path', { d: 'M18 19v2', key: '1dawf0' }],
]);

export default Microwave;
