import createLucideIcon from '../createLucideIcon';

const Unlink2 = createLucideIcon('Unlink2', [
  [
    'path',
    { d: 'M15 7h2a5 5 0 0 1 0 10h-2m-6 0H7A5 5 0 0 1 7 7h2', key: '1re2ne' },
  ],
]);

export default Unlink2;
