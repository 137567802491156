import createLucideIcon from '../createLucideIcon';

const BookOpenCheck = createLucideIcon('BookOpenCheck', [
  [
    'path',
    { d: 'M8 3H2v15h7c1.7 0 3 1.3 3 3V7c0-2.2-1.8-4-4-4Z', key: '1i8u0n' },
  ],
  ['path', { d: 'm16 12 2 2 4-4', key: 'mdajum' }],
  [
    'path',
    {
      d: 'M22 6V3h-6c-2.2 0-4 1.8-4 4v14c0-1.7 1.3-3 3-3h7v-2.3',
      key: 'jb5l51',
    },
  ],
]);

export default BookOpenCheck;
