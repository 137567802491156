import createLucideIcon from '../createLucideIcon';

const ToyBrick = createLucideIcon('ToyBrick', [
  [
    'rect',
    { width: '18', height: '12', x: '3', y: '8', rx: '1', key: '158fvp' },
  ],
  ['path', { d: 'M10 8V5c0-.6-.4-1-1-1H6a1 1 0 0 0-1 1v3', key: 's0042v' }],
  ['path', { d: 'M19 8V5c0-.6-.4-1-1-1h-3a1 1 0 0 0-1 1v3', key: '9wmeh2' }],
]);

export default ToyBrick;
