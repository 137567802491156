import createLucideIcon from '../createLucideIcon';

const AlignHorizontalJustifyEnd = createLucideIcon(
  'AlignHorizontalJustifyEnd',
  [
    [
      'rect',
      { width: '6', height: '14', x: '2', y: '5', rx: '2', key: 'dy24zr' },
    ],
    [
      'rect',
      { width: '6', height: '10', x: '12', y: '7', rx: '2', key: '1ht384' },
    ],
    ['path', { d: 'M22 2v20', key: '40qfg1' }],
  ],
);

export default AlignHorizontalJustifyEnd;
