import createLucideIcon from '../createLucideIcon';

const ArrowUpDown = createLucideIcon('ArrowUpDown', [
  ['path', { d: 'm21 16-4 4-4-4', key: 'f6ql7i' }],
  ['path', { d: 'M17 20V4', key: '1ejh1v' }],
  ['path', { d: 'm3 8 4-4 4 4', key: '11wl7u' }],
  ['path', { d: 'M7 4v16', key: '1glfcx' }],
]);

export default ArrowUpDown;
