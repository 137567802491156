import createLucideIcon from '../createLucideIcon';

const GripVertical = createLucideIcon('GripVertical', [
  ['circle', { cx: '9', cy: '12', r: '1', key: '1vctgf' }],
  ['circle', { cx: '9', cy: '5', r: '1', key: 'hp0tcf' }],
  ['circle', { cx: '9', cy: '19', r: '1', key: 'fkjjf6' }],
  ['circle', { cx: '15', cy: '12', r: '1', key: '1tmaij' }],
  ['circle', { cx: '15', cy: '5', r: '1', key: '19l28e' }],
  ['circle', { cx: '15', cy: '19', r: '1', key: 'f4zoj3' }],
]);

export default GripVertical;
