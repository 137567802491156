import createLucideIcon from '../createLucideIcon';

const Variable = createLucideIcon('Variable', [
  ['path', { d: 'M8 21s-4-3-4-9 4-9 4-9', key: 'uto9ud' }],
  ['path', { d: 'M16 3s4 3 4 9-4 9-4 9', key: '4w2vsq' }],
  ['line', { x1: '15', x2: '9', y1: '9', y2: '15', key: 'f7djnv' }],
  ['line', { x1: '9', x2: '15', y1: '9', y2: '15', key: '1shsy8' }],
]);

export default Variable;
