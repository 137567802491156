import createLucideIcon from '../createLucideIcon';

const UnfoldHorizontal = createLucideIcon('UnfoldHorizontal', [
  ['path', { d: 'M16 12h6', key: '15xry1' }],
  ['path', { d: 'M8 12H2', key: '1jqql6' }],
  ['path', { d: 'M12 2v2', key: 'tus03m' }],
  ['path', { d: 'M12 8v2', key: '1woqiv' }],
  ['path', { d: 'M12 14v2', key: '8jcxud' }],
  ['path', { d: 'M12 20v2', key: '1lh1kg' }],
  ['path', { d: 'm19 15 3-3-3-3', key: 'wjy7rq' }],
  ['path', { d: 'm5 9-3 3 3 3', key: 'j64kie' }],
]);

export default UnfoldHorizontal;
