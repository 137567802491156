import createLucideIcon from '../createLucideIcon';

const SprayCan = createLucideIcon('SprayCan', [
  ['path', { d: 'M3 3h.01', key: '159qn6' }],
  ['path', { d: 'M7 5h.01', key: '1hq22a' }],
  ['path', { d: 'M11 7h.01', key: '1osv80' }],
  ['path', { d: 'M3 7h.01', key: '1xzrh3' }],
  ['path', { d: 'M7 9h.01', key: '19b3jx' }],
  ['path', { d: 'M3 11h.01', key: '1eifu7' }],
  ['rect', { width: '4', height: '4', x: '15', y: '5', key: 'mri9e4' }],
  [
    'path',
    { d: 'm19 9 2 2v10c0 .6-.4 1-1 1h-6c-.6 0-1-.4-1-1V11l2-2', key: 'aib6hk' },
  ],
  ['path', { d: 'm13 14 8-2', key: '1d7bmk' }],
  ['path', { d: 'm13 19 8-2', key: '1y2vml' }],
]);

export default SprayCan;
