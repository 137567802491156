import createLucideIcon from '../createLucideIcon';

const Maximize2 = createLucideIcon('Maximize2', [
  ['polyline', { points: '15 3 21 3 21 9', key: 'mznyad' }],
  ['polyline', { points: '9 21 3 21 3 15', key: '1avn1i' }],
  ['line', { x1: '21', x2: '14', y1: '3', y2: '10', key: 'ota7mn' }],
  ['line', { x1: '3', x2: '10', y1: '21', y2: '14', key: '1atl0r' }],
]);

export default Maximize2;
