import { getVariableValue } from "../createVariable.mjs";
function getVariantExtras(styleState) {
  const {
    curProps,
    props,
    conf,
    context,
    theme
  } = styleState;
  let fonts = conf.fontsParsed;
  context?.language && (fonts = getFontsForLanguage(conf.fontsParsed, context.language));
  const fontFamily = getVariableValue(styleState.fontFamily || styleState.curProps.fontFamily || styleState.conf.defaultFont),
    font = fonts[fontFamily] || fonts[styleState.conf.defaultFont];
  return {
    fonts,
    tokens: conf.tokensParsed,
    theme,
    fontFamily,
    font,
    // TODO do this in splitstlye
    // we avoid passing in default props for media queries because that would confuse things like SizableText.size:
    props: new Proxy(props, {
      // handles shorthands
      get(target, key) {
        for (const tryKey of [key, conf.inverseShorthands[key]]) if (tryKey) return Reflect.has(curProps, tryKey) ? Reflect.get(curProps, tryKey) : Reflect.get(target, tryKey);
      }
    })
  };
}
const fontLanguageCache = /* @__PURE__ */new WeakMap();
function getFontsForLanguage(fonts, language) {
  if (fontLanguageCache.has(language)) return fontLanguageCache.get(language);
  const next = {
    ...fonts,
    ...Object.fromEntries(Object.entries(language).map(([name, lang]) => {
      if (lang === "default") return [];
      const langKey = `$${name}_${lang}`;
      return [`$${name}`, fonts[langKey]];
    }))
  };
  return fontLanguageCache.set(language, next), next;
}
export { getFontsForLanguage, getVariantExtras };