import createLucideIcon from '../createLucideIcon';

const UserSquare2 = createLucideIcon('UserSquare2', [
  ['path', { d: 'M18 21a6 6 0 0 0-12 0', key: 'kaz2du' }],
  ['circle', { cx: '12', cy: '11', r: '4', key: '1gt34v' }],
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
]);

export default UserSquare2;
