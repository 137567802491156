import createLucideIcon from '../createLucideIcon';

const Rows = createLucideIcon('Rows', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['line', { x1: '3', x2: '21', y1: '12', y2: '12', key: '10d38w' }],
]);

export default Rows;
