import createLucideIcon from '../createLucideIcon';

const Combine = createLucideIcon('Combine', [
  ['rect', { width: '8', height: '8', x: '2', y: '2', rx: '2', key: 'z1hh3n' }],
  ['path', { d: 'M14 2c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2', key: '83orz6' }],
  ['path', { d: 'M20 2c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2', key: 'k86dmt' }],
  ['path', { d: 'M10 18H5c-1.7 0-3-1.3-3-3v-1', key: '6vokjl' }],
  ['polyline', { points: '7 21 10 18 7 15', key: '1k02g0' }],
  [
    'rect',
    { width: '8', height: '8', x: '14', y: '14', rx: '2', key: '1fa9i4' },
  ],
]);

export default Combine;
