import createLucideIcon from '../createLucideIcon';

const Wifi = createLucideIcon('Wifi', [
  ['path', { d: 'M5 13a10 10 0 0 1 14 0', key: '6v8j51' }],
  ['path', { d: 'M8.5 16.5a5 5 0 0 1 7 0', key: 'sej527' }],
  ['path', { d: 'M2 8.82a15 15 0 0 1 20 0', key: 'dnpr2z' }],
  ['line', { x1: '12', x2: '12.01', y1: '20', y2: '20', key: 'of4bc4' }],
]);

export default Wifi;
