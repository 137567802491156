import createLucideIcon from '../createLucideIcon';

const Carrot = createLucideIcon('Carrot', [
  [
    'path',
    {
      d: 'M2.27 21.7s9.87-3.5 12.73-6.36a4.5 4.5 0 0 0-6.36-6.37C5.77 11.84 2.27 21.7 2.27 21.7zM8.64 14l-2.05-2.04M15.34 15l-2.46-2.46',
      key: 'rfqxbe',
    },
  ],
  [
    'path',
    {
      d: 'M22 9s-1.33-2-3.5-2C16.86 7 15 9 15 9s1.33 2 3.5 2S22 9 22 9z',
      key: '6b25w4',
    },
  ],
  [
    'path',
    {
      d: 'M15 2s-2 1.33-2 3.5S15 9 15 9s2-1.84 2-3.5C17 3.33 15 2 15 2z',
      key: 'fn65lo',
    },
  ],
]);

export default Carrot;
