import createLucideIcon from '../createLucideIcon';

const RadioTower = createLucideIcon('RadioTower', [
  ['path', { d: 'M4.9 16.1C1 12.2 1 5.8 4.9 1.9', key: 's0qx1y' }],
  ['path', { d: 'M7.8 4.7a6.14 6.14 0 0 0-.8 7.5', key: '1idnkw' }],
  ['circle', { cx: '12', cy: '9', r: '2', key: '1092wv' }],
  ['path', { d: 'M16.2 4.8c2 2 2.26 5.11.8 7.47', key: 'ojru2q' }],
  ['path', { d: 'M19.1 1.9a9.96 9.96 0 0 1 0 14.1', key: 'rhi7fg' }],
  ['path', { d: 'M9.5 18h5', key: 'mfy3pd' }],
  ['path', { d: 'm8 22 4-11 4 11', key: '25yftu' }],
]);

export default RadioTower;
