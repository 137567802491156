import createLucideIcon from '../createLucideIcon';

const Tablet = createLucideIcon('Tablet', [
  [
    'rect',
    {
      width: '16',
      height: '20',
      x: '4',
      y: '2',
      rx: '2',
      ry: '2',
      key: '76otgf',
    },
  ],
  ['line', { x1: '12', x2: '12.01', y1: '18', y2: '18', key: '1dp563' }],
]);

export default Tablet;
