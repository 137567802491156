import { styled, useEvent } from "@tamagui/core";
import { composeEventHandlers, withStaticProperties } from "@tamagui/helpers";
import { ThemeableStack } from "@tamagui/stacks";
import { SizableText } from "@tamagui/text";
import { useControllableState } from "@tamagui/use-controllable-state";
import * as React from "react";
import { TOAST_NAME } from "./constants.mjs";
import { ToastAnnounceExclude } from "./ToastAnnounce.mjs";
import { useToast, useToastController, useToastState } from "./ToastImperative.mjs";
import { ToastImpl, ToastImplFrame, useToastInteractiveContext } from "./ToastImpl.mjs";
import { ToastProvider } from "./ToastProvider.mjs";
import { ToastViewport } from "./ToastViewport.mjs";
import { jsx } from "react/jsx-runtime";
const TITLE_NAME = "ToastTitle",
  ToastTitle = styled(SizableText, {
    name: TITLE_NAME,
    variants: {
      unstyled: {
        false: {
          color: "$color",
          size: "$4"
        }
      }
    },
    defaultVariants: {
      unstyled: process.env.TAMAGUI_HEADLESS === "1"
    }
  });
ToastTitle.displayName = TITLE_NAME;
const DESCRIPTION_NAME = "ToastDescription",
  ToastDescription = styled(SizableText, {
    name: DESCRIPTION_NAME,
    variants: {
      unstyled: {
        false: {
          color: "$color11",
          size: "$1"
        }
      }
    },
    defaultVariants: {
      unstyled: process.env.TAMAGUI_HEADLESS === "1"
    }
  });
ToastDescription.displayName = DESCRIPTION_NAME;
const ACTION_NAME = "ToastAction",
  ToastAction = React.forwardRef((props, forwardedRef) => {
    const {
      altText,
      ...actionProps
    } = props;
    return altText ? /* @__PURE__ */jsx(ToastAnnounceExclude, {
      altText,
      asChild: !0,
      children: /* @__PURE__ */jsx(ToastClose, {
        ...actionProps,
        ref: forwardedRef
      })
    }) : null;
  });
ToastAction.propTypes = {
  altText(props) {
    return props.altText ? null : new Error(`Missing prop \`altText\` expected on \`${ACTION_NAME}\``);
  }
};
ToastAction.displayName = ACTION_NAME;
const CLOSE_NAME = "ToastClose",
  ToastCloseFrame = styled(ThemeableStack, {
    name: CLOSE_NAME,
    tag: "button"
  }),
  ToastClose = React.forwardRef((props, forwardedRef) => {
    const {
        __scopeToast,
        ...closeProps
      } = props,
      interactiveContext = useToastInteractiveContext(__scopeToast);
    return /* @__PURE__ */jsx(ToastAnnounceExclude, {
      asChild: !0,
      children: /* @__PURE__ */jsx(ToastCloseFrame, {
        accessibilityLabel: "Dialog Close",
        ...closeProps,
        ref: forwardedRef,
        onPress: composeEventHandlers(props.onPress, interactiveContext.onClose)
      })
    });
  });
ToastClose.displayName = CLOSE_NAME;
const ToastComponent = ToastImplFrame.styleable((props, forwardedRef) => {
  const {
      forceMount,
      open: openProp,
      defaultOpen,
      onOpenChange,
      ...toastProps
    } = props,
    [open, setOpen] = useControllableState({
      prop: openProp,
      defaultProp: defaultOpen ?? !0,
      onChange: onOpenChange,
      strategy: "most-recent-wins"
    }),
    id = React.useId(),
    onPause = useEvent(props.onPause),
    onResume = useEvent(props.onResume);
  return forceMount || open ? /* @__PURE__ */jsx(ToastImpl, {
    id,
    open,
    ...toastProps,
    ref: forwardedRef,
    onClose: () => setOpen(!1),
    onPause,
    onResume,
    onSwipeEnd: composeEventHandlers(props.onSwipeEnd, event => {
      setOpen(!1);
    })
  }) : null;
});
ToastComponent.displayName = TOAST_NAME;
const Toast = withStaticProperties(ToastComponent, {
  Title: ToastTitle,
  Description: ToastDescription,
  Action: ToastAction,
  Close: ToastClose
});
export { Toast, ToastProvider, ToastViewport, useToast, useToastController, useToastState };