import createLucideIcon from '../createLucideIcon';

const CloudHail = createLucideIcon('CloudHail', [
  [
    'path',
    {
      d: 'M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242',
      key: '1pljnt',
    },
  ],
  ['path', { d: 'M16 14v2', key: 'a1is7l' }],
  ['path', { d: 'M8 14v2', key: '1e9m6t' }],
  ['path', { d: 'M16 20h.01', key: 'xwek51' }],
  ['path', { d: 'M8 20h.01', key: '1vjney' }],
  ['path', { d: 'M12 16v2', key: 'z66u1j' }],
  ['path', { d: 'M12 22h.01', key: '1urd7a' }],
]);

export default CloudHail;
