import createLucideIcon from '../createLucideIcon';

const ArrowRightLeft = createLucideIcon('ArrowRightLeft', [
  ['path', { d: 'm16 3 4 4-4 4', key: '1x1c3m' }],
  ['path', { d: 'M20 7H4', key: 'zbl0bi' }],
  ['path', { d: 'm8 21-4-4 4-4', key: 'h9nckh' }],
  ['path', { d: 'M4 17h16', key: 'g4d7ey' }],
]);

export default ArrowRightLeft;
