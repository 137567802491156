import createLucideIcon from '../createLucideIcon';

const TouchpadOff = createLucideIcon('TouchpadOff', [
  ['path', { d: 'M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16', key: 'lnt0bk' }],
  ['path', { d: 'M2 14h12', key: 'd8icqz' }],
  ['path', { d: 'M22 14h-2', key: 'jrx26d' }],
  ['path', { d: 'M12 20v-6', key: '1rm09r' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  ['path', { d: 'M22 16V6a2 2 0 0 0-2-2H10', key: '11y8e4' }],
]);

export default TouchpadOff;
