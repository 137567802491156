import createLucideIcon from '../createLucideIcon';

const Infinity = createLucideIcon('Infinity', [
  [
    'path',
    {
      d: 'M12 12c-2-2.67-4-4-6-4a4 4 0 1 0 0 8c2 0 4-1.33 6-4Zm0 0c2 2.67 4 4 6 4a4 4 0 0 0 0-8c-2 0-4 1.33-6 4Z',
      key: '1z0uae',
    },
  ],
]);

export default Infinity;
