import React from 'react';

import {nanoid} from 'nanoid';

import {BuilderElement, LocalizedTextType, NodeType} from '../../../types';
import {Accordion, AccordionProps} from '../../Accordion';

export type AccordionElementProps = AccordionProps & {
  value?: LocalizedTextType;
  fontSize?: number;
};

const defaultProps: AccordionElementProps = {
  sections: [
    {
      id: nanoid(),
      title: 'Section 1',
      content:
        'Your content goes here. Edit styles and content or remove this text From the side bar  / Your content goes here. Edit styles and content or remove this text From the side bar',
    },
    {
      id: nanoid(),
      title: 'Section 2',
      content:
        'Your content goes here. Edit styles and content or remove this text From the side bar  / Your content goes here. Edit styles and content or remove this text From the side bar',
    },
  ],
};

export const Component = ({sections, ...props}: AccordionElementProps) => {
  return <Accordion defaultActiveSections={[0, 1]} variant="outlined" sections={sections} {...props} />;
};

export const AccordionElement: BuilderElement<AccordionElementProps> = {
  id: 'AccordionElement',
  nodeId: '',
  icon: 'SquareGantt',
  Component,
  props: defaultProps,
  type: NodeType.ACCORDION,
};
