import createLucideIcon from '../createLucideIcon';

const AlarmClockOff = createLucideIcon('AlarmClockOff', [
  ['path', { d: 'M6.87 6.87a8 8 0 1 0 11.26 11.26', key: '3on8tj' }],
  ['path', { d: 'M19.9 14.25a8 8 0 0 0-9.15-9.15', key: '15ghsc' }],
  ['path', { d: 'm22 6-3-3', key: '1opdir' }],
  ['path', { d: 'M6.26 18.67 4 21', key: 'yzmioq' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  ['path', { d: 'M4 4 2 6', key: '1ycko6' }],
]);

export default AlarmClockOff;
