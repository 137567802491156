import createLucideIcon from '../createLucideIcon';

const StretchVertical = createLucideIcon('StretchVertical', [
  [
    'rect',
    { width: '6', height: '20', x: '4', y: '2', rx: '2', key: '19qu7m' },
  ],
  [
    'rect',
    { width: '6', height: '20', x: '14', y: '2', rx: '2', key: '24v0nk' },
  ],
]);

export default StretchVertical;
