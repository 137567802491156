import createLucideIcon from '../createLucideIcon';

const Biohazard = createLucideIcon('Biohazard', [
  ['circle', { cx: '12', cy: '11.9', r: '2', key: 'e8h31w' }],
  [
    'path',
    { d: 'M6.7 3.4c-.9 2.5 0 5.2 2.2 6.7C6.5 9 3.7 9.6 2 11.6', key: '17bolr' },
  ],
  ['path', { d: 'm8.9 10.1 1.4.8', key: '15ezny' }],
  [
    'path',
    {
      d: 'M17.3 3.4c.9 2.5 0 5.2-2.2 6.7 2.4-1.2 5.2-.6 6.9 1.5',
      key: 'wtwa5u',
    },
  ],
  ['path', { d: 'm15.1 10.1-1.4.8', key: '1r0b28' }],
  [
    'path',
    {
      d: 'M16.7 20.8c-2.6-.4-4.6-2.6-4.7-5.3-.2 2.6-2.1 4.8-4.7 5.2',
      key: 'm7qszh',
    },
  ],
  ['path', { d: 'M12 13.9v1.6', key: 'zfyyim' }],
  ['path', { d: 'M13.5 5.4c-1-.2-2-.2-3 0', key: '1bi9q0' }],
  ['path', { d: 'M17 16.4c.7-.7 1.2-1.6 1.5-2.5', key: '1rhjqw' }],
  ['path', { d: 'M5.5 13.9c.3.9.8 1.8 1.5 2.5', key: '8gsud3' }],
]);

export default Biohazard;
