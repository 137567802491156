import createLucideIcon from '../createLucideIcon';

const PanelRightInactive = createLucideIcon('PanelRightInactive', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M15 14v1', key: 'ilsfch' }],
  ['path', { d: 'M15 19v2', key: '1fst2f' }],
  ['path', { d: 'M15 3v2', key: 'z204g4' }],
  ['path', { d: 'M15 9v1', key: 'z2a8b1' }],
]);

export default PanelRightInactive;
