import createLucideIcon from '../createLucideIcon';

const ToggleLeft = createLucideIcon('ToggleLeft', [
  [
    'rect',
    {
      width: '20',
      height: '12',
      x: '2',
      y: '6',
      rx: '6',
      ry: '6',
      key: 'f2vt7d',
    },
  ],
  ['circle', { cx: '8', cy: '12', r: '2', key: '1nvbw3' }],
]);

export default ToggleLeft;
