import createLucideIcon from '../createLucideIcon';

const Contact2 = createLucideIcon('Contact2', [
  ['path', { d: 'M16 18a4 4 0 0 0-8 0', key: '1lzouq' }],
  ['circle', { cx: '12', cy: '11', r: '3', key: 'itu57m' }],
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '4', rx: '2', key: '1hopcy' },
  ],
  ['line', { x1: '8', x2: '8', y1: '2', y2: '4', key: '1ff9gb' }],
  ['line', { x1: '16', x2: '16', y1: '2', y2: '4', key: '1ufoma' }],
]);

export default Contact2;
