import createLucideIcon from '../createLucideIcon';

const CalendarX2 = createLucideIcon('CalendarX2', [
  [
    'path',
    {
      d: 'M21 13V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8',
      key: '3spt84',
    },
  ],
  ['line', { x1: '16', x2: '16', y1: '2', y2: '6', key: 'm3sa8f' }],
  ['line', { x1: '8', x2: '8', y1: '2', y2: '6', key: '18kwsl' }],
  ['line', { x1: '3', x2: '21', y1: '10', y2: '10', key: 'xt86sb' }],
  ['line', { x1: '17', x2: '22', y1: '17', y2: '22', key: 'xa9o8b' }],
  ['line', { x1: '17', x2: '22', y1: '22', y2: '17', key: '18nitg' }],
]);

export default CalendarX2;
