import createLucideIcon from '../createLucideIcon';

const Orbit = createLucideIcon('Orbit', [
  ['circle', { cx: '12', cy: '12', r: '3', key: '1v7zrd' }],
  ['circle', { cx: '19', cy: '5', r: '2', key: 'mhkx31' }],
  ['circle', { cx: '5', cy: '19', r: '2', key: 'v8kfzx' }],
  ['path', { d: 'M10.4 21.9a10 10 0 0 0 9.941-15.416', key: 'eohfx2' }],
  ['path', { d: 'M13.5 2.1a10 10 0 0 0-9.841 15.416', key: '19pvbm' }],
]);

export default Orbit;
