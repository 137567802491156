import React from 'react';

import {BuilderElement, LocalizedTextType, NodeType} from '../../../types';
import {Button} from '../../Button';
import {ButtonProps} from '../../Button/types';
import {Link} from '../../Link';
import {LocalizedText} from '../../LocalizedText';
import {Typography} from '../../Typography';

export type ButtonElementProps = ButtonProps & {
  value?: LocalizedTextType;
  fontSize?: number;
};

const defaultProps: ButtonElementProps = {
  href: 'https://septenant.medadstg.com/',
  value: 'Click me',
  fontSize: 20,
  color: '$accent',
  alignSelf: 'flex-start',
};

export const Component = ({value, ...props}: ButtonElementProps) => {
  return (
    <Button $sm={{width: '100%'}} {...props}>
      <Link href={props.href} textDecorationLine="none">
        <Typography>
          <LocalizedText value={value} />
        </Typography>
      </Link>
    </Button>
  );
};

export const ButtonElement: BuilderElement<ButtonElementProps> = {
  id: 'ButtonElement',
  nodeId: '',
  icon: 'SquareGantt',
  Component,
  props: defaultProps,
  type: NodeType.BUTTON,
};
