import createLucideIcon from '../createLucideIcon';

const Luggage = createLucideIcon('Luggage', [
  [
    'path',
    {
      d: 'M6 20h0a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h0',
      key: '1h5fkc',
    },
  ],
  ['path', { d: 'M8 18V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v14', key: '1l99gc' }],
  ['path', { d: 'M10 20h4', key: 'ni2waw' }],
  ['circle', { cx: '16', cy: '20', r: '2', key: '1vifvg' }],
  ['circle', { cx: '8', cy: '20', r: '2', key: 'ckkr5m' }],
]);

export default Luggage;
