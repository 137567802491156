import * as normalizeColor from "@react-native/normalize-color";
const norm = normalizeColor.default || normalizeColor,
  normalizeCSSColor = norm;
function rgba(colorInt) {
  const r = Math.round((colorInt & 4278190080) >>> 24),
    g = Math.round((colorInt & 16711680) >>> 16),
    b = Math.round((colorInt & 65280) >>> 8),
    a = ((colorInt & 255) >>> 0) / 255;
  return {
    r,
    g,
    b,
    a
  };
}
var src_default = normalizeCSSColor;
export { src_default as default, normalizeCSSColor, rgba };