import { getElevation } from "./getElevation.mjs";
const elevate = {
    true: (_, extras) => getElevation(extras.props.size, extras)
  },
  bordered = (val, {
    props
  }) => ({
    // TODO size it with size in '...size'
    borderWidth: typeof val == "number" ? val : 1,
    borderColor: "$borderColor",
    ...(props.hoverTheme && {
      hoverStyle: {
        borderColor: "$borderColorHover"
      }
    }),
    ...(props.pressTheme && {
      pressStyle: {
        borderColor: "$borderColorPress"
      }
    }),
    ...(props.focusTheme && {
      focusStyle: {
        borderColor: "$borderColorFocus"
      }
    })
  }),
  padded = {
    true: (_, extras) => {
      const {
        tokens,
        props
      } = extras;
      return {
        padding: tokens.space[props.size] || tokens.space.$true
      };
    }
  },
  radiused = {
    true: (_, extras) => {
      const {
        tokens,
        props
      } = extras;
      return {
        borderRadius: tokens.radius[props.size] || tokens.radius.$true
      };
    }
  },
  circularStyle = {
    borderRadius: 1e5,
    padding: 0
  },
  circular = {
    true: (_, {
      props,
      tokens
    }) => {
      if (!("size" in props)) return circularStyle;
      const size = typeof props.size == "number" ? props.size : tokens.size[props.size];
      return {
        ...circularStyle,
        width: size,
        height: size,
        maxWidth: size,
        maxHeight: size,
        minWidth: size,
        minHeight: size
      };
    }
  },
  hoverTheme = {
    true: {
      hoverStyle: {
        backgroundColor: "$backgroundHover",
        borderColor: "$borderColorHover"
      }
    },
    false: {}
  },
  pressTheme = {
    true: {
      cursor: "pointer",
      pressStyle: {
        backgroundColor: "$backgroundPress",
        borderColor: "$borderColorPress"
      }
    },
    false: {}
  },
  focusTheme = {
    true: {
      focusStyle: {
        backgroundColor: "$backgroundFocus",
        borderColor: "$borderColorFocus"
      }
    },
    false: {}
  };
export { bordered, circular, elevate, focusTheme, hoverTheme, padded, pressTheme, radiused };