import createLucideIcon from '../createLucideIcon';

const Gavel = createLucideIcon('Gavel', [
  [
    'path',
    {
      d: 'm14 13-7.5 7.5c-.83.83-2.17.83-3 0 0 0 0 0 0 0a2.12 2.12 0 0 1 0-3L11 10',
      key: 'c9cbz0',
    },
  ],
  ['path', { d: 'm16 16 6-6', key: 'vzrcl6' }],
  ['path', { d: 'm8 8 6-6', key: '18bi4p' }],
  ['path', { d: 'm9 7 8 8', key: '5jnvq1' }],
  ['path', { d: 'm21 11-8-8', key: 'z4y7zo' }],
]);

export default Gavel;
