import { isWeb } from "@tamagui/core";
import React, { createContext, useContext, useMemo, useRef } from "react";
import { Platform } from "react-native-web";
import { createNativeToast } from "./createNativeToast.mjs";
import { jsx } from "react/jsx-runtime";
const ToastContext = createContext({}),
  ToastCurrentContext = createContext(null),
  useToastController = () => useContext(ToastContext),
  useToastState = () => useContext(ToastCurrentContext),
  useToast = () => ({
    ...useToastController(),
    currentToast: useToastState()
  }),
  ToastImperativeProvider = ({
    children,
    options
  }) => {
    const counterRef = useRef(0),
      [toast, setToast] = React.useState(null),
      [lastNativeToastRef, setLastNativeToastRef] = React.useState(null),
      show = React.useCallback((title, showOptions) => {
        const native = showOptions?.native ?? options.native,
          isWebNative = Array.isArray(native) ? native.includes("web") : native === "web",
          isMobileNative = Array.isArray(native) ? native.includes("mobile") : native === "mobile",
          isAndroidNative = isMobileNative || (Array.isArray(native) ? native.includes("android") : native === "android"),
          isIosNative = isMobileNative || (Array.isArray(native) ? native.includes("ios") : native === "ios"),
          isHandledNatively = native === !0 || isWeb && isWebNative || !isWeb && isMobileNative || Platform.OS === "android" && isAndroidNative || Platform.OS === "ios" && isIosNative;
        if (isHandledNatively) {
          const nativeToastResult = createNativeToast(title, showOptions ?? {});
          typeof nativeToastResult == "object" && nativeToastResult.nativeToastRef && setLastNativeToastRef(nativeToastResult.nativeToastRef);
        }
        return counterRef.current++, setToast({
          ...showOptions?.customData,
          ...showOptions,
          viewportName: showOptions?.viewportName ?? "default",
          title,
          id: counterRef.current.toString(),
          isHandledNatively
        }), !0;
      }, [setToast, options.native]),
      hide = React.useCallback(() => {
        lastNativeToastRef?.close(), setToast(null);
      }, [setToast, lastNativeToastRef]),
      contextValue = useMemo(() => ({
        show,
        hide,
        nativeToast: lastNativeToastRef,
        options
      }), [show, hide, lastNativeToastRef, JSON.stringify(options || null)]);
    return /* @__PURE__ */jsx(ToastContext.Provider, {
      value: contextValue,
      children: /* @__PURE__ */jsx(ToastCurrentContext.Provider, {
        value: toast,
        children
      })
    });
  };
export { ToastImperativeProvider, useToast, useToastController, useToastState };