import createLucideIcon from '../createLucideIcon';

const SearchCode = createLucideIcon('SearchCode', [
  ['path', { d: 'm9 9-2 2 2 2', key: '17gsfh' }],
  ['path', { d: 'm13 13 2-2-2-2', key: '186z8k' }],
  ['circle', { cx: '11', cy: '11', r: '8', key: '4ej97u' }],
  ['path', { d: 'm21 21-4.3-4.3', key: '1qie3q' }],
]);

export default SearchCode;
