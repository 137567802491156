import createLucideIcon from '../createLucideIcon';

const PanelBottomInactive = createLucideIcon('PanelBottomInactive', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M14 15h1', key: '171nev' }],
  ['path', { d: 'M19 15h2', key: '1vnucp' }],
  ['path', { d: 'M3 15h2', key: '8bym0q' }],
  ['path', { d: 'M9 15h1', key: '1tg3ks' }],
]);

export default PanelBottomInactive;
