import { useIsomorphicLayoutEffect } from "@tamagui/constants";
import { useContext, useState } from "react";
import { ThemeManagerIDContext } from "../helpers/ThemeManagerContext.mjs";
import { getThemeManager } from "./useTheme.mjs";
function useThemeName(opts) {
  const manager = getThemeManager(useContext(ThemeManagerIDContext)),
    [name, setName] = useState(manager?.state.name || "");
  return useIsomorphicLayoutEffect(() => {
    if (manager) return setName(manager.state.name), manager.onChangeTheme((next, manager2) => {
      const name2 = opts?.parent && manager2.state.parentName || next;
      name2 && setName(name2);
    });
  }, [manager?.state.name]), name;
}
export { useThemeName };