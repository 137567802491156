import createLucideIcon from '../createLucideIcon';

const Repeat2 = createLucideIcon('Repeat2', [
  ['path', { d: 'm2 9 3-3 3 3', key: '1ltn5i' }],
  ['path', { d: 'M13 18H7a2 2 0 0 1-2-2V6', key: '1r6tfw' }],
  ['path', { d: 'm22 15-3 3-3-3', key: '4rnwn2' }],
  ['path', { d: 'M11 6h6a2 2 0 0 1 2 2v10', key: '2f72bc' }],
]);

export default Repeat2;
