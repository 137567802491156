import createLucideIcon from '../createLucideIcon';

const PlaySquare = createLucideIcon('PlaySquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'm9 8 6 4-6 4Z', key: 'f1r3lt' }],
]);

export default PlaySquare;
