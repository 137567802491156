import createLucideIcon from '../createLucideIcon';

const Router = createLucideIcon('Router', [
  [
    'rect',
    { width: '20', height: '8', x: '2', y: '14', rx: '2', key: 'w68u3i' },
  ],
  ['path', { d: 'M6.01 18H6', key: '19vcac' }],
  ['path', { d: 'M10.01 18H10', key: 'uamcmx' }],
  ['path', { d: 'M15 10v4', key: 'qjz1xs' }],
  ['path', { d: 'M17.84 7.17a4 4 0 0 0-5.66 0', key: '1rif40' }],
  ['path', { d: 'M20.66 4.34a8 8 0 0 0-11.31 0', key: '6a5xfq' }],
]);

export default Router;
