import createLucideIcon from '../createLucideIcon';

const Minimize = createLucideIcon('Minimize', [
  ['path', { d: 'M8 3v3a2 2 0 0 1-2 2H3', key: 'hohbtr' }],
  ['path', { d: 'M21 8h-3a2 2 0 0 1-2-2V3', key: '5jw1f3' }],
  ['path', { d: 'M3 16h3a2 2 0 0 1 2 2v3', key: '198tvr' }],
  ['path', { d: 'M16 21v-3a2 2 0 0 1 2-2h3', key: 'ph8mxp' }],
]);

export default Minimize;
