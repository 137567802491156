import { currentPlatform, isClient, isServer, isWeb, useIsomorphicLayoutEffect } from "@tamagui/constants";
import { stylePropsText, stylePropsTransform, tokenCategories, validPseudoKeys, validStyles } from "@tamagui/helpers";
import { useInsertionEffect } from "react";
import { getConfig } from "../config.mjs";
import { accessibilityDirectMap } from "../constants/accessibilityDirectMap.mjs";
import { webViewFlexCompatStyles } from "../constants/constants.mjs";
import { isDevTools } from "../constants/isDevTools.mjs";
import { getMediaImportanceIfMoreImportant, mediaState as globalMediaState, isMediaKey, mediaKeyMatch, mediaQueryConfig } from "../hooks/useMedia.mjs";
import { createMediaStyle } from "./createMediaStyle.mjs";
import { fixStyles } from "./expandStyles.mjs";
import { getGroupPropParts } from "./getGroupPropParts.mjs";
import { getStyleAtomic, getStylesAtomic, styleToCSS } from "./getStylesAtomic.mjs";
import { insertStyleRules, insertedTransforms, scanAllSheets, shouldInsertStyleRules, updateRules } from "./insertStyleRule.mjs";
import { log } from "./log.mjs";
import { normalizeValueWithProperty, reverseMapClassNameToValue } from "./normalizeValueWithProperty.mjs";
import { getPropMappedFontFamily, propMapper } from "./propMapper.mjs";
import { pseudoDescriptors, pseudoPriorities } from "./pseudoDescriptors.mjs";
import { skipProps } from "./skipProps.mjs";
import { transformsToString } from "./transformsToString.mjs";
const IS_STATIC = process.env.IS_STATIC === "is_static";
let conf;
const PROP_SPLIT = "-";
function isValidStyleKey(key, staticConfig) {
  return (staticConfig.validStyles || (staticConfig.isText || staticConfig.isInput ? stylePropsText : validStyles))[key] || staticConfig.accept?.[key];
}
const getSplitStyles = (props, staticConfig, theme, themeName, componentState, styleProps, parentSplitStyles, context, elementType, debug, skipThemeTokenResolution) => {
  conf = conf || getConfig(), isWeb && styleProps.isAnimated && conf.animations.isReactNative && !styleProps.noNormalize && (styleProps.noNormalize = "values");
  const {
      shorthands
    } = conf,
    {
      isHOC,
      isText,
      isInput,
      variants,
      isReactNative,
      inlineProps,
      inlineWhenUnflattened,
      parentStaticConfig,
      acceptsClassName
    } = staticConfig,
    viewProps = {},
    mediaState = styleProps.mediaState || globalMediaState,
    usedKeys = {},
    shouldDoClasses = acceptsClassName && isWeb && !styleProps.noClassNames,
    rulesToInsert = [],
    classNames = {},
    transforms = {};
  let pseudos = null,
    space = props.space,
    hasMedia = !1,
    dynamicThemeAccess,
    pseudoGroups,
    mediaGroups,
    className = props.className || "",
    mediaStylesSeen = 0;
  const styleState = {
    curProps: {},
    classNames,
    conf,
    props,
    styleProps,
    componentState,
    staticConfig,
    style: null,
    theme,
    usedKeys,
    viewProps,
    context,
    debug,
    skipThemeTokenResolution
  };
  process.env.NODE_ENV === "development" && debug && debug !== "profile" && isClient && (console.groupCollapsed("getSplitStyles (collapsed)"), log({
    props,
    staticConfig,
    shouldDoClasses,
    styleProps,
    componentState,
    styleState,
    theme: {
      ...theme
    }
  }), console.groupEnd());
  for (const keyOg in props) {
    let keyInit = keyOg,
      valInit = props[keyOg];
    if (staticConfig.accept && (staticConfig.accept[keyInit] === "style" || staticConfig.accept[keyInit] === "textStyle") && typeof valInit == "object") {
      const styleObject = getSubStyle(styleState, keyInit, valInit, styleProps.noClassNames);
      viewProps[keyInit] = styleObject;
      continue;
    }
    if (process.env.NODE_ENV === "development" && debug === "verbose" && console.groupEnd(), styleProps.disableExpandShorthands || keyInit in shorthands && (keyInit = shorthands[keyInit]), keyInit === "className" || keyInit in usedKeys || props.asChild && webViewFlexCompatStyles[keyInit] === valInit) continue;
    if (keyInit in skipProps && !styleProps.noSkip && !isHOC) {
      if (keyInit === "group") {
        const identifier = `t_group_${valInit}`,
          containerType = conf.settings.webContainerType || "inline-size",
          containerCSS = {
            identifier,
            property: "container",
            rules: [`.${identifier} { container-name: ${valInit}; container-type: ${containerType}; }`]
          };
        addStyleToInsertRules(rulesToInsert, containerCSS);
      }
      continue;
    }
    const valInitType = typeof valInit,
      isValidStyleKeyInit = isValidStyleKey(keyInit, staticConfig);
    if (isValidStyleKeyInit && valInitType === "string" && valInit[0] === "_") {
      const isValidClassName = (keyInit in validStyles),
        isMediaOrPseudo2 = !isValidClassName &&
        // media are flattened for some reason to color-hover keys,
        // we should probably just leave them in place to avoid extra complexity
        keyInit.includes(PROP_SPLIT) && validStyles[keyInit.split(PROP_SPLIT)[0]];
      if (isValidClassName || isMediaOrPseudo2) {
        shouldDoClasses ? (mergeClassName(transforms, classNames, keyInit, valInit, isMediaOrPseudo2), styleState.style && delete styleState.style[keyInit]) : (styleState.style ||= {}, styleState.style[keyInit] = reverseMapClassNameToValue(keyInit, valInit), delete classNames[keyInit]);
        continue;
      }
    }
    if (valInit !== props[keyInit] && (styleState.curProps[keyInit] = valInit), keyInit === "dataSet") {
      for (const keyInit2 in valInit) viewProps[`data-${hyphenate(keyInit2)}`] = valInit[keyInit2];
      continue;
    }
    if (!styleProps.noExpand) {
      if (keyInit === "disabled" && valInit === !0 && (viewProps["aria-disabled"] = !0, (elementType === "button" || elementType === "form" || elementType === "input" || elementType === "select" || elementType === "textarea") && (viewProps.disabled = !0), !variants?.disabled)) continue;
      if (keyInit === "testID") {
        viewProps[isReactNative ? keyInit : "data-testid"] = valInit;
        continue;
      }
      if (keyInit === "id" || keyInit === "nativeID") {
        viewProps.id = valInit;
        continue;
      }
      let didUseKeyInit = !1;
      if (isReactNative) {
        if (keyInit in accessibilityDirectMap || keyInit.startsWith("accessibility")) {
          viewProps[keyInit] = valInit;
          continue;
        }
      } else {
        if (didUseKeyInit = !0, keyInit in accessibilityDirectMap) {
          viewProps[accessibilityDirectMap[keyInit]] = valInit;
          continue;
        }
        switch (keyInit) {
          case "accessibilityRole":
            {
              valInit === "none" ? viewProps.role = "presentation" : viewProps.role = accessibilityRoleToWebRole[valInit] || valInit;
              continue;
            }
          case "accessibilityLabelledBy":
          case "accessibilityFlowTo":
          case "accessibilityControls":
          case "accessibilityDescribedBy":
            {
              viewProps[`aria-${keyInit.replace("accessibility", "").toLowerCase()}`] = processIDRefList(valInit);
              continue;
            }
          case "accessibilityKeyShortcuts":
            {
              Array.isArray(valInit) && (viewProps["aria-keyshortcuts"] = valInit.join(" "));
              continue;
            }
          case "accessibilityLiveRegion":
            {
              viewProps["aria-live"] = valInit === "none" ? "off" : valInit;
              continue;
            }
          case "accessibilityReadOnly":
            {
              viewProps["aria-readonly"] = valInit, (elementType === "input" || elementType === "select" || elementType === "textarea") && (viewProps.readOnly = !0);
              continue;
            }
          case "accessibilityRequired":
            {
              viewProps["aria-required"] = valInit, (elementType === "input" || elementType === "select" || elementType === "textarea") && (viewProps.required = valInit);
              continue;
            }
          default:
            didUseKeyInit = !1;
        }
      }
      if (didUseKeyInit) continue;
    }
    const isShorthand = (keyInit in shorthands);
    let isVariant = !isValidStyleKeyInit && variants && keyInit in variants;
    const isStyleLikeKey = isShorthand || isValidStyleKeyInit || isVariant;
    let isPseudo = (keyInit in validPseudoKeys),
      isMedia = !isStyleLikeKey && !isPseudo && isMediaKey(keyInit),
      isMediaOrPseudo = !!(isMedia || isPseudo);
    if (isMediaOrPseudo && keyInit.startsWith("$group-")) {
      const name = keyInit.split("-")[1];
      context?.groups.subscribe && !context?.groups.state[name] && (keyInit = keyInit.replace("$group-", "$group-true-"));
    }
    const isStyleProp = isValidStyleKeyInit || isMediaOrPseudo || isVariant && !styleProps.noExpand || isShorthand;
    if (isStyleProp && (props.asChild === "except-style" || props.asChild === "except-style-web")) continue;
    const shouldPassProp = !isStyleProp ||
      // is in parent variants
      isHOC && parentStaticConfig?.variants && keyInit in parentStaticConfig.variants || inlineProps?.has(keyInit),
      parentVariant = parentStaticConfig?.variants?.[keyInit],
      isHOCShouldPassThrough = !!(isHOC && (isShorthand || isValidStyleKeyInit || isMediaOrPseudo || parentVariant || keyInit in skipProps)),
      shouldPassThrough = shouldPassProp || isHOCShouldPassThrough;
    if (process.env.NODE_ENV === "development" && debug === "verbose" && (console.groupEnd(), console.groupEnd(), console.groupCollapsed(`  \u{1F511} ${keyOg}${keyInit !== keyOg ? ` (shorthand for ${keyInit})` : ""} ${shouldPassThrough ? "(pass)" : ""}`), log({
      isVariant,
      valInit,
      shouldPassProp
    }), isClient && log({
      variants,
      variant: variants?.[keyInit],
      isVariant,
      isHOCShouldPassThrough,
      curProps: {
        ...styleState.curProps
      },
      parentStaticConfig
    })), shouldPassThrough && (passDownProp(viewProps, keyInit, valInit, isMediaOrPseudo), !isVariant)) {
      process.env.NODE_ENV === "development" && debug === "verbose" && console.groupEnd();
      continue;
    }
    if (!styleProps.noSkip && keyInit in skipProps) {
      process.env.NODE_ENV === "development" && debug === "verbose" && console.groupEnd();
      continue;
    }
    (isText || isInput) && valInit && (keyInit === "fontFamily" || keyInit === shorthands.fontFamily) && valInit in conf.fontsParsed && (styleState.fontFamily = valInit);
    const avoidPropMap = isMediaOrPseudo || !isVariant && !isValidStyleKeyInit,
      expanded = avoidPropMap ? null : propMapper(keyInit, valInit, styleState);
    if (!avoidPropMap) {
      if (!expanded) continue;
      const next = getPropMappedFontFamily(expanded);
      next && (styleState.fontFamily = next);
    }
    if (process.env.NODE_ENV === "development" && debug === "verbose") {
      console.groupCollapsed("  \u{1F4A0} expanded", keyInit, valInit);
      try {
        !isServer && isDevTools && (log({
          expanded,
          styleProps,
          componentState,
          isVariant,
          variant: variants?.[keyInit],
          shouldPassProp,
          isHOCShouldPassThrough,
          theme,
          usedKeys: {
            ...usedKeys
          },
          curProps: {
            ...styleState.curProps
          }
        }), log("expanded", expanded, `
usedKeys`, {
          ...usedKeys
        }, `
current`, {
          ...styleState.style
        }));
      } catch {}
      console.groupEnd();
    }
    let key = keyInit,
      val = valInit;
    const max = expanded ? expanded.length : 1;
    for (let i = 0; i < max; i++) {
      if (expanded) {
        const [k, v] = expanded[i];
        key = k, val = v;
      }
      if (val == null || key in usedKeys) continue;
      if (isPseudo = key in validPseudoKeys, isMedia = !isPseudo && !isValidStyleKeyInit && isMediaKey(key), isMediaOrPseudo = !!(isMedia || isPseudo), isVariant = variants && key in variants, (inlineProps?.has(key) || IS_STATIC && inlineWhenUnflattened?.has(key)) && (viewProps[key] = props[key] ?? val), styleProps.noExpand && isPseudo || isHOC && (isMediaOrPseudo || parentStaticConfig?.variants?.[keyInit])) {
        passDownProp(viewProps, key, val, isMediaOrPseudo), process.env.NODE_ENV === "development" && debug === "verbose" && (console.groupCollapsed(` - passing down prop ${key}`), log({
          val,
          after: {
            ...viewProps[key]
          }
        }), console.groupEnd());
        continue;
      }
      if (isPseudo) {
        if (!val) continue;
        const pseudoStyleObject = getSubStyle(styleState, key, val, styleProps.noClassNames),
          descriptor = pseudoDescriptors[key],
          isEnter = key === "enterStyle",
          isExit = key === "exitStyle";
        if (!descriptor) continue;
        if ((!shouldDoClasses || IS_STATIC) && (pseudos ||= {}, pseudos[key] ||= {}, IS_STATIC)) {
          Object.assign(pseudos[key], pseudoStyleObject);
          continue;
        }
        if (shouldDoClasses && !isExit) {
          const pseudoStyles = getStyleAtomic(pseudoStyleObject, descriptor);
          process.env.NODE_ENV === "development" && debug === "verbose" && (console.groupCollapsed("pseudo (classes)", key), log({
            pseudoStyleObject,
            pseudoStyles
          }), console.groupEnd());
          for (const psuedoStyle of pseudoStyles) {
            const fullKey = `${psuedoStyle.property}${PROP_SPLIT}${descriptor.name}`;
            fullKey in usedKeys || (addStyleToInsertRules(rulesToInsert, psuedoStyle), mergeClassName(transforms, classNames, fullKey, psuedoStyle.identifier, isMediaOrPseudo, !0));
          }
        }
        if (!shouldDoClasses || isExit || isEnter) {
          const descriptorKey = descriptor.stateKey || descriptor.name;
          let isDisabled = componentState[descriptorKey] === !1;
          isExit && (isDisabled = !styleProps.isExiting), isEnter && (isDisabled = componentState.unmounted === "should-enter" ? !0 : !componentState.unmounted), process.env.NODE_ENV === "development" && debug === "verbose" && (console.groupCollapsed("pseudo", key, {
            isDisabled
          }), log({
            pseudoStyleObject,
            isDisabled,
            descriptor,
            componentState
          }), console.groupEnd());
          const importance = descriptor.priority;
          for (const pkey in pseudoStyleObject) {
            const val2 = pseudoStyleObject[pkey];
            if (isDisabled) {
              const defaultValues = animatableDefaults[pkey];
              defaultValues != null && !(pkey in usedKeys) && (!styleState.style || !(pkey in styleState.style)) && mergeStyle(styleState, pkey, defaultValues);
            } else {
              const curImportance = usedKeys[pkey] || 0,
                shouldMerge = importance >= curImportance;
              shouldMerge && (pseudos ||= {}, pseudos[key] ||= {}, pseudos[key][pkey] = val2, mergeStyle(styleState, pkey, val2)), process.env.NODE_ENV === "development" && debug === "verbose" && log("    subKey", pkey, shouldMerge, {
                importance,
                curImportance,
                pkey,
                val: val2
              });
            }
          }
          if (!isDisabled) for (const key2 in val) {
            const k = shorthands[key2] || key2;
            usedKeys[k] = Math.max(importance, usedKeys[k] || 0);
          }
        }
        continue;
      }
      if (isMedia) {
        if (!val) continue;
        if (isMedia === "platform") {
          const platform = key.slice(10);
          if (
          // supports web, ios, android
          platform !== currentPlatform &&
          // supports web, native
          platform !== "web") continue;
        }
        hasMedia ||= !0;
        const mediaKeyShort = key.slice(1);
        process.env.NODE_ENV === "development" && debug === "verbose" && log(`  \u{1F4FA} ${key}`, {
          key,
          val,
          props,
          shouldDoClasses,
          acceptsClassName,
          componentState
        });
        const hasSpace = val.space;
        if ((hasSpace || !shouldDoClasses) && (Array.isArray(hasMedia) || (hasMedia = []), hasMedia.push(mediaKeyShort)), shouldDoClasses) {
          const mediaStyle = getSubStyle(styleState, key, val, !1);
          if (hasSpace && (delete mediaStyle.space, mediaState[mediaKeyShort])) {
            const importance = getMediaImportanceIfMoreImportant(mediaKeyShort, "space", usedKeys, !0);
            importance && (space = val.space, usedKeys.space = importance, process.env.NODE_ENV === "development" && debug === "verbose" && log(`Found more important space for current media ${mediaKeyShort}: ${val} (importance: ${importance})`));
          }
          const mediaStyles = getStylesAtomic(mediaStyle),
            priority = mediaStylesSeen;
          mediaStylesSeen += 1;
          for (const style of mediaStyles) {
            const out = createMediaStyle(style, mediaKeyShort, mediaQueryConfig, isMedia, !1, priority);
            process.env.NODE_ENV === "development" && debug === "verbose" && log("\u{1F4FA} media style:", out);
            const fullKey = `${style.property}${PROP_SPLIT}${mediaKeyShort}${style.pseudo || ""}`;
            fullKey in usedKeys || (addStyleToInsertRules(rulesToInsert, out), mergeClassName(transforms, classNames, fullKey, out.identifier, !0, !0));
          }
        } else {
          const mediaStyle = getSubStyle(styleState, key, val, !0),
            isThemeMedia = isMedia === "theme",
            isGroupMedia = isMedia === "group";
          if (!isThemeMedia && !(isMedia === "platform") && !isGroupMedia && !mediaState[mediaKeyShort]) continue;
          let importanceBump = 0;
          if (isThemeMedia) {
            dynamicThemeAccess = !0;
            const mediaThemeName = mediaKeyShort.slice(6);
            if (!(themeName === mediaThemeName || themeName.startsWith(mediaThemeName))) continue;
          } else if (isGroupMedia) {
            const groupInfo = getGroupPropParts(mediaKeyShort),
              groupName = groupInfo.name,
              groupContext = context?.groups.state[groupName];
            if (!groupContext) {
              process.env.NODE_ENV === "development" && debug && console.warn(`No parent with group prop, skipping styles: ${groupName}`);
              continue;
            }
            const groupPseudoKey = groupInfo.pseudo,
              groupMediaKey = groupInfo.media,
              componentGroupState = componentState.group?.[groupName];
            if (groupMediaKey) {
              mediaGroups ||= /* @__PURE__ */new Set(), mediaGroups.add(groupMediaKey);
              const mediaState2 = componentGroupState?.media;
              let isActive = mediaState2?.[groupMediaKey];
              if (!mediaState2 && groupContext.layout && (isActive = mediaKeyMatch(groupMediaKey, groupContext.layout)), !isActive) continue;
              importanceBump = 2;
            }
            if (groupPseudoKey) {
              if (pseudoGroups ||= /* @__PURE__ */new Set(), pseudoGroups.add(groupName), !(componentGroupState ||
              // fallback to context initially
              context.groups.state[groupName]).pseudo?.[groupPseudoKey]) continue;
              importanceBump = pseudoPriorities[groupPseudoKey];
            }
          }
          for (const subKey in mediaStyle) {
            if (subKey === "space") {
              space = valInit.space;
              continue;
            }
            styleState.style ||= {}, mergeMediaByImportance(styleState, mediaKeyShort, subKey, mediaStyle[subKey], usedKeys, mediaState[mediaKeyShort], importanceBump), key === "fontFamily" && (styleState.fontFamily = mediaStyle.fontFamily);
          }
        }
        continue;
      }
      if (
      // is HOC we can just pass through the styles as props
      // this fixes issues where style prop got merged with wrong priority
      !isHOC && isValidStyleKey(key, staticConfig)) {
        mergeStyle(styleState, key, val);
        continue;
      }
      isVariant || (viewProps[key] = val);
    }
    if (process.env.NODE_ENV === "development" && debug === "verbose") {
      try {
        log(" \u2714\uFE0F expand complete", keyInit), log("style", {
          ...styleState.style
        }), log("transforms", {
          ...transforms
        }), log("viewProps", {
          ...viewProps
        });
      } catch {}
      console.groupEnd();
    }
  }
  if (!(styleProps.noNormalize === !1) && (styleState.style && (fixStyles(styleState.style), isWeb && !staticConfig.isReactNative && styleToCSS(styleState.style)), styleState.flatTransforms && (styleState.style ||= {}, Object.entries(styleState.flatTransforms).sort(([a], [b]) => a.localeCompare(b)).forEach(([key, val]) => {
    mergeTransform(styleState.style, key, val, !0);
  })), parentSplitStyles)) {
    if (shouldDoClasses) for (const key in parentSplitStyles.classNames) {
      const val = parentSplitStyles.classNames[key];
      styleState.style && key in styleState.style || key in classNames || (classNames[key] = val);
    }
    if (!shouldDoClasses) for (const key in parentSplitStyles.style) key in classNames || styleState.style && key in styleState.style || (styleState.style ||= {}, styleState.style[key] = parentSplitStyles.style[key]);
  }
  if (!styleProps.noNormalize && !staticConfig.isReactNative && !staticConfig.isHOC && (!styleProps.isAnimated || conf.animations.supportsCSSVars) && Array.isArray(styleState.style?.transform) && (styleState.style.transform = transformsToString(styleState.style.transform)), styleState.style && shouldDoClasses) {
    let retainedStyles,
      shouldRetain = !1;
    if (!styleState.style.$$css) {
      const atomic = getStylesAtomic(styleState.style);
      for (const atomicStyle of atomic) {
        const key = atomicStyle.property,
          isAnimatedAndAnimateOnly = styleProps.isAnimated && styleProps.noClassNames && (!props.animateOnly || props.animateOnly.includes(key)),
          nonAnimatedAnimateOnly = !isAnimatedAndAnimateOnly && !styleProps.isAnimated && props.animateOnly?.includes(key);
        isAnimatedAndAnimateOnly ? (retainedStyles ||= {}, retainedStyles[key] = styleState.style[key]) : nonAnimatedAnimateOnly ? (retainedStyles ||= {}, retainedStyles[key] = atomicStyle.value, shouldRetain = !0) : (addStyleToInsertRules(rulesToInsert, atomicStyle), mergeClassName(transforms, classNames, key, atomicStyle.identifier, !1, !0));
      }
      process.env.NODE_ENV === "development" && props.debug === "verbose" && (console.groupEnd(), console.groupCollapsed("\u{1F539} getSplitStyles final style object"), console.info(styleState.style), console.groupEnd()), (shouldRetain || !IS_STATIC) && (styleState.style = retainedStyles || {});
    }
    if (transforms) for (const namespace in transforms) {
      if (!transforms[namespace]) {
        process.env.NODE_ENV === "development" && console.warn("Error no transform", transforms, namespace);
        continue;
      }
      const [hash, val] = transforms[namespace],
        identifier = `_transform${hash}`;
      if (isClient && !insertedTransforms[identifier]) {
        const rule = `.${identifier} { transform: ${val}; }`;
        addStyleToInsertRules(rulesToInsert, {
          identifier,
          rules: [rule],
          property: namespace
        });
      }
      classNames[namespace] = identifier;
    }
  }
  if (!isReactNative && viewProps.tabIndex == null) {
    const isFocusable = viewProps.focusable ?? viewProps.accessible;
    viewProps.focusable && delete viewProps.focusable;
    const role = viewProps.role;
    isFocusable === !1 && (viewProps.tabIndex = "-1"),
    // These native elements are focusable by default
    elementType === "a" || elementType === "button" || elementType === "input" || elementType === "select" || elementType === "textarea" ? (isFocusable === !1 || props.accessibilityDisabled === !0) && (viewProps.tabIndex = "-1") :
    // These roles are made focusable by default
    (role === "button" || role === "checkbox" || role === "link" || role === "radio" ||
    // @ts-expect-error (consistent with RNW)
    role === "textbox" || role === "switch") && isFocusable !== !1 && (viewProps.tabIndex = "0"), isFocusable && (viewProps.tabIndex = "0", delete viewProps.focusable);
  }
  const styleProp = props.style;
  if (styleProp) if (isHOC) viewProps.style = normalizeStyle(styleProp);else {
    const isArray = Array.isArray(styleProp),
      len = isArray ? styleProp.length : 1;
    for (let i = 0; i < len; i++) {
      const style = isArray ? styleProp[i] : styleProp;
      style && (style.$$css ? Object.assign(styleState.classNames, style) : (styleState.style ||= {}, Object.assign(styleState.style, normalizeStyle(style))));
    }
  }
  const result = {
      space,
      hasMedia,
      fontFamily: styleState.fontFamily,
      viewProps,
      style: styleState.style,
      pseudos,
      classNames,
      rulesToInsert,
      dynamicThemeAccess,
      pseudoGroups,
      mediaGroups
    },
    asChild = props.asChild;
  if (!(asChild === "except-style" || asChild === "except-style-web")) {
    const style = styleState.style;
    {
      let fontFamily = isText || isInput ? styleState.fontFamily || staticConfig.defaultProps?.fontFamily : null;
      fontFamily && fontFamily[0] === "$" && (fontFamily = fontFamily.slice(1));
      const fontFamilyClassName = fontFamily ? `font_${fontFamily}` : "",
        groupClassName = props.group ? `t_group_${props.group}` : "",
        componentNameFinal = props.componentName || staticConfig.componentName,
        componentClassName = props.asChild || !componentNameFinal ? "" : `is_${componentNameFinal}`;
      let classList = [];
      componentClassName && classList.push(componentClassName), fontFamilyClassName && classList.push(fontFamilyClassName), classNames && classList.push(Object.values(classNames).join(" ")), groupClassName && classList.push(groupClassName), props.className && classList.push(props.className);
      const finalClassName = classList.join(" ");
      if (styleProps.noMergeStyle) finalClassName && (viewProps.className = finalClassName);else if (styleProps.isAnimated && !conf.animations.supportsCSSVars && isReactNative) style && (viewProps.style = style);else if (isReactNative) {
        const cnStyles = {
          $$css: !0
        };
        for (const name of finalClassName.split(" ")) cnStyles[name] = name;
        viewProps.style = [...(Array.isArray(style) ? style : [style]), cnStyles];
      } else finalClassName && (viewProps.className = finalClassName), style && (viewProps.style = style);
    }
  }
  if (process.env.NODE_ENV === "development" && debug === "verbose" && isDevTools) {
    console.groupCollapsed("\u{1F539} getSplitStyles ===>");
    try {
      const logs = {
        ...result,
        className,
        componentState,
        transforms,
        viewProps,
        rulesToInsert,
        parentSplitStyles
      };
      for (const key in logs) log(key, logs[key]);
    } catch {}
    console.groupEnd();
  }
  return result;
};
function mergeClassName(transforms, classNames, key, val, isMediaOrPseudo = !1, isInsertingNow = !1) {
  if (val) if (!isInsertingNow && val[0] === "_" && val.startsWith("_transform-")) {
    const ns = isMediaOrPseudo ? key : "transform";
    let transform = insertedTransforms[val];
    isClient && !transform && (scanAllSheets(), transform = insertedTransforms[val], !transform && isWeb && val[0] !== "_" && (transform = val)), transforms[ns] ||= ["", ""], transforms[ns][0] += val.replace("_transform", ""), transform && (transforms[ns][1] += transform);
  } else classNames[key] = val;
}
function mergeStyle(styleState, key, val, disableNormalize = !1) {
  const {
    classNames,
    viewProps,
    usedKeys,
    styleProps,
    staticConfig
  } = styleState;
  if (isWeb && val?.[0] === "_") classNames[key] = val, usedKeys[key] ||= 1;else if (key in stylePropsTransform) styleState.flatTransforms ||= {}, styleState.flatTransforms[key] = val;else {
    const out = isWeb && !disableNormalize && !styleProps.noNormalize ? normalizeValueWithProperty(val, key) : val;
    // accept is for props not styles
    staticConfig.accept && key in staticConfig.accept ? viewProps[key] = out : (styleState.style ||= {}, styleState.style[key] = out);
  }
}
const getSubStyle = (styleState, subKey, styleIn, avoidMergeTransform) => {
    const {
        staticConfig,
        props,
        conf: conf2,
        styleProps
      } = styleState,
      styleOut = {};
    for (let key in styleIn) {
      const val = styleIn[key];
      key = conf2.shorthands[key] || key;
      const expanded = propMapper(key, val, styleState, {
        ...props,
        ...props[subKey]
      });
      if (!(!expanded || !staticConfig.isHOC && key in skipProps && !styleProps.noSkip)) for (const [skey, sval] of expanded) !avoidMergeTransform && skey in stylePropsTransform ? mergeTransform(styleOut, skey, sval) : styleOut[skey] = styleProps.noNormalize ? sval : normalizeValueWithProperty(sval, key);
    }
    return styleProps.noNormalize || fixStyles(styleOut), styleOut;
  },
  useInsertEffectCompat = isWeb ? useInsertionEffect || useIsomorphicLayoutEffect : () => {},
  useSplitStyles = (a, b, c, d, e, f, g, h, i, j) => {
    const res = getSplitStyles(a, b, c, d, e, f, g, h, i, j);
    return useInsertEffectCompat(() => {
      insertStyleRules(res.rulesToInsert);
    }, [res.rulesToInsert]), res;
  };
function addStyleToInsertRules(rulesToInsert, styleObject) {
  shouldInsertStyleRules(styleObject.identifier) && (updateRules(styleObject.identifier, styleObject.rules), rulesToInsert.push(styleObject));
}
function processIDRefList(idRefList) {
  return Array.isArray(idRefList) ? idRefList.join(" ") : idRefList;
}
const defaultColor = process.env.TAMAGUI_DEFAULT_COLOR || "rgba(0,0,0,0)",
  animatableDefaults = {
    ...Object.fromEntries(Object.entries(tokenCategories.color).map(([k, v]) => [k, defaultColor])),
    opacity: 1,
    scale: 1,
    rotate: "0deg",
    rotateY: "0deg",
    rotateX: "0deg",
    x: 0,
    y: 0,
    borderRadius: 0
  },
  lowercaseHyphenate = match => `-${match.toLowerCase()}`,
  hyphenate = str => str.replace(/[A-Z]/g, lowercaseHyphenate),
  mergeTransform = (obj, key, val, backwards = !1) => {
    typeof obj.transform != "string" && (obj.transform ||= [], obj.transform[backwards ? "unshift" : "push"]({
      [mapTransformKeys[key] || key]: val
    }));
  },
  mapTransformKeys = {
    x: "translateX",
    y: "translateY"
  },
  accessibilityRoleToWebRole = {
    adjustable: "slider",
    header: "heading",
    image: "img",
    link: "link",
    none: "presentation",
    summary: "region"
  };
function passDownProp(viewProps, key, val, shouldMergeObject = !1) {
  if (shouldMergeObject) {
    const next = {
      ...viewProps[key],
      ...val
    };
    delete viewProps[key], viewProps[key] = next;
  } else viewProps[key] = val;
}
function mergeMediaByImportance(styleState, mediaKey, key, value, importancesUsed, isSizeMedia, importanceBump) {
  let importance = getMediaImportanceIfMoreImportant(mediaKey, key, importancesUsed, isSizeMedia);
  return importanceBump && (importance = (importance || 0) + importanceBump), importance === null ? !1 : (importancesUsed[key] = importance, mergeStyle(styleState, key, value), !0);
}
function normalizeStyle(style) {
  const out = {};
  for (const key in style) {
    const val = style[key];
    key in stylePropsTransform ? mergeTransform(out, key, val) : out[key] = normalizeValueWithProperty(val, key);
  }
  return isWeb && Array.isArray(out.transform) && (out.transform = transformsToString(out.transform)), fixStyles(out), out;
}
export { PROP_SPLIT, getSplitStyles, getSubStyle, useSplitStyles };