import createLucideIcon from '../createLucideIcon';

const CloudDrizzle = createLucideIcon('CloudDrizzle', [
  [
    'path',
    {
      d: 'M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242',
      key: '1pljnt',
    },
  ],
  ['path', { d: 'M8 19v1', key: '1dk2by' }],
  ['path', { d: 'M8 14v1', key: '84yxot' }],
  ['path', { d: 'M16 19v1', key: 'v220m7' }],
  ['path', { d: 'M16 14v1', key: 'g12gj6' }],
  ['path', { d: 'M12 21v1', key: 'q8vafk' }],
  ['path', { d: 'M12 16v1', key: '1mx6rx' }],
]);

export default CloudDrizzle;
