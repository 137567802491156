import createLucideIcon from '../createLucideIcon';

const BoxSelect = createLucideIcon('BoxSelect', [
  ['path', { d: 'M5 3a2 2 0 0 0-2 2', key: 'y57alp' }],
  ['path', { d: 'M19 3a2 2 0 0 1 2 2', key: '18rm91' }],
  ['path', { d: 'M21 19a2 2 0 0 1-2 2', key: '1j7049' }],
  ['path', { d: 'M5 21a2 2 0 0 1-2-2', key: 'sbafld' }],
  ['path', { d: 'M9 3h1', key: '1yesri' }],
  ['path', { d: 'M9 21h1', key: '15o7lz' }],
  ['path', { d: 'M14 3h1', key: '1ec4yj' }],
  ['path', { d: 'M14 21h1', key: 'v9vybs' }],
  ['path', { d: 'M3 9v1', key: '1r0deq' }],
  ['path', { d: 'M21 9v1', key: 'mxsmne' }],
  ['path', { d: 'M3 14v1', key: 'vnatye' }],
  ['path', { d: 'M21 14v1', key: '169vum' }],
]);

export default BoxSelect;
