import createLucideIcon from '../createLucideIcon';

const CloudSunRain = createLucideIcon('CloudSunRain', [
  ['path', { d: 'M12 2v2', key: 'tus03m' }],
  ['path', { d: 'm4.93 4.93 1.41 1.41', key: '149t6j' }],
  ['path', { d: 'M20 12h2', key: '1q8mjw' }],
  ['path', { d: 'm19.07 4.93-1.41 1.41', key: '1shlcs' }],
  ['path', { d: 'M15.947 12.65a4 4 0 0 0-5.925-4.128', key: 'dpwdj0' }],
  ['path', { d: 'M3 20a5 5 0 1 1 8.9-4H13a3 3 0 0 1 2 5.24', key: '1qmrp3' }],
  ['path', { d: 'M11 20v2', key: '174qtz' }],
  ['path', { d: 'M7 19v2', key: '12npes' }],
]);

export default CloudSunRain;
