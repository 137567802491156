import createLucideIcon from '../createLucideIcon';

const School2 = createLucideIcon('School2', [
  ['circle', { cx: '12', cy: '10', r: '1', key: '1gnqs8' }],
  [
    'path',
    {
      d: 'M22 20V8h-4l-6-4-6 4H2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2Z',
      key: '8z0lq4',
    },
  ],
  ['path', { d: 'M6 17v.01', key: 'roodi6' }],
  ['path', { d: 'M6 13v.01', key: '67c122' }],
  ['path', { d: 'M18 17v.01', key: '12ktxm' }],
  ['path', { d: 'M18 13v.01', key: 'tn1rt1' }],
  ['path', { d: 'M14 22v-5a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v5', key: 'jfgdp0' }],
]);

export default School2;
