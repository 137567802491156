import React, {ReactNode} from 'react';

import {ViewProps} from '../../View';
import {XStack} from '../../XStack';

type Props = ViewProps & {
  children: ReactNode;
  id: string;
  preview?: boolean;
};

export const RowWrapper = ({id, children, preview = false, ...props}: Props): JSX.Element | null => {
  return (
    <XStack
      id={id}
      position="relative"
      borderRadius="$true"
      paddingHorizontal="$3"
      paddingBottom="$3"
      gap="$6"
      flexWrap="nowrap"
      borderColor="$green-400"
      padding="$8"
      borderWidth={preview ? '$0' : '$1'}
      flex={1}
      {...props}
      flexDirection="row">
      {children}
    </XStack>
  );
};
