import createLucideIcon from '../createLucideIcon';

const ArrowDownNarrowWide = createLucideIcon('ArrowDownNarrowWide', [
  ['path', { d: 'm3 16 4 4 4-4', key: '1co6wj' }],
  ['path', { d: 'M7 20V4', key: '1yoxec' }],
  ['path', { d: 'M11 4h4', key: '6d7r33' }],
  ['path', { d: 'M11 8h7', key: 'djye34' }],
  ['path', { d: 'M11 12h10', key: '1438ji' }],
]);

export default ArrowDownNarrowWide;
