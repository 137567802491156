import createLucideIcon from '../createLucideIcon';

const MonitorDown = createLucideIcon('MonitorDown', [
  ['path', { d: 'M12 13V7', key: 'h0r20n' }],
  ['path', { d: 'm15 10-3 3-3-3', key: 'lzhmyn' }],
  [
    'rect',
    { width: '20', height: '14', x: '2', y: '3', rx: '2', key: '48i651' },
  ],
  ['path', { d: 'M12 17v4', key: '1riwvh' }],
  ['path', { d: 'M8 21h8', key: '1ev6f3' }],
]);

export default MonitorDown;
