import createLucideIcon from '../createLucideIcon';

const Construction = createLucideIcon('Construction', [
  [
    'rect',
    { x: '2', y: '6', width: '20', height: '8', rx: '1', key: '1estib' },
  ],
  ['path', { d: 'M17 14v7', key: '7m2elx' }],
  ['path', { d: 'M7 14v7', key: '1cm7wv' }],
  ['path', { d: 'M17 3v3', key: '1v4jwn' }],
  ['path', { d: 'M7 3v3', key: '7o6guu' }],
  ['path', { d: 'M10 14 2.3 6.3', key: '1023jk' }],
  ['path', { d: 'm14 6 7.7 7.7', key: '1s8pl2' }],
  ['path', { d: 'm8 6 8 8', key: 'hl96qh' }],
]);

export default Construction;
