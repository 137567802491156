import createLucideIcon from '../createLucideIcon';

const Disc3 = createLucideIcon('Disc3', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['path', { d: 'M6 12c0-1.7.7-3.2 1.8-4.2', key: 'oqkarx' }],
  ['circle', { cx: '12', cy: '12', r: '2', key: '1c9p78' }],
  ['path', { d: 'M18 12c0 1.7-.7 3.2-1.8 4.2', key: '1eah9h' }],
]);

export default Disc3;
