import React from 'react';

import {StackProps} from 'tamagui';

import {View} from '../../View';

export const TabsRovingIndicator = ({active, ...props}: {active?: boolean} & StackProps) => {
  return (
    <View
      position="absolute"
      backgroundColor="$accentLight"
      opacity={0.7}
      animation="100ms"
      enterStyle={{
        opacity: 0,
      }}
      exitStyle={{
        opacity: 0,
      }}
      {...(active && {
        backgroundColor: '$accent',
        borderRadius: '$2',
      })}
      {...props}
    />
  );
};
