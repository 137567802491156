import createLucideIcon from '../createLucideIcon';

const Plug = createLucideIcon('Plug', [
  ['path', { d: 'M12 22v-5', key: '1ega77' }],
  ['path', { d: 'M9 8V2', key: '14iosj' }],
  ['path', { d: 'M15 8V2', key: '18g5xt' }],
  ['path', { d: 'M18 8v5a4 4 0 0 1-4 4h-4a4 4 0 0 1-4-4V8Z', key: 'osxo6l' }],
]);

export default Plug;
