import createLucideIcon from '../createLucideIcon';

const SlidersHorizontal = createLucideIcon('SlidersHorizontal', [
  ['line', { x1: '21', x2: '14', y1: '4', y2: '4', key: 'obuewd' }],
  ['line', { x1: '10', x2: '3', y1: '4', y2: '4', key: '1q6298' }],
  ['line', { x1: '21', x2: '12', y1: '12', y2: '12', key: '1iu8h1' }],
  ['line', { x1: '8', x2: '3', y1: '12', y2: '12', key: 'ntss68' }],
  ['line', { x1: '21', x2: '16', y1: '20', y2: '20', key: '14d8ph' }],
  ['line', { x1: '12', x2: '3', y1: '20', y2: '20', key: 'm0wm8r' }],
  ['line', { x1: '14', x2: '14', y1: '2', y2: '6', key: '14e1ph' }],
  ['line', { x1: '8', x2: '8', y1: '10', y2: '14', key: '1i6ji0' }],
  ['line', { x1: '16', x2: '16', y1: '18', y2: '22', key: '1lctlv' }],
]);

export default SlidersHorizontal;
