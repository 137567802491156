import createLucideIcon from '../createLucideIcon';

const AlarmClock = createLucideIcon('AlarmClock', [
  ['circle', { cx: '12', cy: '13', r: '8', key: '3y4lt7' }],
  ['path', { d: 'M12 9v4l2 2', key: '1c63tq' }],
  ['path', { d: 'M5 3 2 6', key: '18tl5t' }],
  ['path', { d: 'm22 6-3-3', key: '1opdir' }],
  ['path', { d: 'M6.38 18.7 4 21', key: '17xu3x' }],
  ['path', { d: 'M17.64 18.67 20 21', key: 'kv2oe2' }],
]);

export default AlarmClock;
