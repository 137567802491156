import React, {forwardRef, useMemo} from 'react';

import {Button as BaseButton} from 'tamagui';

import {useAppTheme} from '../../context';
import {Spinner} from '../Spinner';
import {Typography} from '../Typography';

import {ButtonTypeColors} from './config';
import {ButtonColorTypes, ButtonProps} from './types';

export * from './types';

const _Button = (
  {
    loading,
    disabled,
    prefix,
    suffix,
    children,
    fontWeight = '500',
    fontSize = '$14',
    type = 'primary',
    variant = 'filled',
    ...rest
  }: ButtonProps,
  ref,
) => {
  const {theme} = useAppTheme();
  const suffixItem = loading ? (iconProps: any) => <Spinner color="$white" {...iconProps} /> : suffix;
  const variantsStyles = useMemo(() => buttonStyles(type, loading), [loading, type, theme]);

  return (
    <BaseButton
      ref={ref}
      disabled={disabled}
      iconAfter={suffixItem as any}
      icon={prefix as any}
      paddingHorizontal="$16"
      paddingVertical="$10"
      fontSize={fontSize}
      {...variantsStyles[variant]}
      {...(disabled && {
        backgroundColor: variant === 'empty' ? '$transparent' : '$neutral-100',
        color: '$neutral-400',
        borderColor: '$neutral-100',
      })}
      pressStyle={{
        backgroundColor: variantsStyles[variant].backgroundColor,
        borderColor: variantsStyles[variant].borderColor,
        opacity: 0.8,
      }}
      focusStyle={{outlineColor: 'transparent'}}
      borderRadius={6}
      {...rest}>
      {!!children && (
        <Typography
          fontWeight={fontWeight}
          fontSize={fontSize}
          textTransform="capitalize"
          color={variantsStyles[variant].color}
          {...(disabled && {color: '$neutral-400'})}>
          {children}
        </Typography>
      )}
    </BaseButton>
  );
};

const buttonStyles = (type: ButtonColorTypes, loading?: boolean) => ({
  filled: {
    backgroundColor: loading ? ButtonTypeColors[type].loading : ButtonTypeColors[type].default,
    borderColor: loading ? ButtonTypeColors[type].loading : ButtonTypeColors[type].default,
    color: '$white',
    hoverStyle: {
      backgroundColor: ButtonTypeColors[type].hover,
    },
  },

  outlined: {
    backgroundColor: loading ? ButtonTypeColors[type].outlineLoading : 'transparent',
    color: ButtonTypeColors[type].default,
    borderColor: ButtonTypeColors[type].default,
    borderWidth: 1,
    hoverStyle: {
      color: ButtonTypeColors[type].hover,
      borderColor: ButtonTypeColors[type].hover,
      backgroundColor: 'transparent',
    },
    PressStyle: {
      backgroundColor: ButtonTypeColors[type].default,
    },
  },
  empty: {
    backgroundColor: 'transparent',
    color: ButtonTypeColors[type].default,
    borderColor: 'transparent',
    padding: 0,
    borderWidth: 0,
    hoverStyle: {
      color: ButtonTypeColors[type].hover,
      backgroundColor: 'transparent',
    },
    PressStyle: {
      backgroundColor: 'transparent',
      opacity: 0.5,
    },
  },
});

export const Button = forwardRef(_Button);
