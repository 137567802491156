import createLucideIcon from '../createLucideIcon';

const Scroll = createLucideIcon('Scroll', [
  [
    'path',
    {
      d: 'M8 21h12a2 2 0 0 0 2-2v-2H10v2a2 2 0 1 1-4 0V5a2 2 0 1 0-4 0v3h4',
      key: '13a6an',
    },
  ],
  ['path', { d: 'M19 17V5a2 2 0 0 0-2-2H4', key: 'zz82l3' }],
]);

export default Scroll;
