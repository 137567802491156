import createLucideIcon from '../createLucideIcon';

const Pin = createLucideIcon('Pin', [
  ['line', { x1: '12', x2: '12', y1: '17', y2: '22', key: '1jrz49' }],
  [
    'path',
    {
      d: 'M5 17h14v-1.76a2 2 0 0 0-1.11-1.79l-1.78-.9A2 2 0 0 1 15 10.76V6h1a2 2 0 0 0 0-4H8a2 2 0 0 0 0 4h1v4.76a2 2 0 0 1-1.11 1.79l-1.78.9A2 2 0 0 0 5 15.24Z',
      key: '13yl11',
    },
  ],
]);

export default Pin;
