import createLucideIcon from '../createLucideIcon';

const Unlink = createLucideIcon('Unlink', [
  [
    'path',
    {
      d: 'm18.84 12.25 1.72-1.71h-.02a5.004 5.004 0 0 0-.12-7.07 5.006 5.006 0 0 0-6.95 0l-1.72 1.71',
      key: 'yqzxt4',
    },
  ],
  [
    'path',
    {
      d: 'm5.17 11.75-1.71 1.71a5.004 5.004 0 0 0 .12 7.07 5.006 5.006 0 0 0 6.95 0l1.71-1.71',
      key: '4qinb0',
    },
  ],
  ['line', { x1: '8', x2: '8', y1: '2', y2: '5', key: '1041cp' }],
  ['line', { x1: '2', x2: '5', y1: '8', y2: '8', key: '14m1p5' }],
  ['line', { x1: '16', x2: '16', y1: '19', y2: '22', key: 'rzdirn' }],
  ['line', { x1: '19', x2: '22', y1: '16', y2: '16', key: 'ox905f' }],
]);

export default Unlink;
