import createLucideIcon from '../createLucideIcon';

const TestTubes = createLucideIcon('TestTubes', [
  [
    'path',
    {
      d: 'M9 2v17.5A2.5 2.5 0 0 1 6.5 22v0A2.5 2.5 0 0 1 4 19.5V2',
      key: '12z67u',
    },
  ],
  [
    'path',
    {
      d: 'M20 2v17.5a2.5 2.5 0 0 1-2.5 2.5v0a2.5 2.5 0 0 1-2.5-2.5V2',
      key: '1q2nfy',
    },
  ],
  ['path', { d: 'M3 2h7', key: '7s29d5' }],
  ['path', { d: 'M14 2h7', key: '7sicin' }],
  ['path', { d: 'M9 16H4', key: '1bfye3' }],
  ['path', { d: 'M20 16h-5', key: 'ddnjpe' }],
]);

export default TestTubes;
