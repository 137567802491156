import createLucideIcon from '../createLucideIcon';

const MonitorCheck = createLucideIcon('MonitorCheck', [
  ['path', { d: 'm9 10 2 2 4-4', key: '1gnqz4' }],
  [
    'rect',
    { width: '20', height: '14', x: '2', y: '3', rx: '2', key: '48i651' },
  ],
  ['path', { d: 'M12 17v4', key: '1riwvh' }],
  ['path', { d: 'M8 21h8', key: '1ev6f3' }],
]);

export default MonitorCheck;
