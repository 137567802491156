import createLucideIcon from '../createLucideIcon';

const Undo2 = createLucideIcon('Undo2', [
  ['path', { d: 'M9 14 4 9l5-5', key: '102s5s' }],
  [
    'path',
    {
      d: 'M4 9h10.5a5.5 5.5 0 0 1 5.5 5.5v0a5.5 5.5 0 0 1-5.5 5.5H11',
      key: 'llx8ln',
    },
  ],
]);

export default Undo2;
