import createLucideIcon from '../createLucideIcon';

const CreditCard = createLucideIcon('CreditCard', [
  [
    'rect',
    { width: '20', height: '14', x: '2', y: '5', rx: '2', key: 'ynyp8z' },
  ],
  ['line', { x1: '2', x2: '22', y1: '10', y2: '10', key: '1b3vmo' }],
]);

export default CreditCard;
