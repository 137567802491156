import { getConfig } from "../config.mjs";
import { mediaObjectToString } from "../hooks/useMedia.mjs";
import { getGroupPropParts } from "./getGroupPropParts.mjs";
const MEDIA_SEP = "_";
let prefixes = null,
  selectors = null;
const groupPseudoToPseudoCSSMap = {
    press: "active"
  },
  createMediaStyle = (styleObject, mediaKeyIn, mediaQueries, type, negate, priority) => {
    const {
        property,
        identifier,
        rules
      } = styleObject,
      conf = getConfig(),
      enableMediaPropOrder = conf.settings.mediaPropOrder,
      isTheme = type === "theme",
      isPlatform = type === "platform",
      isGroup = type === "group",
      isNonWindowMedia = isTheme || isPlatform || isGroup,
      negKey = negate ? "0" : "",
      ogPrefix = identifier.slice(0, identifier.indexOf("-") + 1),
      id = `${ogPrefix}${MEDIA_SEP}${mediaKeyIn.replace("-", "")}${negKey}${MEDIA_SEP}`;
    let styleRule = "",
      groupMediaKey,
      containerName,
      nextIdentifier = identifier.replace(ogPrefix, id),
      styleInner = rules.map(rule => rule.replace(identifier, nextIdentifier)).join(";");
    if (isNonWindowMedia) {
      const precedenceImportancePrefix = new Array((priority || 0) + (isGroup ? 1 : 0)).fill(":root").join("");
      if (isTheme || isGroup) {
        const groupInfo = getGroupPropParts(mediaKeyIn),
          mediaName = groupInfo?.name;
        groupMediaKey = groupInfo?.media, isGroup && (containerName = mediaName);
        const name = (isGroup ? "group_" : "") + mediaName,
          selectorStart = styleInner.indexOf(":root"),
          selectorEnd = styleInner.lastIndexOf("{"),
          selector = styleInner.slice(selectorStart, selectorEnd),
          precedenceSpace = conf.themeClassNameOnRoot && isTheme ? "" : " ",
          pseudoSelectorName = groupInfo.pseudo ? groupPseudoToPseudoCSSMap[groupInfo.pseudo] || groupInfo.pseudo : void 0,
          pseudoSelector = pseudoSelectorName ? `:${pseudoSelectorName}` : "",
          presedencePrefix = `:root${precedenceImportancePrefix}${precedenceSpace}`,
          mediaSelector = `.t_${name}${pseudoSelector}`,
          nextSelector = `${presedencePrefix}${mediaSelector} ${selector.replace(":root", "")}`;
        styleRule = styleInner.replace(selector, nextSelector);
      } else styleRule = `${precedenceImportancePrefix}${styleInner}`;
    }
    if (!isNonWindowMedia || groupMediaKey) {
      if (!selectors) {
        const mediaKeys = Object.keys(mediaQueries);
        selectors = Object.fromEntries(mediaKeys.map(key => [key, mediaObjectToString(mediaQueries[key])])), enableMediaPropOrder || (prefixes = Object.fromEntries(mediaKeys.map((k, index) => [k, new Array(index + 1).fill(":root").join("")])));
      }
      const mediaKey = groupMediaKey || mediaKeyIn,
        mediaSelector = selectors[mediaKey],
        mediaQuery = `${negate ? "not all and " : ""}${mediaSelector}`,
        precedenceImportancePrefix = groupMediaKey ? "" : enableMediaPropOrder ?
        // this new array should be cached
        new Array(priority).fill(":root").join("") :
        // @ts-ignore
        prefixes[mediaKey],
        prefix = groupMediaKey ? `@container ${containerName}` : "@media";
      groupMediaKey && (styleInner = styleRule), styleInner.includes(prefix) ? styleRule = styleInner.replace("{", ` and ${mediaQuery} {`).replace("and screen and", "and") : styleRule = `${prefix} ${mediaQuery}{${precedenceImportancePrefix}${styleInner}}`, groupMediaKey && (styleRule = `@supports (contain: ${conf.settings.webContainerType || "inline-size"}) {${styleRule}}`);
    }
    return {
      property,
      rules: [styleRule],
      identifier: nextIdentifier
    };
  };
export { MEDIA_SEP, createMediaStyle };