import createLucideIcon from '../createLucideIcon';

const Printer = createLucideIcon('Printer', [
  ['polyline', { points: '6 9 6 2 18 2 18 9', key: '1306q4' }],
  [
    'path',
    {
      d: 'M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2',
      key: '143wyd',
    },
  ],
  ['rect', { width: '12', height: '8', x: '6', y: '14', key: '5ipwut' }],
]);

export default Printer;
