import createLucideIcon from '../createLucideIcon';

const Sparkle = createLucideIcon('Sparkle', [
  [
    'path',
    {
      d: 'm12 3-1.9 5.8a2 2 0 0 1-1.287 1.288L3 12l5.8 1.9a2 2 0 0 1 1.288 1.287L12 21l1.9-5.8a2 2 0 0 1 1.287-1.288L21 12l-5.8-1.9a2 2 0 0 1-1.288-1.287Z',
      key: 'nraa5p',
    },
  ],
]);

export default Sparkle;
