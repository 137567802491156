import createLucideIcon from '../createLucideIcon';

const ArrowDownWideNarrow = createLucideIcon('ArrowDownWideNarrow', [
  ['path', { d: 'm3 16 4 4 4-4', key: '1co6wj' }],
  ['path', { d: 'M7 20V4', key: '1yoxec' }],
  ['path', { d: 'M11 4h10', key: '1w87gc' }],
  ['path', { d: 'M11 8h7', key: 'djye34' }],
  ['path', { d: 'M11 12h4', key: 'q8tih4' }],
]);

export default ArrowDownWideNarrow;
