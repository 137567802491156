import createLucideIcon from '../createLucideIcon';

const FlipHorizontal = createLucideIcon('FlipHorizontal', [
  ['path', { d: 'M8 3H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h3', key: '1i73f7' }],
  ['path', { d: 'M16 3h3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-3', key: 'saxlbk' }],
  ['path', { d: 'M12 20v2', key: '1lh1kg' }],
  ['path', { d: 'M12 14v2', key: '8jcxud' }],
  ['path', { d: 'M12 8v2', key: '1woqiv' }],
  ['path', { d: 'M12 2v2', key: 'tus03m' }],
]);

export default FlipHorizontal;
