import createLucideIcon from '../createLucideIcon';

const FormInput = createLucideIcon('FormInput', [
  [
    'rect',
    { width: '20', height: '12', x: '2', y: '6', rx: '2', key: '9lu3g6' },
  ],
  ['path', { d: 'M12 12h.01', key: '1mp3jc' }],
  ['path', { d: 'M17 12h.01', key: '1m0b6t' }],
  ['path', { d: 'M7 12h.01', key: 'eqddd0' }],
]);

export default FormInput;
