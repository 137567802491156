import { TamaguiProvider as OGProvider } from "@tamagui/core";
import { PortalProvider } from "@tamagui/portal";
import { jsx } from "react/jsx-runtime";
const TamaguiProvider = ({
  children,
  ...props
}) => /* @__PURE__ */jsx(OGProvider, {
  ...props,
  children: /* @__PURE__ */jsx(PortalProvider, {
    shouldAddRootHost: !0,
    children
  })
});
export { TamaguiProvider };