import createLucideIcon from '../createLucideIcon';

const Music4 = createLucideIcon('Music4', [
  ['path', { d: 'M9 18V5l12-2v13', key: '1jmyc2' }],
  ['path', { d: 'm9 9 12-2', key: '1e64n2' }],
  ['circle', { cx: '6', cy: '18', r: '3', key: 'fqmcym' }],
  ['circle', { cx: '18', cy: '16', r: '3', key: '1hluhg' }],
]);

export default Music4;
