import createLucideIcon from '../createLucideIcon';

const Ungroup = createLucideIcon('Ungroup', [
  ['rect', { width: '8', height: '6', x: '5', y: '4', rx: '1', key: 'nzclkv' }],
  [
    'rect',
    { width: '8', height: '6', x: '11', y: '14', rx: '1', key: '4tytwb' },
  ],
]);

export default Ungroup;
