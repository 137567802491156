import createLucideIcon from '../createLucideIcon';

const Cpu = createLucideIcon('Cpu', [
  [
    'rect',
    { x: '4', y: '4', width: '16', height: '16', rx: '2', key: '1vbyd7' },
  ],
  ['rect', { x: '9', y: '9', width: '6', height: '6', key: 'o3kz5p' }],
  ['path', { d: 'M15 2v2', key: '13l42r' }],
  ['path', { d: 'M15 20v2', key: '15mkzm' }],
  ['path', { d: 'M2 15h2', key: '1gxd5l' }],
  ['path', { d: 'M2 9h2', key: '1bbxkp' }],
  ['path', { d: 'M20 15h2', key: '19e6y8' }],
  ['path', { d: 'M20 9h2', key: '19tzq7' }],
  ['path', { d: 'M9 2v2', key: '165o2o' }],
  ['path', { d: 'M9 20v2', key: 'i2bqo8' }],
]);

export default Cpu;
