import createLucideIcon from '../createLucideIcon';

const Webcam = createLucideIcon('Webcam', [
  ['circle', { cx: '12', cy: '10', r: '8', key: '1gshiw' }],
  ['circle', { cx: '12', cy: '10', r: '3', key: 'ilqhr7' }],
  ['path', { d: 'M7 22h10', key: '10w4w3' }],
  ['path', { d: 'M12 22v-4', key: '1utk9m' }],
]);

export default Webcam;
