import React from 'react';

import {Platform} from 'react-native';
import {Switch as BasSwitch, SizeTokens} from 'tamagui';

import {useAppDirection} from '../../utils/useAppDirection';
import {Spinner} from '../Spinner';

export type SwitchProps = {
  name?: string;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  required?: boolean;
  isLoading?: boolean;
  size?: SizeTokens;
};

export function Switch({isLoading, ...props}: SwitchProps) {
  const {isRTL} = useAppDirection();

  return (
    <BasSwitch
      {...props}
      direction="ltr"
      borderColor="$border"
      flexDirection={isRTL ? 'row-reverse' : 'row'}
      backgroundColor={props.checked ? '$accent' : '$neutral-200'}
      hoverStyle={{cursor: 'pointer'}}>
      {isLoading ? (
        <Spinner color={props.checked ? '$white' : '$accent'} />
      ) : (
        <BasSwitch.Thumb
          {...(Platform.OS !== 'web' ? {alignSelf: isRTL ? 'flex-end' : 'flex-start'} : {})}
          direction="ltr"
          animation="quick"
          backgroundColor={props.checked ? '$neutral-200' : '$neutral-400'}
          style={{
            width: 24,
            height: 24,
          }}
        />
      )}
    </BasSwitch>
  );
}
