import React from 'react';

import {Badge} from '../../Badge';
import {IconView} from '../../IconView';
import {LocalizedText} from '../../LocalizedText';
import {Pressable} from '../../Pressable';
import {CoreTrans} from '../../ScopedTrans';
import {Typography} from '../../Typography';
import {XStack} from '../../XStack';
import {YStack} from '../../YStack';

import {TriggerProps} from './Trigger';

type Props = Pick<TriggerProps, 'width' | 'selectedValues' | 'onChangeSelection' | 'label' | 'triggerInputStyles'> & {
  onReset: () => void;
  setTriggerWidth?: React.Dispatch<React.SetStateAction<number | null>>;
};

export const BadgesContainer = ({
  label,
  onReset,
  width,
  setTriggerWidth,
  selectedValues,
  triggerInputStyles,
  onChangeSelection,
}: Props) => {
  return (
    <YStack
      theme="translucent"
      width={width || '100%'}
      gap="$8"
      onLayout={(e) => setTriggerWidth?.(e.nativeEvent.layout.width)}>
      <XStack marginBottom="$0.5" flex={1} justifyContent="space-between" alignItems="center">
        <Typography fontWeight="600" variant="body2">
          <LocalizedText value={label} />
        </Typography>

        <Pressable onPress={() => onReset()}>
          <Typography fontWeight="600" variant="body2" color="$accent">
            <CoreTrans i18nKey="action.reset" />
          </Typography>
        </Pressable>
      </XStack>

      <XStack
        minHeight={40}
        alignItems="center"
        flex={1}
        borderRadius={6}
        borderWidth="$0.5"
        borderColor="$border"
        flexWrap="wrap"
        padding="$12"
        focusStyle={{borderColor: '$accent'}}
        hoverStyle={{borderColor: '$borderHover'}}
        gap="$10"
        style={triggerInputStyles}
        backgroundColor="$background">
        {selectedValues.map((option) => {
          return (
            <Badge
              alignSelf="center"
              key={option.value}
              label={option.label as any}
              gap="$6"
              suffix={
                <Pressable onPress={() => onChangeSelection(option)}>
                  <IconView icon="X" color="$neutral-600" />
                </Pressable>
              }
            />
          );
        })}
      </XStack>
    </YStack>
  );
};
