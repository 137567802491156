import createLucideIcon from '../createLucideIcon';

const Touchpad = createLucideIcon('Touchpad', [
  [
    'rect',
    { width: '20', height: '16', x: '2', y: '4', rx: '2', key: '18n3k1' },
  ],
  ['path', { d: 'M2 14h20', key: 'myj16y' }],
  ['path', { d: 'M12 20v-6', key: '1rm09r' }],
]);

export default Touchpad;
