import createLucideIcon from '../createLucideIcon';

const Milestone = createLucideIcon('Milestone', [
  [
    'path',
    {
      d: 'M18 6H5a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h13l4-3.5L18 6Z',
      key: '1mp5s7',
    },
  ],
  ['path', { d: 'M12 13v8', key: '1l5pq0' }],
  ['path', { d: 'M12 3v3', key: '1n5kay' }],
]);

export default Milestone;
