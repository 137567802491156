import createLucideIcon from '../createLucideIcon';

const KeyRound = createLucideIcon('KeyRound', [
  [
    'path',
    {
      d: 'M2 18v3c0 .6.4 1 1 1h4v-3h3v-3h2l1.4-1.4a6.5 6.5 0 1 0-4-4Z',
      key: '167ctg',
    },
  ],
  ['circle', { cx: '16.5', cy: '7.5', r: '.5', key: '1kog09' }],
]);

export default KeyRound;
