import createLucideIcon from '../createLucideIcon';

const Chrome = createLucideIcon('Chrome', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['circle', { cx: '12', cy: '12', r: '4', key: '4exip2' }],
  ['line', { x1: '21.17', x2: '12', y1: '8', y2: '8', key: 'a0cw5f' }],
  ['line', { x1: '3.95', x2: '8.54', y1: '6.06', y2: '14', key: '1kftof' }],
  ['line', { x1: '10.88', x2: '15.46', y1: '21.94', y2: '14', key: '1ymyh8' }],
]);

export default Chrome;
