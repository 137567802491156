import createLucideIcon from '../createLucideIcon';

const MenuSquare = createLucideIcon('MenuSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M7 8h10', key: '1jw688' }],
  ['path', { d: 'M7 12h10', key: 'b7w52i' }],
  ['path', { d: 'M7 16h10', key: 'wp8him' }],
]);

export default MenuSquare;
