import createLucideIcon from '../createLucideIcon';

const Ampersands = createLucideIcon('Ampersands', [
  [
    'path',
    {
      d: 'M10 17c-5-3-7-7-7-9a2 2 0 0 1 4 0c0 2.5-5 2.5-5 6 0 1.7 1.3 3 3 3 2.8 0 5-2.2 5-5',
      key: '12lh1k',
    },
  ],
  [
    'path',
    {
      d: 'M22 17c-5-3-7-7-7-9a2 2 0 0 1 4 0c0 2.5-5 2.5-5 6 0 1.7 1.3 3 3 3 2.8 0 5-2.2 5-5',
      key: '173c68',
    },
  ],
]);

export default Ampersands;
