import createLucideIcon from '../createLucideIcon';

const RectangleVertical = createLucideIcon('RectangleVertical', [
  [
    'rect',
    { width: '12', height: '20', x: '6', y: '2', rx: '2', key: '1oxtiu' },
  ],
]);

export default RectangleVertical;
