import createLucideIcon from '../createLucideIcon';

const RefreshCcw = createLucideIcon('RefreshCcw', [
  [
    'path',
    { d: 'M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8', key: '14sxne' },
  ],
  ['path', { d: 'M3 3v5h5', key: '1xhq8a' }],
  [
    'path',
    { d: 'M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16', key: '1hlbsb' },
  ],
  ['path', { d: 'M16 16h5v5', key: 'ccwih5' }],
]);

export default RefreshCcw;
