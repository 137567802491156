import createLucideIcon from '../createLucideIcon';

const SquareDot = createLucideIcon('SquareDot', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['circle', { cx: '12', cy: '12', r: '1', key: '41hilf' }],
]);

export default SquareDot;
