import createLucideIcon from '../createLucideIcon';

const IndianRupee = createLucideIcon('IndianRupee', [
  ['path', { d: 'M6 3h12', key: 'ggurg9' }],
  ['path', { d: 'M6 8h12', key: '6g4wlu' }],
  ['path', { d: 'm6 13 8.5 8', key: 'u1kupk' }],
  ['path', { d: 'M6 13h3', key: 'wdp6ag' }],
  ['path', { d: 'M9 13c6.667 0 6.667-10 0-10', key: '1nkvk2' }],
]);

export default IndianRupee;
