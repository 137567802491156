import createLucideIcon from '../createLucideIcon';

const ZoomIn = createLucideIcon('ZoomIn', [
  ['circle', { cx: '11', cy: '11', r: '8', key: '4ej97u' }],
  ['line', { x1: '21', x2: '16.65', y1: '21', y2: '16.65', key: '13gj7c' }],
  ['line', { x1: '11', x2: '11', y1: '8', y2: '14', key: '1vmskp' }],
  ['line', { x1: '8', x2: '14', y1: '11', y2: '11', key: 'durymu' }],
]);

export default ZoomIn;
