import createLucideIcon from '../createLucideIcon';

const LayoutPanelLeft = createLucideIcon('LayoutPanelLeft', [
  ['rect', { width: '7', height: '18', x: '3', y: '3', rx: '1', key: '2obqm' }],
  [
    'rect',
    { width: '7', height: '7', x: '14', y: '3', rx: '1', key: '6d4xhi' },
  ],
  [
    'rect',
    { width: '7', height: '7', x: '14', y: '14', rx: '1', key: 'nxv5o0' },
  ],
]);

export default LayoutPanelLeft;
