import createLucideIcon from '../createLucideIcon';

const Locate = createLucideIcon('Locate', [
  ['line', { x1: '2', x2: '5', y1: '12', y2: '12', key: 'bvdh0s' }],
  ['line', { x1: '19', x2: '22', y1: '12', y2: '12', key: '1tbv5k' }],
  ['line', { x1: '12', x2: '12', y1: '2', y2: '5', key: '11lu5j' }],
  ['line', { x1: '12', x2: '12', y1: '19', y2: '22', key: 'x3vr5v' }],
  ['circle', { cx: '12', cy: '12', r: '7', key: 'fim9np' }],
]);

export default Locate;
