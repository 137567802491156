import createLucideIcon from '../createLucideIcon';

const FlashlightOff = createLucideIcon('FlashlightOff', [
  [
    'path',
    {
      d: 'M16 16v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V10c0-2-2-2-2-4',
      key: '1r120k',
    },
  ],
  ['path', { d: 'M7 2h11v4c0 2-2 2-2 4v1', key: 'dz1920' }],
  ['line', { x1: '11', x2: '18', y1: '6', y2: '6', key: 'bi1vpe' }],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22', key: 'a6p6uj' }],
]);

export default FlashlightOff;
