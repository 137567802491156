import { Portal } from "@tamagui/portal";
import { Platform } from "react-native-web";
import { ReprogapateToastProvider, useToastProviderContext } from "./ToastProvider.mjs";
import { jsx } from "react/jsx-runtime";
function ToastPortal({
  children,
  zIndex
}) {
  let content = children;
  return Platform.OS === "android" && (content = /* @__PURE__ */jsx(ReprogapateToastProvider, {
    context: useToastProviderContext(),
    children
  })), /* @__PURE__ */jsx(Portal, {
    zIndex: zIndex || 1e9,
    children: content
  });
}
export { ToastPortal };