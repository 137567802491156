import createLucideIcon from '../createLucideIcon';

const Scaling = createLucideIcon('Scaling', [
  ['path', { d: 'M21 3 9 15', key: '15kdhq' }],
  ['path', { d: 'M12 3H3v18h18v-9', key: '8suug0' }],
  ['path', { d: 'M16 3h5v5', key: '1806ms' }],
  ['path', { d: 'M14 15H9v-5', key: 'pi4jk9' }],
]);

export default Scaling;
