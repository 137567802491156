import createLucideIcon from '../createLucideIcon';

const Grip = createLucideIcon('Grip', [
  ['circle', { cx: '12', cy: '5', r: '1', key: 'gxeob9' }],
  ['circle', { cx: '19', cy: '5', r: '1', key: 'w8mnmm' }],
  ['circle', { cx: '5', cy: '5', r: '1', key: 'lttvr7' }],
  ['circle', { cx: '12', cy: '12', r: '1', key: '41hilf' }],
  ['circle', { cx: '19', cy: '12', r: '1', key: '1wjl8i' }],
  ['circle', { cx: '5', cy: '12', r: '1', key: '1pcz8c' }],
  ['circle', { cx: '12', cy: '19', r: '1', key: 'lyex9k' }],
  ['circle', { cx: '19', cy: '19', r: '1', key: 'shf9b7' }],
  ['circle', { cx: '5', cy: '19', r: '1', key: 'bfqh0e' }],
]);

export default Grip;
