import createLucideIcon from '../createLucideIcon';

const MonitorPlay = createLucideIcon('MonitorPlay', [
  ['path', { d: 'm10 7 5 3-5 3Z', key: '29ljg6' }],
  [
    'rect',
    { width: '20', height: '14', x: '2', y: '3', rx: '2', key: '48i651' },
  ],
  ['path', { d: 'M12 17v4', key: '1riwvh' }],
  ['path', { d: 'M8 21h8', key: '1ev6f3' }],
]);

export default MonitorPlay;
