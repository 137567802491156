import createLucideIcon from '../createLucideIcon';

const ListTree = createLucideIcon('ListTree', [
  ['path', { d: 'M21 12h-8', key: '1bmf0i' }],
  ['path', { d: 'M21 6H8', key: '1pqkrb' }],
  ['path', { d: 'M21 18h-8', key: '1tm79t' }],
  ['path', { d: 'M3 6v4c0 1.1.9 2 2 2h3', key: '1ywdgy' }],
  ['path', { d: 'M3 10v6c0 1.1.9 2 2 2h3', key: '2wc746' }],
]);

export default ListTree;
