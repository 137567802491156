import React from 'react';

import {BuilderElement, LocalizedTextType, NodeType} from '../../../types';
import {LocalizedText} from '../../LocalizedText';
import {Typography, TypographyProps} from '../../Typography';

export type TextElementProps = TypographyProps & {
  value?: LocalizedTextType;
};

const defaultProps: TextElementProps = {
  lineHeight: 20,
  fontSize: 16,
  fontWeight: '500',
  value:
    'Your content goes here. Edit styles and content or remove this text From the side bar  / Your content goes here. Edit styles and content or remove this text From the side bar',
};

export const Component = ({value, ...props}: TextElementProps) => {
  return (
    <Typography {...props}>
      <LocalizedText value={value} />
    </Typography>
  );
};

export const TextElement: BuilderElement<TextElementProps> = {
  nodeId: '',
  id: 'TextElement',
  icon: 'Type',
  Component,
  props: defaultProps,
  type: NodeType.TEXT,
};
