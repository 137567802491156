import createLucideIcon from '../createLucideIcon';

const BadgePercent = createLucideIcon('BadgePercent', [
  [
    'path',
    {
      d: 'M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z',
      key: '3c2336',
    },
  ],
  ['path', { d: 'm15 9-6 6', key: '1uzhvr' }],
  ['path', { d: 'M9 9h.01', key: '1q5me6' }],
  ['path', { d: 'M15 15h.01', key: 'lqbp3k' }],
]);

export default BadgePercent;
