import createLucideIcon from '../createLucideIcon';

const LayoutTemplate = createLucideIcon('LayoutTemplate', [
  [
    'rect',
    { width: '18', height: '7', x: '3', y: '3', rx: '1', key: 'f1a2em' },
  ],
  [
    'rect',
    { width: '9', height: '7', x: '3', y: '14', rx: '1', key: 'jqznyg' },
  ],
  [
    'rect',
    { width: '5', height: '7', x: '16', y: '14', rx: '1', key: 'q5h2i8' },
  ],
]);

export default LayoutTemplate;
