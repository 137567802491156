import createLucideIcon from '../createLucideIcon';

const PlugZap2 = createLucideIcon('PlugZap2', [
  ['path', { d: 'm13 2-2 2.5h3L12 7', key: '1me98u' }],
  ['path', { d: 'M10 14v-3', key: '1mllf3' }],
  ['path', { d: 'M14 14v-3', key: '1l3fkq' }],
  [
    'path',
    { d: 'M11 19c-1.7 0-3-1.3-3-3v-2h8v2c0 1.7-1.3 3-3 3Z', key: 'jd5pat' },
  ],
  ['path', { d: 'M12 22v-3', key: 'kmzjlo' }],
]);

export default PlugZap2;
