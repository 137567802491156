import createLucideIcon from '../createLucideIcon';

const MonitorOff = createLucideIcon('MonitorOff', [
  ['path', { d: 'M17 17H4a2 2 0 0 1-2-2V5c0-1.5 1-2 1-2', key: 'k0q8oc' }],
  ['path', { d: 'M22 15V5a2 2 0 0 0-2-2H9', key: 'cp1ac0' }],
  ['path', { d: 'M8 21h8', key: '1ev6f3' }],
  ['path', { d: 'M12 17v4', key: '1riwvh' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
]);

export default MonitorOff;
