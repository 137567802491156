import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import { createContext } from "@tamagui/create-context";
import { focusFocusable } from "@tamagui/focusable";
import { getButtonSized } from "@tamagui/get-button-sized";
import { getFontSized } from "@tamagui/get-font-sized";
import { SizableText } from "@tamagui/text";
import { styled, themeable } from "@tamagui/web";
import * as React from "react";
import { jsx } from "react/jsx-runtime";
const NAME = "Label",
  [LabelProvider, useLabelContextImpl] = createContext(NAME, {
    id: void 0,
    controlRef: {
      current: null
    }
  }),
  LabelFrame = styled(SizableText, {
    name: "Label",
    tag: "label",
    variants: {
      unstyled: {
        false: {
          size: "$true",
          color: "$color",
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          userSelect: "none",
          cursor: "default",
          pressStyle: {
            color: "$colorPress"
          }
        }
      },
      size: {
        "...size": (val, extras) => {
          const buttonHeight = getButtonSized(val, extras)?.height;
          return {
            ...getFontSized(val, extras),
            lineHeight: buttonHeight ? extras.tokens.size[buttonHeight] : void 0
          };
        }
      }
    },
    defaultVariants: {
      unstyled: process.env.TAMAGUI_HEADLESS === "1"
    }
  }),
  LabelComponent = React.forwardRef((props, forwardedRef) => {
    const {
        htmlFor,
        id: idProp,
        ...labelProps
      } = props,
      controlRef = React.useRef(null),
      ref = React.useRef(null),
      composedRefs = useComposedRefs(forwardedRef, ref),
      backupId = React.useId(),
      id = idProp ?? backupId;
    return isWeb && React.useEffect(() => {
      if (htmlFor) {
        const element = document.getElementById(htmlFor);
        if (ref.current && element) {
          const getAriaLabel = () => element.getAttribute("aria-labelledby"),
            ariaLabelledBy = [id, getAriaLabel()].filter(Boolean).join(" ");
          return element.setAttribute("aria-labelledby", ariaLabelledBy), controlRef.current = element, () => {
            if (!id) return;
            const ariaLabelledBy2 = getAriaLabel()?.replace(id, "");
            ariaLabelledBy2 === "" ? element.removeAttribute("aria-labelledby") : ariaLabelledBy2 && element.setAttribute("aria-labelledby", ariaLabelledBy2);
          };
        }
      }
    }, [id, htmlFor]), /* @__PURE__ */jsx(LabelProvider, {
      id,
      controlRef,
      children: /* @__PURE__ */jsx(LabelFrame, {
        id,
        htmlFor,
        ...labelProps,
        ref: composedRefs,
        onMouseDown: event => {
          props.onMouseDown?.(event), !event.defaultPrevented && event.detail > 1 && event.preventDefault();
        },
        onPress: event => {
          if (props.onPress?.(event), isWeb) {
            if (htmlFor || !controlRef.current || event.defaultPrevented) return;
            const isClickingControl = controlRef.current.contains(event.target),
              isUserClick = event.isTrusted === !0;
            !isClickingControl && isUserClick && (controlRef.current.click(), controlRef.current.focus());
          } else props.htmlFor && focusFocusable(props.htmlFor);
        }
      })
    });
  });
LabelComponent.displayName = NAME;
const Label = LabelFrame.extractable(themeable(LabelComponent), {
    neverFlatten: !0
  }),
  useLabelContext = element => {
    const context = useLabelContextImpl("LabelConsumer"),
      {
        controlRef
      } = context;
    return React.useEffect(() => {
      element && (controlRef.current = element);
    }, [element, controlRef]), context.id;
  };
export { Label, LabelFrame, useLabelContext };