import createLucideIcon from '../createLucideIcon';

const UserSquare = createLucideIcon('UserSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['circle', { cx: '12', cy: '10', r: '3', key: 'ilqhr7' }],
  ['path', { d: 'M7 21v-2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2', key: '1m6ac2' }],
]);

export default UserSquare;
