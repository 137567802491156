import createLucideIcon from '../createLucideIcon';

const Warehouse = createLucideIcon('Warehouse', [
  [
    'path',
    {
      d: 'M22 8.35V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8.35A2 2 0 0 1 3.26 6.5l8-3.2a2 2 0 0 1 1.48 0l8 3.2A2 2 0 0 1 22 8.35Z',
      key: 'gksnxg',
    },
  ],
  ['path', { d: 'M6 18h12', key: '9pbo8z' }],
  ['path', { d: 'M6 14h12', key: '4cwo0f' }],
  ['rect', { width: '12', height: '12', x: '6', y: '10', key: 'apd30q' }],
]);

export default Warehouse;
