import createLucideIcon from '../createLucideIcon';

const ReplaceAll = createLucideIcon('ReplaceAll', [
  ['path', { d: 'M14 4c0-1.1.9-2 2-2', key: '1mvvbw' }],
  ['path', { d: 'M20 2c1.1 0 2 .9 2 2', key: '1mj6oe' }],
  ['path', { d: 'M22 8c0 1.1-.9 2-2 2', key: 'v1wql3' }],
  ['path', { d: 'M16 10c-1.1 0-2-.9-2-2', key: '821ux0' }],
  ['path', { d: 'm3 7 3 3 3-3', key: 'x25e72' }],
  ['path', { d: 'M6 10V5c0-1.7 1.3-3 3-3h1', key: '13af7h' }],
  [
    'rect',
    { width: '8', height: '8', x: '2', y: '14', rx: '2', key: '17ihk4' },
  ],
  ['path', { d: 'M14 14c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2', key: '1w9p8c' }],
  ['path', { d: 'M20 14c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2', key: 'm45eaa' }],
]);

export default ReplaceAll;
