import createLucideIcon from '../createLucideIcon';

const ArrowDownUp = createLucideIcon('ArrowDownUp', [
  ['path', { d: 'm3 16 4 4 4-4', key: '1co6wj' }],
  ['path', { d: 'M7 20V4', key: '1yoxec' }],
  ['path', { d: 'm21 8-4-4-4 4', key: '1c9v7m' }],
  ['path', { d: 'M17 4v16', key: '7dpous' }],
]);

export default ArrowDownUp;
