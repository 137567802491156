import createLucideIcon from '../createLucideIcon';

const Calculator = createLucideIcon('Calculator', [
  [
    'rect',
    { width: '16', height: '20', x: '4', y: '2', rx: '2', key: '1nb95v' },
  ],
  ['line', { x1: '8', x2: '16', y1: '6', y2: '6', key: 'x4nwl0' }],
  ['line', { x1: '16', x2: '16', y1: '14', y2: '18', key: 'wjye3r' }],
  ['path', { d: 'M16 10h.01', key: '1m94wz' }],
  ['path', { d: 'M12 10h.01', key: '1nrarc' }],
  ['path', { d: 'M8 10h.01', key: '19clt8' }],
  ['path', { d: 'M12 14h.01', key: '1etili' }],
  ['path', { d: 'M8 14h.01', key: '6423bh' }],
  ['path', { d: 'M12 18h.01', key: 'mhygvu' }],
  ['path', { d: 'M8 18h.01', key: 'lrp35t' }],
]);

export default Calculator;
