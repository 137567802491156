import {
  AppContentPage,
  Applet,
  AppPage,
  AppStructure,
  isAppContentPage,
  isAppServicePage,
  isBuilderWidgetElement,
} from '../../types';
import {createAppServicePage} from '../appStructureFactory';

export function processAppStructure(
  structure: AppStructure,
  enabledApplets: Applet[],
  showAllWidgets = false,
  isAuthenticated = false,
) {
  const enabledAppletsMap = new Map<string, Applet>();
  enabledApplets.forEach((applet) => enabledAppletsMap.set(applet.id, applet));

  addMissingServices(structure.pages, enabledApplets);

  structure.pages = escapePages(structure.pages, enabledAppletsMap, showAllWidgets, isAuthenticated);

  Object.values(structure.auxiliaryPages).forEach((group) => {
    group.pages = group.pages.filter((page) =>
      escapeContentPageDisabledWidgets(page, enabledAppletsMap, showAllWidgets, isAuthenticated),
    );
  });

  Object.values(structure.launcher).forEach((launcherPage) => {
    escapeContentPageDisabledWidgets(launcherPage, enabledAppletsMap, showAllWidgets, isAuthenticated);
  });

  return structure;
}

function addMissingServices(pages: AppPage[], enabledApplets: Applet[]) {
  const alreadyAdded = new Set();
  pages.forEach((page) => isAppServicePage(page) && alreadyAdded.add(page.serviceId));

  enabledApplets.forEach((applet) => {
    applet.services.forEach((service) => {
      if (!alreadyAdded.has(service.id)) {
        pages.push(createAppServicePage(applet.id, service.id));
        alreadyAdded.add(service.id);
      }
    });
  });
}

function escapePages(
  pages: AppPage[],
  enabledAppletsMap: Map<string, Applet>,
  showAllWidgets = false,
  isAuthenticated = false,
): AppPage[] {
  return pages.filter((page) => {
    if (isAppServicePage(page)) {
      const {appletId, serviceId} = page;
      const applet = enabledAppletsMap.get(appletId);

      const service = applet?.services.find(({id}) => id === serviceId);
      if (!applet || !service) return false;

      if (showAllWidgets) return true;

      return isAuthenticated ? applet.canView(service, applet._context as any) : service.public;
    }

    if (isAppContentPage(page)) {
      // [enhance]: add public/secret content pages
      return escapeContentPageDisabledWidgets(page, enabledAppletsMap, showAllWidgets, isAuthenticated);
    }
  });
}

function escapeContentPageDisabledWidgets(
  page: AppContentPage,
  enabledAppletsMap: Map<string, Applet>,
  showAllWidgets = false,
  isAuthenticated = false,
) {
  if (page.design && page.design.length) {
    page.design.forEach((section) =>
      section.rows.map((row) => {
        row.cells.filter((cell) => {
          if (cell.nodes.length) {
            for (let index = 0; index < cell.nodes.length; index++) {
              const node = cell.nodes[index];

              if (isBuilderWidgetElement(node)) {
                const applet = enabledAppletsMap.get(node.appletId);
                const appletIsDisabled = !enabledAppletsMap.has(node.id);
                const widget = applet?.widgets.find(({id}) => id === node?.id);

                if (appletIsDisabled) return false;
                if (showAllWidgets) return true;

                if (applet && widget) {
                  return !(isAuthenticated ? applet.canView(widget, applet._context as any) : widget.public);
                }
              }
            }
          }
        });
      }),
    );
  }

  return page?.design?.length;
}
