import React from 'react';

import {ImageResizeMode} from 'react-native';
import {RadiusTokens} from 'tamagui';

import {BuilderElement, NodeType} from '../../../types';
import {Image} from '../../Image';
import {ViewProps} from '../../View';
import {YStack} from '../../YStack';

export type ImageElementProps = {
  uri: string;
  resizeMode?: ImageResizeMode;
  borderRadius: RadiusTokens;
  borderWidth?: number;
  style?: ViewProps['style'];
  height?: ViewProps['height'];
  flex?: ViewProps['flex'];
  width: ViewProps['width'];
};

const defaultProps: ImageElementProps = {
  width: '100%',
  height: '$18',
  uri: 'https://bgr.com/wp-content/uploads/2022/03/AdobeStock_194080021.jpeg?quality=82&strip=all',
  borderRadius: 0,
  flex: 1,
  resizeMode: 'cover',
};

export const Component = ({uri, resizeMode, borderRadius, borderWidth, ...props}: ImageElementProps) => {
  return (
    <YStack {...props}>
      <Image
        source={{uri}}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        resizeMode={resizeMode}
        width="100%"
        height="100%"
      />
    </YStack>
  );
};

export const ImageElement: BuilderElement<ImageElementProps> = {
  id: 'ImageElement',
  nodeId: '',
  icon: 'Image',
  type: NodeType.IMAGE,
  Component,
  props: defaultProps,
};
