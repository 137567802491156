import createLucideIcon from '../createLucideIcon';

const Landmark = createLucideIcon('Landmark', [
  ['line', { x1: '3', x2: '21', y1: '22', y2: '22', key: 'j8o0r' }],
  ['line', { x1: '6', x2: '6', y1: '18', y2: '11', key: '10tf0k' }],
  ['line', { x1: '10', x2: '10', y1: '18', y2: '11', key: '54lgf6' }],
  ['line', { x1: '14', x2: '14', y1: '18', y2: '11', key: '380y' }],
  ['line', { x1: '18', x2: '18', y1: '18', y2: '11', key: '1kevvc' }],
  ['polygon', { points: '12 2 20 7 4 7', key: 'jkujk7' }],
]);

export default Landmark;
