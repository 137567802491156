import createLucideIcon from '../createLucideIcon';

const CaseSensitive = createLucideIcon('CaseSensitive', [
  ['path', { d: 'm3 15 4-8 4 8', key: '1vwr6u' }],
  ['path', { d: 'M4 13h6', key: '1r9ots' }],
  ['circle', { cx: '18', cy: '12', r: '3', key: '1kchzo' }],
  ['path', { d: 'M21 9v6', key: 'anns31' }],
]);

export default CaseSensitive;
