import createLucideIcon from '../createLucideIcon';

const Grape = createLucideIcon('Grape', [
  ['path', { d: 'M22 5V2l-5.89 5.89', key: '1eenpo' }],
  ['circle', { cx: '16.6', cy: '15.89', r: '3', key: 'xjtalx' }],
  ['circle', { cx: '8.11', cy: '7.4', r: '3', key: 'u2fv6i' }],
  ['circle', { cx: '12.35', cy: '11.65', r: '3', key: 'i6i8g7' }],
  ['circle', { cx: '13.91', cy: '5.85', r: '3', key: '6ye0dv' }],
  ['circle', { cx: '18.15', cy: '10.09', r: '3', key: 'snx9no' }],
  ['circle', { cx: '6.56', cy: '13.2', r: '3', key: '17x4xg' }],
  ['circle', { cx: '10.8', cy: '17.44', r: '3', key: '1hogw9' }],
  ['circle', { cx: '5', cy: '19', r: '3', key: '1sn6vo' }],
]);

export default Grape;
