import createLucideIcon from '../createLucideIcon';

const AlignHorizontalDistributeStart = createLucideIcon(
  'AlignHorizontalDistributeStart',
  [
    [
      'rect',
      { width: '6', height: '14', x: '4', y: '5', rx: '2', key: '1wwnby' },
    ],
    [
      'rect',
      { width: '6', height: '10', x: '14', y: '7', rx: '2', key: '1fe6j6' },
    ],
    ['path', { d: 'M4 2v20', key: 'gtpd5x' }],
    ['path', { d: 'M14 2v20', key: 'tg6bpw' }],
  ],
);

export default AlignHorizontalDistributeStart;
