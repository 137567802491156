import createLucideIcon from '../createLucideIcon';

const Feather = createLucideIcon('Feather', [
  [
    'path',
    { d: 'M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z', key: 'u4sw5n' },
  ],
  ['line', { x1: '16', x2: '2', y1: '8', y2: '22', key: '1c47m2' }],
  ['line', { x1: '17.5', x2: '9', y1: '15', y2: '15', key: '2fj3pr' }],
]);

export default Feather;
