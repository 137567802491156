import createLucideIcon from '../createLucideIcon';

const Annoyed = createLucideIcon('Annoyed', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['path', { d: 'M8 15h8', key: '45n4r' }],
  ['path', { d: 'M8 9h2', key: '1g203m' }],
  ['path', { d: 'M14 9h2', key: '116p9w' }],
]);

export default Annoyed;
