import React from 'react';

import {PageRow, ViewportSizes} from '../../../../types';
import {usePropsByViewPortSize} from '../../../../utils';
import {RowWrapper} from '../../RowWrapper';

import {CellPreview} from './CellPreview';

type RowPreviewProps = {
  row: PageRow;
  viewportSize: ViewportSizes;
  builderContentElements: Record<string, any>;
};

export const RowPreview = ({row, viewportSize, builderContentElements}: RowPreviewProps) => {
  const rowProps = usePropsByViewPortSize(row.props);

  return (
    <RowWrapper id={row.id} preview={true} key={row.id} {...rowProps}>
      {row.cells.map((cell) => (
        <CellPreview
          key={cell.id}
          cell={cell}
          viewportSize={viewportSize}
          builderContentElements={builderContentElements}
        />
      ))}
    </RowWrapper>
  );
};
