import createLucideIcon from '../createLucideIcon';

const RockingChair = createLucideIcon('RockingChair', [
  ['polyline', { points: '3.5 2 6.5 12.5 18 12.5', key: 'y3iy52' }],
  ['line', { x1: '9.5', x2: '5.5', y1: '12.5', y2: '20', key: '19vg5i' }],
  ['line', { x1: '15', x2: '18.5', y1: '12.5', y2: '20', key: '1inpmv' }],
  ['path', { d: 'M2.75 18a13 13 0 0 0 18.5 0', key: '1nquas' }],
]);

export default RockingChair;
