import createLucideIcon from '../createLucideIcon';

const PanelTopInactive = createLucideIcon('PanelTopInactive', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M14 9h1', key: 'l0svgy' }],
  ['path', { d: 'M19 9h2', key: 'te2zfg' }],
  ['path', { d: 'M3 9h2', key: '1h4ldw' }],
  ['path', { d: 'M9 9h1', key: '15jzuz' }],
]);

export default PanelTopInactive;
