import { forwardRef } from "react";
import { useTheme } from "./hooks/useTheme.mjs";
import { jsx } from "react/jsx-runtime";
const _withStableStyle = (Component, styleProvider) => forwardRef((props, ref) => {
  const {
      _expressions = [],
      ...rest
    } = props,
    theme = useTheme();
  return /* @__PURE__ */jsx(Component, {
    ref,
    style: styleProvider(theme, _expressions),
    ...rest
  });
});
export { _withStableStyle };