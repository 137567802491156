import createLucideIcon from '../createLucideIcon';

const Vegan = createLucideIcon('Vegan', [
  [
    'path',
    { d: 'M2 2a26.6 26.6 0 0 1 10 20c.9-6.82 1.5-9.5 4-14', key: 'qiv7li' },
  ],
  ['path', { d: 'M16 8c4 0 6-2 6-6-4 0-6 2-6 6', key: 'n7eohy' }],
  ['path', { d: 'M17.41 3.6a10 10 0 1 0 3 3', key: '1dion0' }],
]);

export default Vegan;
