import { AdaptParentContext } from "@tamagui/adapt";
import { AnimatePresence } from "@tamagui/animate-presence";
import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb, useIsomorphicLayoutEffect } from "@tamagui/constants";
import { getConfig, Stack, Theme, themeable, useConfiguration, useEvent, useThemeName } from "@tamagui/core";
import { Portal } from "@tamagui/portal";
import { useKeyboardVisible } from "@tamagui/use-keyboard-visible";
import { forwardRef, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Dimensions, Keyboard, PanResponder, View } from "react-native-web";
import { SHEET_HIDDEN_STYLESHEET } from "./constants.mjs";
import { ParentSheetContext, SheetInsideSheetContext } from "./contexts.mjs";
import { resisted } from "./helpers.mjs";
import { SheetProvider } from "./SheetContext.mjs";
import { useSheetOpenState } from "./useSheetOpenState.mjs";
import { useSheetProviderProps } from "./useSheetProviderProps.mjs";
import { jsx, jsxs } from "react/jsx-runtime";
let hiddenSize = 10000.1;
const SheetImplementationCustom = themeable(forwardRef(function (props, forwardedRef) {
  const parentSheet = useContext(ParentSheetContext),
    {
      animation,
      animationConfig: animationConfigProp,
      modal = !1,
      zIndex = parentSheet.zIndex + 1,
      moveOnKeyboardChange = !1,
      unmountChildrenWhenHidden = !1,
      portalProps,
      containerComponent: ContainerComponent = Fragment
    } = props,
    keyboardIsVisible = useKeyboardVisible(),
    state = useSheetOpenState(props),
    [overlayComponent, setOverlayComponent] = useState(null),
    providerProps = useSheetProviderProps(props, state, {
      onOverlayComponent: setOverlayComponent
    }),
    {
      frameSize,
      setFrameSize,
      snapPoints,
      snapPointsMode,
      hasFit,
      position,
      setPosition,
      scrollBridge,
      screenSize,
      setMaxContentSize,
      maxSnapPoint
    } = providerProps,
    {
      open,
      controller,
      isHidden
    } = state,
    sheetRef = useRef(null),
    ref = useComposedRefs(forwardedRef, sheetRef),
    animationConfig = (() => {
      const [animationProp, animationPropConfig] = animation ? Array.isArray(animation) ? animation : [animation] : [];
      return animationConfigProp ?? (animationProp ? {
        ...getConfig().animations.animations[animationProp],
        ...animationPropConfig
      } : null);
    })(),
    [isShowingInnerSheet, setIsShowingInnerSheet] = useState(!1),
    shouldHideParentSheet = !isWeb && modal && isShowingInnerSheet,
    parentSheetContext = useContext(SheetInsideSheetContext),
    onInnerSheet = useCallback(hasChild => {
      setIsShowingInnerSheet(hasChild);
    }, []),
    positions = useMemo(() => snapPoints.map(point => getYPositions(snapPointsMode, point, screenSize, frameSize)), [screenSize, frameSize, snapPoints, snapPointsMode]),
    {
      animationDriver
    } = useConfiguration(),
    {
      useAnimatedNumber,
      useAnimatedNumberStyle,
      useAnimatedNumberReaction
    } = animationDriver,
    AnimatedView = animationDriver.View ?? Stack;
  useIsomorphicLayoutEffect(() => {
    if (parentSheetContext && open) return parentSheetContext(!0), () => {
      parentSheetContext(!1);
    };
  }, [parentSheetContext, open]);
  const nextParentContext = useMemo(() => ({
      zIndex
    }), [zIndex]),
    animatedNumber = useAnimatedNumber(hiddenSize),
    at = useRef(hiddenSize);
  useAnimatedNumberReaction({
    value: animatedNumber,
    hostRef: sheetRef
  }, useCallback(value => {
    at.current = value, scrollBridge.paneY = value;
  }, [animationDriver]));
  function stopSpring() {
    animatedNumber.stop(), scrollBridge.onFinishAnimate && (scrollBridge.onFinishAnimate(), scrollBridge.onFinishAnimate = void 0);
  }
  const hasntMeasured = at.current === hiddenSize,
    animateTo = useEvent(position2 => {
      if (frameSize === 0) return;
      let toValue = isHidden || position2 === -1 ? screenSize : positions[position2];
      if (at.current !== toValue) {
        if (at.current = toValue, stopSpring(), hasntMeasured || isHidden) {
          animatedNumber.setValue(screenSize, {
            type: "timing",
            duration: 0
          }, () => {
            isHidden || (toValue = positions[position2], at.current = toValue, animatedNumber.setValue(toValue, {
              type: "spring",
              ...animationConfig
            }));
          });
          return;
        }
        animatedNumber.setValue(toValue, {
          type: "spring",
          ...animationConfig
        });
      }
    });
  useIsomorphicLayoutEffect(() => {
    screenSize && hasntMeasured && animatedNumber.setValue(screenSize, {
      type: "timing",
      duration: 0
    });
  }, [hasntMeasured, screenSize]), useIsomorphicLayoutEffect(() => {
    !frameSize || !screenSize || isHidden || hasntMeasured && !open || animateTo(position);
  }, [isHidden, frameSize, screenSize, open, position]);
  const disableDrag = props.disableDrag ?? controller?.disableDrag,
    themeName = useThemeName(),
    [isDragging, setIsDragging] = useState(!1),
    panResponder = useMemo(() => {
      if (disableDrag || !frameSize || isShowingInnerSheet) return;
      const minY = positions[0];
      scrollBridge.paneMinY = minY;
      let startY = at.current;
      function setPanning(val) {
        setIsDragging(val), SHEET_HIDDEN_STYLESHEET && (val ? SHEET_HIDDEN_STYLESHEET.innerText = ":root * { user-select: none !important; -webkit-user-select: none !important; }" : SHEET_HIDDEN_STYLESHEET.innerText = "");
      }
      const release = ({
          vy,
          dragAt
        }) => {
          isExternalDrag = !1, previouslyScrolling = !1, setPanning(!1);
          const end = dragAt + startY + frameSize * vy * 0.2;
          let closestPoint = 0,
            dist = Number.POSITIVE_INFINITY;
          for (let i = 0; i < positions.length; i++) {
            const position2 = positions[i],
              curDist = end > position2 ? end - position2 : position2 - end;
            curDist < dist && (dist = curDist, closestPoint = i);
          }
          setPosition(closestPoint), animateTo(closestPoint);
        },
        finish = (_e, state2) => {
          release({
            vy: state2.vy,
            dragAt: state2.dy
          });
        };
      let previouslyScrolling = !1;
      const onMoveShouldSet = (e, {
          dy
        }) => {
          if (e.target === providerProps.handleRef.current) return !0;
          const isScrolled = scrollBridge.y !== 0,
            isDraggingUp = dy < 0,
            isNearTop = scrollBridge.paneY - 5 <= scrollBridge.paneMinY;
          return isScrolled ? (previouslyScrolling = !0, !1) : isNearTop && !isScrolled && isDraggingUp && !isWeb ? !1 : Math.abs(dy) > 5;
        },
        grant = () => {
          setPanning(!0), stopSpring(), startY = at.current;
        };
      let isExternalDrag = !1;
      return scrollBridge.drag = dy => {
        isExternalDrag || (isExternalDrag = !0, grant());
        const to = dy + startY;
        animatedNumber.setValue(resisted(to, minY), {
          type: "direct"
        });
      }, scrollBridge.release = release, PanResponder.create({
        onMoveShouldSetPanResponder: onMoveShouldSet,
        onPanResponderGrant: grant,
        onPanResponderMove: (_e, {
          dy
        }) => {
          const toFull = dy + startY,
            to = resisted(toFull, minY);
          animatedNumber.setValue(to, {
            type: "direct"
          });
        },
        onPanResponderEnd: finish,
        onPanResponderTerminate: finish,
        onPanResponderRelease: finish
      });
    }, [disableDrag, isShowingInnerSheet, animateTo, frameSize, positions, setPosition]),
    handleAnimationViewLayout = useCallback(e => {
      const next = Math.min(e.nativeEvent?.layout.height, Dimensions.get("window").height);
      next && setFrameSize(next);
    }, [keyboardIsVisible]),
    handleMaxContentViewLayout = useCallback(e => {
      const next = Math.min(e.nativeEvent?.layout.height, Dimensions.get("window").height);
      next && setMaxContentSize(next);
    }, [keyboardIsVisible]),
    animatedStyle = useAnimatedNumberStyle(animatedNumber, val => {
      "worklet";

      return {
        transform: [{
          translateY: frameSize === 0 ? hiddenSize : val
        }]
      };
    }),
    sizeBeforeKeyboard = useRef(null);
  useEffect(() => {
    if (isWeb || !moveOnKeyboardChange) return;
    const keyboardDidShowListener = Keyboard.addListener("keyboardDidShow", e => {
        sizeBeforeKeyboard.current === null && (sizeBeforeKeyboard.current = animatedNumber.getValue(), animatedNumber.setValue(Math.max(animatedNumber.getValue() - e.endCoordinates.height, 0)));
      }),
      keyboardDidHideListener = Keyboard.addListener("keyboardDidHide", () => {
        sizeBeforeKeyboard.current !== null && (animatedNumber.setValue(sizeBeforeKeyboard.current), sizeBeforeKeyboard.current = null);
      });
    return () => {
      keyboardDidHideListener.remove(), keyboardDidShowListener.remove();
    };
  }, [moveOnKeyboardChange]);
  const [opacity, setOpacity] = useState(open ? 1 : 0);
  open && opacity === 0 && setOpacity(1), useEffect(() => {
    if (!open) {
      const tm = setTimeout(() => {
        setOpacity(0);
      }, 400);
      return () => {
        clearTimeout(tm);
      };
    }
  }, [open]);
  const forcedContentHeight = hasFit ? void 0 : snapPointsMode === "percent" ? `${maxSnapPoint}${isWeb ? "dvh" : "%"}` : maxSnapPoint,
    contents = /* @__PURE__ */jsx(ParentSheetContext.Provider, {
      value: nextParentContext,
      children: /* @__PURE__ */jsxs(SheetProvider, {
        ...providerProps,
        children: [/* @__PURE__ */jsx(AnimatePresence, {
          custom: {
            open
          },
          children: shouldHideParentSheet || !open ? null : overlayComponent
        }), snapPointsMode !== "percent" && /* @__PURE__ */jsx(View, {
          style: {
            opacity: 0,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: "none"
          },
          onLayout: handleMaxContentViewLayout
        }), /* @__PURE__ */jsx(AnimatedView, {
          ref,
          ...panResponder?.panHandlers,
          onLayout: handleAnimationViewLayout,
          ...(!isDragging && {
            // @ts-ignore for CSS driver this is necessary to attach the transition
            animation
          }),
          disableClassName: !0,
          style: [{
            position: "absolute",
            zIndex,
            width: "100%",
            height: forcedContentHeight,
            minHeight: forcedContentHeight,
            opacity,
            ...((shouldHideParentSheet || !open) && {
              pointerEvents: "none"
            })
          }, animatedStyle],
          children: props.children
        })]
      })
    }),
    adaptContext = useContext(AdaptParentContext),
    shouldMountChildren = !!(opacity || !unmountChildrenWhenHidden);
  if (modal) {
    const modalContents = /* @__PURE__ */jsx(Portal, {
      zIndex,
      ...portalProps,
      children: shouldMountChildren && /* @__PURE__ */jsx(ContainerComponent, {
        children: /* @__PURE__ */jsx(Theme, {
          forceClassName: !0,
          name: themeName,
          children: /* @__PURE__ */jsx(AdaptParentContext.Provider, {
            value: adaptContext,
            children: contents
          })
        })
      })
    });
    return isWeb ? modalContents : /* @__PURE__ */jsx(SheetInsideSheetContext.Provider, {
      value: onInnerSheet,
      children: modalContents
    });
  }
  return contents;
}));
function getYPositions(mode, point, screenSize, frameSize) {
  if (!screenSize || !frameSize) return 0;
  if (mode === "mixed") {
    if (typeof point == "number") return screenSize - Math.min(screenSize, Math.max(0, point));
    if (point === "fit") return screenSize - Math.min(screenSize, frameSize);
    if (point.endsWith("%")) {
      const pct2 = Math.min(100, Math.max(0, Number(point.slice(0, -1)))) / 100;
      return Number.isNaN(pct2) ? (console.warn("Invalid snapPoint percentage string"), 0) : Math.round(screenSize - pct2 * screenSize);
    }
    return console.warn("Invalid snapPoint unknown value"), 0;
  }
  if (mode === "fit") return point === 0 ? screenSize : screenSize - Math.min(screenSize, frameSize);
  if (mode === "constant" && typeof point == "number") return screenSize - Math.min(screenSize, Math.max(0, point));
  const pct = Math.min(100, Math.max(0, Number(point))) / 100;
  return Number.isNaN(pct) ? (console.warn("Invalid snapPoint percentage"), 0) : Math.round(screenSize - pct * screenSize);
}
export { SheetImplementationCustom };