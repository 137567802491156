import createLucideIcon from '../createLucideIcon';

const ArrowUpAZ = createLucideIcon('ArrowUpAZ', [
  ['path', { d: 'm3 8 4-4 4 4', key: '11wl7u' }],
  ['path', { d: 'M7 4v16', key: '1glfcx' }],
  ['path', { d: 'M20 8h-5', key: '1vsyxs' }],
  ['path', { d: 'M15 10V6.5a2.5 2.5 0 0 1 5 0V10', key: 'ag13bf' }],
  ['path', { d: 'M15 14h5l-5 6h5', key: 'ur5jdg' }],
]);

export default ArrowUpAZ;
