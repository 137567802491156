import createLucideIcon from '../createLucideIcon';

const AlarmCheck = createLucideIcon('AlarmCheck', [
  ['circle', { cx: '12', cy: '13', r: '8', key: '3y4lt7' }],
  ['path', { d: 'M5 3 2 6', key: '18tl5t' }],
  ['path', { d: 'm22 6-3-3', key: '1opdir' }],
  ['path', { d: 'M6.38 18.7 4 21', key: '17xu3x' }],
  ['path', { d: 'M17.64 18.67 20 21', key: 'kv2oe2' }],
  ['path', { d: 'm9 13 2 2 4-4', key: '6343dt' }],
]);

export default AlarmCheck;
