import createLucideIcon from '../createLucideIcon';

const FileStack = createLucideIcon('FileStack', [
  ['path', { d: 'M16 2v5h5', key: 'kt2in0' }],
  [
    'path',
    {
      d: 'M21 6v6.5c0 .8-.7 1.5-1.5 1.5h-7c-.8 0-1.5-.7-1.5-1.5v-9c0-.8.7-1.5 1.5-1.5H17l4 4z',
      key: '1km23n',
    },
  ],
  ['path', { d: 'M7 8v8.8c0 .3.2.6.4.8.2.2.5.4.8.4H15', key: '16874u' }],
  ['path', { d: 'M3 12v8.8c0 .3.2.6.4.8.2.2.5.4.8.4H11', key: 'k2ox98' }],
]);

export default FileStack;
