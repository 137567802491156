import createLucideIcon from '../createLucideIcon';

const FlipVertical = createLucideIcon('FlipVertical', [
  ['path', { d: 'M21 8V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v3', key: '14bfxa' }],
  ['path', { d: 'M21 16v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-3', key: '14rx03' }],
  ['path', { d: 'M4 12H2', key: 'rhcxmi' }],
  ['path', { d: 'M10 12H8', key: 's88cx1' }],
  ['path', { d: 'M16 12h-2', key: '10asgb' }],
  ['path', { d: 'M22 12h-2', key: '14jgyd' }],
]);

export default FlipVertical;
