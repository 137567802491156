import createLucideIcon from '../createLucideIcon';

const Tv2 = createLucideIcon('Tv2', [
  ['path', { d: 'M7 21h10', key: '1b0cd5' }],
  [
    'rect',
    { width: '20', height: '14', x: '2', y: '3', rx: '2', key: '48i651' },
  ],
]);

export default Tv2;
