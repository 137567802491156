import React from 'react';

import {BuilderElement, LocalizedTextType, NodeType} from '../../../types';
import {Link, LinkProps} from '../../Link';
import {LocalizedText} from '../../LocalizedText';
import {Typography, TypographyProps} from '../../Typography';
import {ViewProps} from '../../View';
import {XStack} from '../../XStack';

export type LinkElementProps = ViewProps &
  LinkProps & {
    value?: LocalizedTextType;
    fontSize?: TypographyProps['fontSize'];
    color?: TypographyProps['color'];
    lineHeight?: TypographyProps['lineHeight'];
    fontWeight?: TypographyProps['fontWeight'];
  };

const defaultProps: LinkElementProps = {
  href: 'https://naseej.com/media-center/news/',
  value: 'Visit Naseej.com',
  fontSize: 20,
  color: '$accent',
  gap: 10,
};

export const Component = ({value, color, gap, fontSize, lineHeight, fontWeight, ...props}: LinkElementProps) => {
  return (
    <XStack alignItems="center" justifyContent="flex-start" {...props}>
      <Link href={props.href} textDecorationLine="none" flexDirection="row" flex={0} gap={gap}>
        <Typography color={color} fontSize={fontSize} lineHeight={lineHeight} fontWeight={fontWeight}>
          <LocalizedText value={value} />
        </Typography>
      </Link>
    </XStack>
  );
};

export const LinkElement: BuilderElement<LinkElementProps> = {
  id: 'LinkElement',
  nodeId: '',
  icon: 'ExternalLink',
  Component,
  props: defaultProps,
  type: NodeType.LINK,
};
