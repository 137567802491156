import createLucideIcon from '../createLucideIcon';

const CloudLightning = createLucideIcon('CloudLightning', [
  [
    'path',
    {
      d: 'M6 16.326A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 .5 8.973',
      key: '1cez44',
    },
  ],
  ['path', { d: 'm13 12-3 5h4l-3 5', key: '1t22er' }],
]);

export default CloudLightning;
