import createLucideIcon from '../createLucideIcon';

const GitBranchPlus = createLucideIcon('GitBranchPlus', [
  ['path', { d: 'M6 3v12', key: 'qpgusn' }],
  ['path', { d: 'M18 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z', key: '1d02ji' }],
  ['path', { d: 'M6 21a3 3 0 1 0 0-6 3 3 0 0 0 0 6z', key: 'chk6ph' }],
  ['path', { d: 'M15 6a9 9 0 0 0-9 9', key: 'or332x' }],
  ['path', { d: 'M18 15v6', key: '9wciyi' }],
  ['path', { d: 'M21 18h-6', key: '139f0c' }],
]);

export default GitBranchPlus;
