import createLucideIcon from '../createLucideIcon';

const TowerControl = createLucideIcon('TowerControl', [
  [
    'path',
    {
      d: 'M18.2 12.27 20 6H4l1.8 6.27a1 1 0 0 0 .95.73h10.5a1 1 0 0 0 .96-.73Z',
      key: '1pledb',
    },
  ],
  ['path', { d: 'M8 13v9', key: 'hmv0ci' }],
  ['path', { d: 'M16 22v-9', key: 'ylnf1u' }],
  ['path', { d: 'm9 6 1 7', key: 'dpdgam' }],
  ['path', { d: 'm15 6-1 7', key: 'ls7zgu' }],
  ['path', { d: 'M12 6V2', key: '1pj48d' }],
  ['path', { d: 'M13 2h-2', key: 'mj6ths' }],
]);

export default TowerControl;
