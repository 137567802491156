import createLucideIcon from '../createLucideIcon';

const BookUp = createLucideIcon('BookUp', [
  [
    'path',
    {
      d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20',
      key: 't4utmx',
    },
  ],
  ['path', { d: 'M12 13V7', key: 'h0r20n' }],
  ['path', { d: 'm9 10 3-3 3 3', key: '11gsxs' }],
]);

export default BookUp;
