import React from 'react';

import {Dimensions, Platform} from 'react-native';
import {ScrollView} from 'tamagui';

import {Button} from '../Button';
import {Dialog} from '../Dialog';
import {IconView} from '../IconView';
import {Typography} from '../Typography';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {SideBarProps} from './types';

const screenHeight = Dimensions.get('window').height;

export function SideBar({
  title,
  onClose,
  open,
  header,
  content,
  footer = <Button>Apply Filters</Button>,
  contentProps,
  ScrollViewProps,
  ...props
}: SideBarProps) {
  const sideBarHeader = (
    <XStack
      alignItems="center"
      justifyContent="space-between"
      height="$56"
      padding="$16"
      backgroundColor="$neutral-200">
      <XStack alignItems="center" gap="$1">
        <Typography fontWeight="600">{title}</Typography>
      </XStack>
      {onClose && (
        <Button variant="empty" icon={<IconView icon="X" size={19} color="$neutral-600" />} onPress={() => onClose()} />
      )}
    </XStack>
  );

  if (Platform.OS !== 'web') {
    return (
      <Dialog
        modelHeight="full"
        open={open}
        contentProps={{padding: '$16'}}
        onClose={() => onClose?.()}
        header={header || sideBarHeader}
        content={content}
        footer={<YStack padding="$16">{footer}</YStack>}
      />
    );
  }

  return (
    <YStack
      display={open ? 'flex' : 'none'}
      width={328}
      borderWidth="$0.5"
      borderColor="$border"
      maxHeight={screenHeight}
      {...props}>
      {header || sideBarHeader}

      <ScrollView {...ScrollViewProps}>
        <YStack flex={1} padding="$16" overflow="scroll" {...contentProps}>
          {content}
        </YStack>
      </ScrollView>

      {footer && <YStack padding="$16">{footer}</YStack>}
    </YStack>
  );
}
