import { useIsPresent } from "@tamagui/animate-presence";
import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import { Stack, Theme, createStyledContext, styled, useConfiguration, useEvent, useThemeName } from "@tamagui/core";
import { Dismissable } from "@tamagui/dismissable";
import { composeEventHandlers } from "@tamagui/helpers";
import { PortalItem } from "@tamagui/portal";
import { ThemeableStack } from "@tamagui/stacks";
import * as React from "react";
import { PanResponder } from "react-native-web";
import { TOAST_CONTEXT, TOAST_NAME } from "./constants.mjs";
import { ToastAnnounce } from "./ToastAnnounce.mjs";
import { Collection, useToastProviderContext } from "./ToastProvider.mjs";
import { VIEWPORT_PAUSE, VIEWPORT_RESUME } from "./ToastViewport.mjs";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
const ToastImplFrame = styled(ThemeableStack, {
    name: "ToastImpl",
    focusable: !0,
    variants: {
      unstyled: {
        false: {
          focusStyle: {
            outlineStyle: "solid",
            outlineWidth: 2,
            outlineColor: "$outlineColor"
          },
          backgroundColor: "$color6",
          borderRadius: "$10",
          paddingHorizontal: "$5",
          paddingVertical: "$2",
          marginHorizontal: "auto",
          marginVertical: "$1"
        }
      }
    },
    defaultVariants: {
      unstyled: process.env.TAMAGUI_HEADLESS === "1"
    }
  }),
  {
    Provider: ToastInteractiveProvider,
    useStyledContext: useToastInteractiveContext
  } = createStyledContext({
    onClose() {}
  }),
  ToastImpl = React.forwardRef((props, forwardedRef) => {
    const {
        __scopeToast,
        type = "foreground",
        duration: durationProp,
        open,
        onClose,
        onEscapeKeyDown,
        onPause,
        onResume,
        onSwipeStart,
        onSwipeMove,
        onSwipeCancel,
        onSwipeEnd,
        viewportName = "default",
        ...toastProps
      } = props,
      isPresent = useIsPresent(),
      context = useToastProviderContext(__scopeToast),
      [node, setNode] = React.useState(null),
      composedRefs = useComposedRefs(forwardedRef, node2 => setNode(node2)),
      duration = durationProp || context.duration,
      closeTimerStartTimeRef = React.useRef(0),
      closeTimerRemainingTimeRef = React.useRef(duration),
      closeTimerRef = React.useRef(0),
      {
        onToastAdd,
        onToastRemove
      } = context,
      viewport = React.useMemo(() => context.viewports[viewportName], [context.viewports, viewportName]),
      handleClose = useEvent(() => {
        isPresent && (isWeb && node?.contains(document.activeElement) && viewport?.focus(), onClose());
      }),
      startTimer = React.useCallback(duration2 => {
        !duration2 || duration2 === Number.POSITIVE_INFINITY || (clearTimeout(closeTimerRef.current), closeTimerStartTimeRef.current = ( /* @__PURE__ */new Date()).getTime(), closeTimerRef.current = setTimeout(handleClose, duration2));
      }, [handleClose]),
      handleResume = React.useCallback(() => {
        startTimer(closeTimerRemainingTimeRef.current), onResume?.();
      }, [onResume, startTimer]),
      handlePause = React.useCallback(() => {
        const elapsedTime = ( /* @__PURE__ */new Date()).getTime() - closeTimerStartTimeRef.current;
        closeTimerRemainingTimeRef.current = closeTimerRemainingTimeRef.current - elapsedTime, window.clearTimeout(closeTimerRef.current), onPause?.();
      }, [onPause]);
    React.useEffect(() => {
      if (isWeb && viewport) return viewport.addEventListener(VIEWPORT_PAUSE, handlePause), viewport.addEventListener(VIEWPORT_RESUME, handleResume), () => {
        viewport.removeEventListener(VIEWPORT_PAUSE, handlePause), viewport.removeEventListener(VIEWPORT_RESUME, handleResume);
      };
    }, [viewport, duration, onPause, onResume, startTimer]), React.useEffect(() => {
      open && !context.isClosePausedRef.current && startTimer(duration);
    }, [open, duration, context.isClosePausedRef, startTimer]), React.useEffect(() => (onToastAdd(), () => onToastRemove()), [onToastAdd, onToastRemove]);
    const announceTextContent = React.useMemo(() => isWeb && node ? getAnnounceTextContent(node) : null, [node]),
      isHorizontalSwipe = ["left", "right", "horizontal"].includes(context.swipeDirection),
      {
        animationDriver
      } = useConfiguration();
    if (!animationDriver) throw new Error("Must set animations in tamagui.config.ts");
    const {
        useAnimatedNumber,
        useAnimatedNumberStyle
      } = animationDriver,
      animatedNumber = useAnimatedNumber(0),
      AnimatedView = animationDriver.NumberView ?? animationDriver.View ?? Stack,
      animatedStyles = useAnimatedNumberStyle(animatedNumber, val => {
        "worklet";

        return {
          transform: [isHorizontalSwipe ? {
            translateX: val
          } : {
            translateY: val
          }]
        };
      }),
      panResponder = React.useMemo(() => PanResponder.create({
        onMoveShouldSetPanResponder: (e, gesture) => shouldGrantGestureMove(context.swipeDirection, gesture) ? (onSwipeStart?.(e), !0) : !1,
        onPanResponderGrant: e => {
          isWeb || handlePause?.();
        },
        onPanResponderMove: (e, gesture) => {
          const {
              x,
              y
            } = getGestureDistance(context.swipeDirection, gesture),
            delta = {
              x,
              y
            };
          animatedNumber.setValue(isHorizontalSwipe ? x : y, {
            type: "direct"
          }), isDeltaInDirection(delta, context.swipeDirection, context.swipeThreshold) && onSwipeEnd?.(e), onSwipeMove?.(e);
        },
        onPanResponderEnd: (e, {
          dx,
          dy
        }) => {
          isDeltaInDirection({
            x: dx,
            y: dy
          }, context.swipeDirection, context.swipeThreshold) || (isWeb || handleResume?.(), onSwipeCancel?.(e), animatedNumber.setValue(0, {
            type: "spring"
          }));
        }
      }), [handlePause, handleResume]),
      themeName = useThemeName();
    return /* @__PURE__ */jsxs(Fragment, {
      children: [announceTextContent && /* @__PURE__ */jsx(ToastAnnounce, {
        __scopeToast,
        role: "status",
        "aria-live": type === "foreground" ? "assertive" : "polite",
        "aria-atomic": !0,
        children: announceTextContent
      }), /* @__PURE__ */jsx(PortalItem, {
        hostName: viewportName ?? "default",
        children: /* @__PURE__ */jsx(ToastInteractiveProvider, {
          scope: __scopeToast,
          onClose: () => {
            handleClose();
          },
          children: /* @__PURE__ */jsx(Dismissable, {
            onEscapeKeyDown: composeEventHandlers(onEscapeKeyDown, () => {
              context.isFocusedToastEscapeKeyDownRef.current || handleClose(), context.isFocusedToastEscapeKeyDownRef.current = !1;
            }),
            children: /* @__PURE__ */jsx(Theme, {
              forceClassName: !0,
              name: themeName,
              children: /* @__PURE__ */jsx(AnimatedView, {
                ...panResponder?.panHandlers,
                style: [{
                  margin: "auto"
                }, animatedStyles],
                children: /* @__PURE__ */jsx(Collection.ItemSlot, {
                  __scopeCollection: __scopeToast || TOAST_CONTEXT,
                  children: /* @__PURE__ */jsx(ToastImplFrame, {
                    role: "status",
                    "aria-live": "off",
                    "aria-atomic": !0,
                    "data-state": open ? "open" : "closed",
                    "data-swipe-direction": context.swipeDirection,
                    pointerEvents: "auto",
                    touchAction: "none",
                    userSelect: "none",
                    ...toastProps,
                    ref: composedRefs,
                    ...(isWeb && {
                      onKeyDown: composeEventHandlers(props.onKeyDown, event => {
                        event.key === "Escape" && (onEscapeKeyDown?.(event), onEscapeKeyDown?.(event), event.defaultPrevented || (context.isFocusedToastEscapeKeyDownRef.current = !0, handleClose()));
                      })
                    })
                  })
                })
              })
            })
          })
        }, props.id)
      })]
    });
  });
ToastImpl.propTypes = {
  type(props) {
    if (props.type && !["foreground", "background"].includes(props.type)) {
      const error = `Invalid prop \`type\` supplied to \`${TOAST_NAME}\`. Expected \`foreground | background\`.`;
      return new Error(error);
    }
    return null;
  }
};
const isDeltaInDirection = (delta, direction, threshold = 0) => {
  const deltaX = Math.abs(delta.x),
    deltaY = Math.abs(delta.y),
    isDeltaX = deltaX > deltaY;
  return direction === "left" || direction === "right" || direction === "horizontal" ? isDeltaX && deltaX > threshold : !isDeltaX && deltaY > threshold;
};
function getAnnounceTextContent(container) {
  if (!isWeb) return "";
  const textContent = [];
  return Array.from(container.childNodes).forEach(node => {
    if (node.nodeType === node.TEXT_NODE && node.textContent && textContent.push(node.textContent), isHTMLElement(node)) {
      const isHidden = node.ariaHidden || node.hidden || node.style.display === "none",
        isExcluded = node.dataset.toastAnnounceExclude === "";
      if (!isHidden) if (isExcluded) {
        const altText = node.dataset.toastAnnounceAlt;
        altText && textContent.push(altText);
      } else textContent.push(...getAnnounceTextContent(node));
    }
  }), textContent;
}
function isHTMLElement(node) {
  return node.nodeType === node.ELEMENT_NODE;
}
const GESTURE_GRANT_THRESHOLD = 10,
  shouldGrantGestureMove = (dir, {
    dx,
    dy
  }) => (dir === "horizontal" || dir === "left") && dx < -GESTURE_GRANT_THRESHOLD || (dir === "horizontal" || dir === "right") && dx > GESTURE_GRANT_THRESHOLD || (dir === "vertical" || dir === "up") && dy > -GESTURE_GRANT_THRESHOLD || (dir === "vertical" || dir === "down") && dy < GESTURE_GRANT_THRESHOLD,
  getGestureDistance = (dir, {
    dx,
    dy
  }) => {
    let y = 0,
      x = 0;
    return dir === "horizontal" ? x = dx : dir === "left" ? x = Math.min(0, dx) : dir === "right" ? x = Math.max(0, dx) : dir === "vertical" ? y = dy : dir === "up" ? y = Math.min(0, dy) : dir === "down" && (y = Math.max(0, dy)), {
      x,
      y
    };
  };
export { ToastImpl, ToastImplFrame, useToastInteractiveContext };