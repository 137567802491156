import createLucideIcon from '../createLucideIcon';

const LightbulbOff = createLucideIcon('LightbulbOff', [
  [
    'path',
    { d: 'M16.8 11.2c.8-.9 1.2-2 1.2-3.2a6 6 0 0 0-9.3-5', key: '1fkcox' },
  ],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  [
    'path',
    {
      d: 'M6.3 6.3a4.67 4.67 0 0 0 1.2 5.2c.7.7 1.3 1.5 1.5 2.5',
      key: '10m8kw',
    },
  ],
  ['path', { d: 'M9 18h6', key: 'x1upvd' }],
  ['path', { d: 'M10 22h4', key: 'ceow96' }],
]);

export default LightbulbOff;
