import { getBoundingClientRect } from "./getBoundingClientRect.mjs";
const getRect = node => {
  const rect = getBoundingClientRect(node);
  if (!rect) return;
  const {
    x,
    y,
    top,
    left
  } = rect;
  return {
    x,
    y,
    width: node.offsetWidth,
    height: node.offsetHeight,
    top,
    left
  };
};
export { getRect };