import createLucideIcon from '../createLucideIcon';

const Sparkles = createLucideIcon('Sparkles', [
  [
    'path',
    {
      d: 'm12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z',
      key: '17u4zn',
    },
  ],
  ['path', { d: 'M5 3v4', key: 'bklmnn' }],
  ['path', { d: 'M19 17v4', key: 'iiml17' }],
  ['path', { d: 'M3 5h4', key: 'nem4j1' }],
  ['path', { d: 'M17 19h4', key: 'lbex7p' }],
]);

export default Sparkles;
