import createLucideIcon from '../createLucideIcon';

const Scale3d = createLucideIcon('Scale3d', [
  ['circle', { cx: '19', cy: '19', r: '2', key: '17f5cg' }],
  ['circle', { cx: '5', cy: '5', r: '2', key: '1gwv83' }],
  ['path', { d: 'M5 7v12h12', key: 'vtaa4r' }],
  ['path', { d: 'm5 19 6-6', key: 'jh6hbb' }],
]);

export default Scale3d;
