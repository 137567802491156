import createLucideIcon from '../createLucideIcon';

const Tornado = createLucideIcon('Tornado', [
  ['path', { d: 'M21 4H3', key: '1hwok0' }],
  ['path', { d: 'M18 8H6', key: '41n648' }],
  ['path', { d: 'M19 12H9', key: '1g4lpz' }],
  ['path', { d: 'M16 16h-6', key: '1j5d54' }],
  ['path', { d: 'M11 20H9', key: '39obr8' }],
]);

export default Tornado;
