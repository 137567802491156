import { AnimatePresence } from "@tamagui/animate-presence";
import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import { styled } from "@tamagui/core";
import { PortalHost } from "@tamagui/portal";
import { YStack } from "@tamagui/stacks";
import { VisuallyHidden } from "@tamagui/visually-hidden";
import * as React from "react";
import { TOAST_CONTEXT } from "./constants.mjs";
import { ToastPortal } from "./ToastPortal.mjs";
import { Collection, useCollection, useToastProviderContext } from "./ToastProvider.mjs";
import { jsx, jsxs } from "react/jsx-runtime";
const VIEWPORT_NAME = "ToastViewport",
  VIEWPORT_DEFAULT_HOTKEY = ["F8"],
  VIEWPORT_PAUSE = "toast.viewportPause",
  VIEWPORT_RESUME = "toast.viewportResume",
  ToastViewportWrapperFrame = styled(YStack, {
    name: "ViewportWrapper",
    variants: {
      unstyled: {
        false: {
          pointerEvents: "box-none",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          position: isWeb ? "fixed" : "absolute",
          maxWidth: "100%",
          tabIndex: 0,
          zIndex: 1e5
        }
      }
    },
    defaultVariants: {
      unstyled: process.env.TAMAGUI_HEADLESS === "1"
    }
  }),
  ToastViewportFrame = styled(YStack, {
    name: VIEWPORT_NAME,
    variants: {
      unstyled: {
        false: {
          pointerEvents: "box-none",
          position: isWeb ? "fixed" : "absolute",
          maxWidth: "100%"
        }
      }
    },
    defaultVariants: {
      unstyled: process.env.TAMAGUI_HEADLESS === "1"
    }
  }),
  ToastViewport = React.memo(React.forwardRef((props, forwardedRef) => {
    const {
        __scopeToast,
        hotkey = VIEWPORT_DEFAULT_HOTKEY,
        label = "Notifications ({hotkey})",
        name = "default",
        multipleToasts,
        zIndex,
        portalToRoot,
        ...viewportProps
      } = props,
      context = useToastProviderContext(__scopeToast),
      getItems = useCollection(__scopeToast || TOAST_CONTEXT),
      headFocusProxyRef = React.useRef(null),
      tailFocusProxyRef = React.useRef(null),
      wrapperRef = React.useRef(null),
      ref = React.useRef(null),
      onViewportChange = React.useCallback(el => {
        context.viewports[name] !== el && context.onViewportChange(name, el);
      }, [name, context.viewports]),
      composedRefs = useComposedRefs(forwardedRef, ref, onViewportChange),
      hotkeyLabel = hotkey.join("+").replace(/Key/g, "").replace(/Digit/g, ""),
      hasToasts = context.toastCount > 0;
    React.useEffect(() => {
      if (!isWeb || context.toastCount === 0) return;
      const handleKeyDown = event => {
        hotkey.every(key => event[key] || event.code === key) && ref.current?.focus();
      };
      return document.addEventListener("keydown", handleKeyDown), () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [hotkey, context.toastCount]), React.useEffect(() => {
      if (!isWeb || context.toastCount === 0) return;
      const wrapper = wrapperRef.current,
        viewport = ref.current;
      if (hasToasts && wrapper && viewport) {
        const handlePause = () => {
            if (!context.isClosePausedRef.current) {
              const pauseEvent = new CustomEvent(VIEWPORT_PAUSE);
              viewport.dispatchEvent(pauseEvent), context.isClosePausedRef.current = !0;
            }
          },
          handleResume = () => {
            if (context.isClosePausedRef.current) {
              const resumeEvent = new CustomEvent(VIEWPORT_RESUME);
              viewport.dispatchEvent(resumeEvent), context.isClosePausedRef.current = !1;
            }
          },
          handleFocusOutResume = event => {
            !wrapper.contains(event.relatedTarget) && handleResume();
          },
          handlePointerLeaveResume = () => {
            wrapper.contains(document.activeElement) || handleResume();
          };
        return wrapper.addEventListener("focusin", handlePause), wrapper.addEventListener("focusout", handleFocusOutResume), wrapper.addEventListener("pointermove", handlePause), wrapper.addEventListener("pointerleave", handlePointerLeaveResume), window.addEventListener("blur", handlePause), window.addEventListener("focus", handleResume), () => {
          wrapper.removeEventListener("focusin", handlePause), wrapper.removeEventListener("focusout", handleFocusOutResume), wrapper.removeEventListener("pointermove", handlePause), wrapper.removeEventListener("pointerleave", handlePointerLeaveResume), window.removeEventListener("blur", handlePause), window.removeEventListener("focus", handleResume);
        };
      }
    }, [hasToasts, context.isClosePausedRef, context.toastCount]);
    const getSortedTabbableCandidates = React.useCallback(({
      tabbingDirection
    }) => {
      const tabbableCandidates = getItems().map(toastItem => {
        const toastNode = toastItem.ref.current,
          toastTabbableCandidates = [toastNode, ...getTabbableCandidates(toastNode)];
        return tabbingDirection === "forwards" ? toastTabbableCandidates : toastTabbableCandidates.reverse();
      });
      return (tabbingDirection === "forwards" ? tabbableCandidates.reverse() : tabbableCandidates).flat();
    }, [getItems]);
    React.useEffect(() => {
      if (!isWeb || context.toastCount === 0) return;
      const viewport = ref.current;
      if (viewport) {
        const handleKeyDown = event => {
          const isMetaKey = event.altKey || event.ctrlKey || event.metaKey;
          if (event.key === "Tab" && !isMetaKey) {
            const focusedElement = document.activeElement,
              isTabbingBackwards = event.shiftKey;
            if (event.target === viewport && isTabbingBackwards) {
              headFocusProxyRef.current?.focus();
              return;
            }
            const sortedCandidates = getSortedTabbableCandidates({
                tabbingDirection: isTabbingBackwards ? "backwards" : "forwards"
              }),
              index = sortedCandidates.findIndex(candidate => candidate === focusedElement);
            focusFirst(sortedCandidates.slice(index + 1)) ? event.preventDefault() : isTabbingBackwards ?
            // @ts-ignore ali TODO type
            headFocusProxyRef.current?.focus() :
            // @ts-ignore ali TODO type
            tailFocusProxyRef.current?.focus();
          }
        };
        return viewport.addEventListener("keydown", handleKeyDown), () => viewport.removeEventListener("keydown", handleKeyDown);
      }
    }, [getItems, getSortedTabbableCandidates, context.toastCount]);
    const contents = /* @__PURE__ */jsxs(ToastViewportWrapperFrame, {
      ref: wrapperRef,
      role: "region",
      "aria-label": label.replace("{hotkey}", hotkeyLabel),
      tabIndex: -1,
      children: [hasToasts && /* @__PURE__ */jsx(FocusProxy, {
        __scopeToast,
        viewportName: name,
        ref: headFocusProxyRef,
        onFocusFromOutsideViewport: () => {
          const tabbableCandidates = getSortedTabbableCandidates({
            tabbingDirection: "forwards"
          });
          focusFirst(tabbableCandidates);
        }
      }), /* @__PURE__ */jsx(Collection.Slot, {
        __scopeCollection: __scopeToast || TOAST_CONTEXT,
        children: /* @__PURE__ */jsx(ToastViewportFrame, {
          focusable: context.toastCount > 0,
          ref: composedRefs,
          ...viewportProps,
          children: /* @__PURE__ */jsx(PortalHost, {
            render: children => /* @__PURE__ */jsx(AnimatePresence, {
              exitBeforeEnter: !multipleToasts,
              children
            }),
            name: name ?? "default"
          })
        })
      }), hasToasts && /* @__PURE__ */jsx(FocusProxy, {
        __scopeToast,
        viewportName: name,
        ref: tailFocusProxyRef,
        onFocusFromOutsideViewport: () => {
          const tabbableCandidates = getSortedTabbableCandidates({
            tabbingDirection: "backwards"
          });
          focusFirst(tabbableCandidates);
        }
      })]
    });
    return portalToRoot ? /* @__PURE__ */jsx(ToastPortal, {
      ...(typeof zIndex == "number" ? {
        zIndex
      } : {}),
      children: contents
    }) : contents;
  }));
ToastViewport.displayName = VIEWPORT_NAME;
const FOCUS_PROXY_NAME = "ToastFocusProxy",
  FocusProxy = React.forwardRef((props, forwardedRef) => {
    const {
        __scopeToast,
        onFocusFromOutsideViewport,
        viewportName,
        ...proxyProps
      } = props,
      viewport = useToastProviderContext(__scopeToast).viewports[viewportName];
    return /* @__PURE__ */jsx(VisuallyHidden, {
      "aria-hidden": !0,
      tabIndex: 0,
      ...proxyProps,
      ref: forwardedRef,
      position: isWeb ? "fixed" : "absolute",
      onFocus: event => {
        if (!isWeb) return;
        const prevFocusedElement = event.relatedTarget;
        !viewport?.contains(prevFocusedElement) && onFocusFromOutsideViewport();
      }
    });
  });
FocusProxy.displayName = FOCUS_PROXY_NAME;
function focusFirst(candidates) {
  if (!isWeb) return;
  const previouslyFocusedElement = document.activeElement;
  return candidates.some(candidate => candidate === previouslyFocusedElement ? !0 : (candidate.focus(), document.activeElement !== previouslyFocusedElement));
}
function getTabbableCandidates(container) {
  if (!isWeb) return [];
  const containerHtml = container,
    nodes = [],
    walker = document.createTreeWalker(containerHtml, NodeFilter.SHOW_ELEMENT, {
      acceptNode: node => {
        const isHiddenInput = node.tagName === "INPUT" && node.type === "hidden";
        return node.disabled || node.hidden || isHiddenInput ? NodeFilter.FILTER_SKIP : node.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
      }
    });
  for (; walker.nextNode();) nodes.push(walker.currentNode);
  return nodes;
}
export { ToastViewport, VIEWPORT_DEFAULT_HOTKEY, VIEWPORT_PAUSE, VIEWPORT_RESUME };