import React, {ReactNode} from 'react';

import {ViewProps} from '../View';
import {XStack} from '../XStack';

export interface BreadcrumbTypes extends ViewProps {
  prefix?: ReactNode;
  children: ReactNode;
}

export const Breadcrumb = ({prefix, children, ...props}: BreadcrumbTypes) => {
  return (
    <XStack
      borderBottomColor="$border"
      borderBottomWidth="$0.5"
      paddingHorizontal="$32"
      $sm={{paddingHorizontal: '$16'}}
      height="$3.5"
      gap="$6"
      backgroundColor="$neutral-1100"
      alignItems="center"
      {...props}>
      {prefix}
      {children}
    </XStack>
  );
};
