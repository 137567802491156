import createLucideIcon from '../createLucideIcon';

const Maximize = createLucideIcon('Maximize', [
  ['path', { d: 'M8 3H5a2 2 0 0 0-2 2v3', key: '1dcmit' }],
  ['path', { d: 'M21 8V5a2 2 0 0 0-2-2h-3', key: '1e4gt3' }],
  ['path', { d: 'M3 16v3a2 2 0 0 0 2 2h3', key: 'wsl5sc' }],
  ['path', { d: 'M16 21h3a2 2 0 0 0 2-2v-3', key: '18trek' }],
]);

export default Maximize;
