import createLucideIcon from '../createLucideIcon';

const FileSearch = createLucideIcon('FileSearch', [
  [
    'path',
    { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v3', key: 'am10z3' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M5 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6z', key: 'ychnub' }],
  ['path', { d: 'm9 18-1.5-1.5', key: '1j6qii' }],
]);

export default FileSearch;
