import { useComposedRefs } from "@tamagui/compose-refs";
import { composeEventHandlers } from "@tamagui/helpers";
import { useEscapeKeydown } from "@tamagui/use-escape-keydown";
import { useEvent } from "@tamagui/use-event";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { jsx } from "react/jsx-runtime";
function dispatchDiscreteCustomEvent(target, event) {
  target && ReactDOM.flushSync(() => target.dispatchEvent(event));
}
const DISMISSABLE_LAYER_NAME = "Dismissable",
  CONTEXT_UPDATE = "dismissable.update",
  POINTER_DOWN_OUTSIDE = "dismissable.pointerDownOutside",
  FOCUS_OUTSIDE = "dismissable.focusOutside";
let originalBodyPointerEvents;
const DismissableContext = React.createContext({
    layers: /* @__PURE__ */new Set(),
    layersWithOutsidePointerEventsDisabled: /* @__PURE__ */new Set(),
    branches: /* @__PURE__ */new Set()
  }),
  Dismissable = React.forwardRef((props, forwardedRef) => {
    const {
        disableOutsidePointerEvents = !1,
        forceUnmount,
        onEscapeKeyDown,
        onPointerDownOutside,
        onFocusOutside,
        onInteractOutside,
        onDismiss,
        ...layerProps
      } = props,
      context = React.useContext(DismissableContext),
      [node, setNode] = React.useState(null),
      [, force] = React.useState({}),
      composedRefs = useComposedRefs(forwardedRef, node2 => setNode(node2)),
      layers = Array.from(context.layers),
      [highestLayerWithOutsidePointerEventsDisabled] = [...context.layersWithOutsidePointerEventsDisabled].slice(-1),
      highestLayerWithOutsidePointerEventsDisabledIndex = layers.indexOf(highestLayerWithOutsidePointerEventsDisabled),
      index = node ? layers.indexOf(node) : -1,
      isBodyPointerEventsDisabled = context.layersWithOutsidePointerEventsDisabled.size > 0,
      isPointerEventsEnabled = index >= highestLayerWithOutsidePointerEventsDisabledIndex,
      pointerDownOutside = usePointerDownOutside(event => {
        const target = event.target,
          isPointerDownOnBranch = [...context.branches].some(branch => branch.contains(target));
        !isPointerEventsEnabled || isPointerDownOnBranch || (onPointerDownOutside?.(event), onInteractOutside?.(event), event.defaultPrevented || onDismiss?.());
      }),
      focusOutside = useFocusOutside(event => {
        const target = event.target;
        [...context.branches].some(branch => branch.contains(target)) || (onFocusOutside?.(event), onInteractOutside?.(event), event.defaultPrevented || onDismiss?.());
      });
    return useEscapeKeydown(event => {
      index === context.layers.size - 1 && (onEscapeKeyDown?.(event), !event.defaultPrevented && onDismiss && (event.preventDefault(), onDismiss()));
    }), React.useEffect(() => {
      if (node) return disableOutsidePointerEvents && (context.layersWithOutsidePointerEventsDisabled.size === 0 && (originalBodyPointerEvents = document.body.style.pointerEvents, document.body.style.pointerEvents = "none"), context.layersWithOutsidePointerEventsDisabled.add(node)), context.layers.add(node), dispatchUpdate(), () => {
        disableOutsidePointerEvents && context.layersWithOutsidePointerEventsDisabled.size === 1 && (document.body.style.pointerEvents = originalBodyPointerEvents);
      };
    }, [node, disableOutsidePointerEvents, context]), React.useEffect(() => {
      if (!forceUnmount) return () => {
        node && (context.layers.delete(node), context.layersWithOutsidePointerEventsDisabled.delete(node), dispatchUpdate());
      };
    }, [node, context, forceUnmount]), React.useEffect(() => {
      const handleUpdate = () => {
        force({});
      };
      return document.addEventListener(CONTEXT_UPDATE, handleUpdate), () => document.removeEventListener(CONTEXT_UPDATE, handleUpdate);
    }, []), /* @__PURE__ */jsx("div", {
      ...layerProps,
      ref: composedRefs,
      style: {
        display: "contents",
        pointerEvents: isBodyPointerEventsDisabled ? isPointerEventsEnabled ? "auto" : "none" : void 0,
        // @ts-ignore
        ...props.style
      },
      onFocusCapture: composeEventHandlers(props.onFocusCapture, focusOutside.onFocusCapture),
      onBlurCapture: composeEventHandlers(props.onBlurCapture, focusOutside.onBlurCapture),
      onPointerDownCapture: composeEventHandlers(props.onPointerDownCapture, pointerDownOutside.onPointerDownCapture)
    });
  });
Dismissable.displayName = DISMISSABLE_LAYER_NAME;
const BRANCH_NAME = "DismissableBranch",
  DismissableBranch = React.forwardRef((props, forwardedRef) => {
    const context = React.useContext(DismissableContext),
      ref = React.useRef(null),
      composedRefs = useComposedRefs(forwardedRef, ref);
    return React.useEffect(() => {
      const node = ref.current;
      if (node) return context.branches.add(node), () => {
        context.branches.delete(node);
      };
    }, [context.branches]), /* @__PURE__ */jsx("div", {
      style: {
        display: "contents"
      },
      ...props,
      ref: composedRefs
    });
  });
DismissableBranch.displayName = BRANCH_NAME;
function usePointerDownOutside(onPointerDownOutside) {
  const handlePointerDownOutside = useEvent(onPointerDownOutside),
    isPointerInsideReactTreeRef = React.useRef(!1),
    handleClickRef = React.useRef(() => {});
  return React.useEffect(() => {
    const handlePointerDown = event => {
        if (event.target && !isPointerInsideReactTreeRef.current) {
          let handleAndDispatchPointerDownOutsideEvent = function () {
            handleAndDispatchCustomEvent(POINTER_DOWN_OUTSIDE, handlePointerDownOutside, eventDetail, {
              discrete: !0
            });
          };
          const eventDetail = {
            originalEvent: event
          };
          event.pointerType === "touch" ? (document.removeEventListener("click", handleClickRef.current), handleClickRef.current = handleAndDispatchPointerDownOutsideEvent, document.addEventListener("click", handleClickRef.current, {
            once: !0
          })) : handleAndDispatchPointerDownOutsideEvent();
        }
        isPointerInsideReactTreeRef.current = !1;
      },
      timerId = setTimeout(() => {
        document.addEventListener("pointerdown", handlePointerDown);
      }, 0);
    return () => {
      window.clearTimeout(timerId), document.removeEventListener("pointerdown", handlePointerDown), document.removeEventListener("click", handleClickRef.current);
    };
  }, [handlePointerDownOutside]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => {
      isPointerInsideReactTreeRef.current = !0;
    }
  };
}
function useFocusOutside(onFocusOutside) {
  const handleFocusOutside = useEvent(onFocusOutside),
    isFocusInsideReactTreeRef = React.useRef(!1);
  return React.useEffect(() => {
    const handleFocus = event => {
      event.target && !isFocusInsideReactTreeRef.current && handleAndDispatchCustomEvent(FOCUS_OUTSIDE, handleFocusOutside, {
        originalEvent: event
      }, {
        discrete: !1
      });
    };
    return document.addEventListener("focusin", handleFocus), () => document.removeEventListener("focusin", handleFocus);
  }, [handleFocusOutside]), {
    onFocusCapture: () => {
      isFocusInsideReactTreeRef.current = !0;
    },
    onBlurCapture: () => {
      isFocusInsideReactTreeRef.current = !1;
    }
  };
}
function dispatchUpdate() {
  const event = new CustomEvent(CONTEXT_UPDATE);
  document.dispatchEvent(event);
}
function handleAndDispatchCustomEvent(name, handler, detail, {
  discrete
}) {
  const target = detail.originalEvent.target,
    event = new CustomEvent(name, {
      bubbles: !1,
      cancelable: !0,
      detail
    });
  handler && target.addEventListener(name, handler, {
    once: !0
  }), discrete ? dispatchDiscreteCustomEvent(target, event) : target.dispatchEvent(event);
}
export { Dismissable, DismissableBranch, dispatchDiscreteCustomEvent };