import createLucideIcon from '../createLucideIcon';

const Bed = createLucideIcon('Bed', [
  ['path', { d: 'M2 4v16', key: 'vw9hq8' }],
  ['path', { d: 'M2 8h18a2 2 0 0 1 2 2v10', key: '1dgv2r' }],
  ['path', { d: 'M2 17h20', key: '18nfp3' }],
  ['path', { d: 'M6 8v9', key: '1yriud' }],
]);

export default Bed;
