import createLucideIcon from '../createLucideIcon';

const FileMinus2 = createLucideIcon('FileMinus2', [
  [
    'path',
    { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: '702lig' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M3 15h6', key: '4e2qda' }],
]);

export default FileMinus2;
