import {ReactNode} from 'react';

import {EventContentArg} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import {Agenda} from 'react-native-calendars';
import {AgendaEntry} from 'react-native-calendars/src/types';
import {ColorTokens} from 'tamagui';

import {Option} from '../../types';

export type CalendarEvent = {
  id: string;
  title: string;
  type: string;
  appletId?: string;
  date: string;
  description?: string;
  redirectUrl?: string;
  startTime?: Date | string;
  endTime?: Date | string;
  start?: Date | string;
  end?: Date | string;
  color?: ColorTokens; // base color to derive colors from
  colors?: {
    main: string;
    light: string;
    dark: string;
  };
  extraInfo?: Option[];
};

export type CalendarEventDots = {
  key?: string;
  color: string;
  selectedDotColor?: string;
};

export type AgendaEventItem = AgendaEntry & CalendarEvent;

export type EventsByDateType = {
  [date: string]: AgendaEventItem[];
};

export type CalendarProps = {
  events: CalendarEvent[];
  calendarHeader?: ReactNode;
  calendarRef?: React.RefObject<FullCalendar | null> | React.RefObject<Agenda | null>;
  eventContent?: (event: EventContentArg | AgendaEventItem, calendarView?: CalendarViews) => ReactNode;
  weekStartDay?: 0 | 1;
  noTitle?: boolean;
};

export type CalendarState = {
  date: Date;
  view: CalendarViewsType;
};

export const CalendarViews = {
  day: 'timeGridDay',
  week: 'timeGridWeek',
  month: 'dayGridMonth',
} as const;

export const CalendarDateFormat = {
  timeGridDay: 'MMMM D, YYYY',
  timeGridWeek: 'MMMM D, YYYY',
  dayGridMonth: 'MMMM YYYY',
} as const;

export type CalendarViewsType = typeof CalendarViews[keyof typeof CalendarViews];
export type CalendarViews = keyof typeof CalendarViews;
