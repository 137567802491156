import createLucideIcon from '../createLucideIcon';

const AlignVerticalSpaceAround = createLucideIcon('AlignVerticalSpaceAround', [
  [
    'rect',
    { width: '10', height: '6', x: '7', y: '9', rx: '2', key: 'b1zbii' },
  ],
  ['path', { d: 'M22 20H2', key: '1p1f7z' }],
  ['path', { d: 'M22 4H2', key: '1b7qnq' }],
]);

export default AlignVerticalSpaceAround;
