import createLucideIcon from '../createLucideIcon';

const Group = createLucideIcon('Group', [
  ['path', { d: 'M3 7V5c0-1.1.9-2 2-2h2', key: 'adw53z' }],
  ['path', { d: 'M17 3h2c1.1 0 2 .9 2 2v2', key: 'an4l38' }],
  ['path', { d: 'M21 17v2c0 1.1-.9 2-2 2h-2', key: '144t0e' }],
  ['path', { d: 'M7 21H5c-1.1 0-2-.9-2-2v-2', key: 'rtnfgi' }],
  ['rect', { width: '7', height: '5', x: '7', y: '7', rx: '1', key: '1eyiv7' }],
  [
    'rect',
    { width: '7', height: '5', x: '10', y: '12', rx: '1', key: '1qlmkx' },
  ],
]);

export default Group;
