const fontWeights = ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  processSection = (section, keys, defaultValue) => {
    if (typeof section == "string") return section;
    const sectionKeys = Object.keys(section);
    let fillValue = section[sectionKeys[0]];
    return Object.fromEntries([... /* @__PURE__ */new Set([...keys, ...sectionKeys])].map(key => {
      const value = section[key] ?? defaultValue ?? fillValue;
      return fillValue = value, defaultValue = value, [key, value];
    }));
  },
  createFont = font => {
    const sizeKeys = Object.keys(font.size),
      processedFont = Object.fromEntries(Object.entries(font).map(([key, section]) => [key, processSection(section, key === "face" ? fontWeights : sizeKeys, key === "face" ? {
        normal: font.family
      } : void 0)]));
    return Object.freeze(processedFont);
  };
export { createFont };