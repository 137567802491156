import createLucideIcon from '../createLucideIcon';

const CaseUpper = createLucideIcon('CaseUpper', [
  ['path', { d: 'm3 15 4-8 4 8', key: '1vwr6u' }],
  ['path', { d: 'M4 13h6', key: '1r9ots' }],
  [
    'path',
    { d: 'M15 11h4.5a2 2 0 0 1 0 4H15V7h4a2 2 0 0 1 0 4', key: '1sqfas' },
  ],
]);

export default CaseUpper;
