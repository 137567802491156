import createLucideIcon from '../createLucideIcon';

const Ship = createLucideIcon('Ship', [
  [
    'path',
    {
      d: 'M2 21c.6.5 1.2 1 2.5 1 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1 .6.5 1.2 1 2.5 1 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1',
      key: 'iegodh',
    },
  ],
  [
    'path',
    {
      d: 'M19.38 20A11.6 11.6 0 0 0 21 14l-9-4-9 4c0 2.9.94 5.34 2.81 7.76',
      key: 'fp8vka',
    },
  ],
  ['path', { d: 'M19 13V7a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v6', key: 'qpkstq' }],
  ['path', { d: 'M12 10v4', key: '1kjpxc' }],
  ['path', { d: 'M12 2v3', key: 'qbqxhf' }],
]);

export default Ship;
