import createLucideIcon from '../createLucideIcon';

const ListRestart = createLucideIcon('ListRestart', [
  ['path', { d: 'M21 6H3', key: '1jwq7v' }],
  ['path', { d: 'M7 12H3', key: '13ou7f' }],
  ['path', { d: 'M7 18H3', key: '1sijw9' }],
  [
    'path',
    {
      d: 'M12 18a5 5 0 0 0 9-3 4.5 4.5 0 0 0-4.5-4.5c-1.33 0-2.54.54-3.41 1.41L11 14',
      key: 'qth677',
    },
  ],
  ['path', { d: 'M11 10v4h4', key: '172dkj' }],
]);

export default ListRestart;
