import createLucideIcon from '../createLucideIcon';

const Atom = createLucideIcon('Atom', [
  ['circle', { cx: '12', cy: '12', r: '1', key: '41hilf' }],
  [
    'path',
    {
      d: 'M20.2 20.2c2.04-2.03.02-7.36-4.5-11.9-4.54-4.52-9.87-6.54-11.9-4.5-2.04 2.03-.02 7.36 4.5 11.9 4.54 4.52 9.87 6.54 11.9 4.5Z',
      key: '1l2ple',
    },
  ],
  [
    'path',
    {
      d: 'M15.7 15.7c4.52-4.54 6.54-9.87 4.5-11.9-2.03-2.04-7.36-.02-11.9 4.5-4.52 4.54-6.54 9.87-4.5 11.9 2.03 2.04 7.36.02 11.9-4.5Z',
      key: '1wam0m',
    },
  ],
]);

export default Atom;
