import createLucideIcon from '../createLucideIcon';

const HardDrive = createLucideIcon('HardDrive', [
  ['line', { x1: '22', x2: '2', y1: '12', y2: '12', key: '1y58io' }],
  [
    'path',
    {
      d: 'M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z',
      key: 'oot6mr',
    },
  ],
  ['line', { x1: '6', x2: '6.01', y1: '16', y2: '16', key: 'sgf278' }],
  ['line', { x1: '10', x2: '10.01', y1: '16', y2: '16', key: '1l4acy' }],
]);

export default HardDrive;
