import createLucideIcon from '../createLucideIcon';

const AlignVerticalDistributeEnd = createLucideIcon(
  'AlignVerticalDistributeEnd',
  [
    [
      'rect',
      { width: '14', height: '6', x: '5', y: '14', rx: '2', key: 'jmoj9s' },
    ],
    [
      'rect',
      { width: '10', height: '6', x: '7', y: '4', rx: '2', key: 'aza5on' },
    ],
    ['path', { d: 'M2 20h20', key: 'owomy5' }],
    ['path', { d: 'M2 10h20', key: '1ir3d8' }],
  ],
);

export default AlignVerticalDistributeEnd;
