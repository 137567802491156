import createLucideIcon from '../createLucideIcon';

const ListVideo = createLucideIcon('ListVideo', [
  ['path', { d: 'M12 12H3', key: '18klou' }],
  ['path', { d: 'M16 6H3', key: '1wxfjs' }],
  ['path', { d: 'M12 18H3', key: '11ftsu' }],
  ['path', { d: 'm16 12 5 3-5 3v-6Z', key: 'zpskkp' }],
]);

export default ListVideo;
