import React, {forwardRef, useMemo, useState} from 'react';

import {OpaqueColorValue} from 'react-native';
import BaseAccordion, {AccordionProps as RNAccordionProps} from 'react-native-collapsible/Accordion';
import {ColorTokens, ThemeValueFallback, XStack, YStack} from 'tamagui';

import {IconView} from '../IconView';
import {Typography} from '../Typography';
import {View, ViewProps} from '../View';

import {AccordionConfig} from './config';
import {AccordionSection, AccordionVariants} from './models';

export * from './models';

// todo : Use Tamagui Accordion .

export type AccordionProps = Omit<
  RNAccordionProps<any>,
  'sections' | 'renderHeader' | 'renderFooter' | 'renderSectionTitle' | 'renderContent' | 'onChange' | 'activeSections'
> & {
  sections: AccordionSection[];
  onSectionsChange?: RNAccordionProps<AccordionSection>['onChange'];
  defaultActiveSections?: number[];
  headerBackgroundColor?: ThemeValueFallback | ColorTokens | OpaqueColorValue;
  variant?: AccordionVariants;
  containerProps?: ViewProps;
  contentContainerProps?: ViewProps;
  customeHeader?: (
    content: AccordionSection,
    index: number,
    isActive: boolean,
    sections: AccordionSection[],
  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

export const _Accordion = (
  {
    sections,
    onSectionsChange,
    customeHeader,
    defaultActiveSections,
    headerBackgroundColor,
    variant = 'filled',
    containerProps,
    contentContainerProps,
    ...props
  }: AccordionProps,
  ref,
) => {
  const [activeSections, setActiveSections] = useState(defaultActiveSections || [0]);

  const headerBackground = AccordionConfig[variant].headerBackground;
  const openedHeaderBackground = AccordionConfig[variant].openedHeaderBackground;
  const padding = AccordionConfig[variant].padding;
  const contentPadding = AccordionConfig[variant].contentPadding;

  const headerTextColor = AccordionConfig[variant].headerTextColor;
  const iconColor = AccordionConfig[variant].iconColor;

  const displayedSections = useMemo(() => sections.filter((s) => s.display !== false), [sections]);

  const renderHeader = (section: AccordionSection, _: number, isActive: boolean, __: AccordionSection[]) => {
    const isFilledAndActive = variant === 'filled' && isActive;
    return (
      <XStack
        theme="translucent"
        flexDirection={variant === 'outlined' ? 'row' : 'row-reverse'}
        justifyContent={variant === 'outlined' ? 'space-between' : 'flex-end'}
        alignItems="center"
        borderRadius={isActive ? '$8' : '$0'}
        backgroundColor={headerBackgroundColor || (isActive ? openedHeaderBackground : headerBackground)}
        padding={variant === 'filled' ? '$10' : '$0'}
        paddingVertical={variant === 'outlined' ? '$4' : '$0'}
        paddingBottom={isActive && variant === 'outlined' ? '$14' : '$4'}
        borderBottomRightRadius={isFilledAndActive ? 0 : '$2'}
        borderBottomLeftRadius={isFilledAndActive ? 0 : '$2'}>
        <YStack marginHorizontal="$2" maxWidth="90%">
          <Typography
            width="100%"
            variant={variant === 'filled' ? 'body1' : 'h6'}
            fontWeight="700"
            color={isFilledAndActive ? '$textColor' : headerTextColor}>
            {section.title}
          </Typography>
          {section.subTitle && (
            <Typography width="100%" color={isFilledAndActive ? '$textColor' : headerTextColor} variant="body2">
              {section.subTitle}
            </Typography>
          )}
        </YStack>

        <IconView
          icon={isActive ? 'ChevronUp' : 'ChevronDown'}
          size={24}
          color={isFilledAndActive ? '$textColor' : iconColor}
        />
      </XStack>
    );
  };

  const renderContent = (section: AccordionSection) => {
    return (
      <View margin={contentPadding} {...contentContainerProps}>
        {typeof section.content === 'string' ? <Typography>{section.content}</Typography> : section.content}
      </View>
    );
  };

  const updateSections = (activeSections) => {
    setActiveSections(activeSections);
    onSectionsChange && onSectionsChange(activeSections);
  };

  return (
    <View
      ref={ref}
      theme="translucent"
      paddingTop="$3"
      padding={padding}
      borderRadius="$true"
      borderColor="$border"
      borderWidth="$1"
      backgroundColor="$background"
      marginVertical="$3"
      {...containerProps}>
      <BaseAccordion
        sections={displayedSections}
        renderContent={renderContent}
        activeSections={activeSections}
        onChange={updateSections}
        renderHeader={(...args) => customeHeader?.(...args) || renderHeader(...args)}
        underlayColor="transparent"
        containerStyle={{borderRadius: 8}}
        {...props}
      />
    </View>
  );
};

export const Accordion = forwardRef(_Accordion);
