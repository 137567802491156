import createLucideIcon from '../createLucideIcon';

const BarChartHorizontalBig = createLucideIcon('BarChartHorizontalBig', [
  ['path', { d: 'M3 3v18h18', key: '1s2lah' }],
  [
    'rect',
    { width: '12', height: '4', x: '7', y: '5', rx: '1', key: '936jl1' },
  ],
  [
    'rect',
    { width: '7', height: '4', x: '7', y: '13', rx: '1', key: 'jqfkpy' },
  ],
]);

export default BarChartHorizontalBig;
