import createLucideIcon from '../createLucideIcon';

const StretchHorizontal = createLucideIcon('StretchHorizontal', [
  [
    'rect',
    { width: '20', height: '6', x: '2', y: '4', rx: '2', key: 'qdearl' },
  ],
  [
    'rect',
    { width: '20', height: '6', x: '2', y: '14', rx: '2', key: '1xrn6j' },
  ],
]);

export default StretchHorizontal;
