import createLucideIcon from '../createLucideIcon';

const PanelRight = createLucideIcon('PanelRight', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['line', { x1: '15', x2: '15', y1: '3', y2: '21', key: '1hpv9i' }],
]);

export default PanelRight;
