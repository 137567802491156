import createLucideIcon from '../createLucideIcon';

const CreativeCommons = createLucideIcon('CreativeCommons', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  [
    'path',
    {
      d: 'M10 9.3a2.8 2.8 0 0 0-3.5 1 3.1 3.1 0 0 0 0 3.4 2.7 2.7 0 0 0 3.5 1',
      key: '1ss3eq',
    },
  ],
  [
    'path',
    {
      d: 'M17 9.3a2.8 2.8 0 0 0-3.5 1 3.1 3.1 0 0 0 0 3.4 2.7 2.7 0 0 0 3.5 1',
      key: '1od56t',
    },
  ],
]);

export default CreativeCommons;
