import createLucideIcon from '../createLucideIcon';

const FileJson2 = createLucideIcon('FileJson2', [
  [
    'path',
    { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: '702lig' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  [
    'path',
    {
      d: 'M4 12a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1 1 1 0 0 1 1 1v1a1 1 0 0 0 1 1',
      key: 'fq0c9t',
    },
  ],
  [
    'path',
    {
      d: 'M8 18a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1 1 1 0 0 1-1-1v-1a1 1 0 0 0-1-1',
      key: '4gibmv',
    },
  ],
]);

export default FileJson2;
