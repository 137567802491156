import createLucideIcon from '../createLucideIcon';

const Regex = createLucideIcon('Regex', [
  ['path', { d: 'M17 3v10', key: '15fgeh' }],
  ['path', { d: 'm12.67 5.5 8.66 5', key: '1gpheq' }],
  ['path', { d: 'm12.67 10.5 8.66-5', key: '1dkfa6' }],
  [
    'path',
    {
      d: 'M9 17a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2z',
      key: 'swwfx4',
    },
  ],
]);

export default Regex;
