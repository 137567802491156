import createLucideIcon from '../createLucideIcon';

const Package2 = createLucideIcon('Package2', [
  ['path', { d: 'M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z', key: '1ront0' }],
  [
    'path',
    {
      d: 'm3 9 2.45-4.9A2 2 0 0 1 7.24 3h9.52a2 2 0 0 1 1.8 1.1L21 9',
      key: '19h2x1',
    },
  ],
  ['path', { d: 'M12 3v6', key: '1holv5' }],
]);

export default Package2;
