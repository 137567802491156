import React from 'react';

import {SketchPicker} from 'react-color';

import {Input, InputProps} from '../Input';
import {Popover} from '../Popover';
import {View} from '../View';
import {XStack} from '../XStack';

interface Props {
  value?: any;
  inputProps?: InputProps;
  onChange?: (value: string) => void;
}

export const ColorInput = ({value, onChange, inputProps}: Props) => (
  <XStack gap="$12">
    <Input
      width="$12"
      value={value}
      editable={false}
      disabled
      baseInputProps={{backgroundColor: '$background'}}
      {...inputProps}
    />
    <Popover
      contentProps={{padding: 0}}
      triggerProps={{width: '$4'}}
      trigger={
        <View borderRadius="$4" borderColor="$border" borderWidth="$0.5" width="100%" height="100%">
          <View borderRadius="$4" backgroundColor={value} height="100%" width="100%"></View>
        </View>
      }>
      <SketchPicker
        color={value || 'grey'}
        onChangeComplete={(c) => onChange?.(`${c.hex}${decimalToHex(c.rgb.a || 100)}`)}
      />
    </Popover>
  </XStack>
);

const decimalToHex = (alpha: number) => (alpha === 0 ? '00' : Math.round(255 * alpha).toString(16));
