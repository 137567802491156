import createLucideIcon from '../createLucideIcon';

const Glasses = createLucideIcon('Glasses', [
  ['circle', { cx: '6', cy: '15', r: '4', key: 'vux9w4' }],
  ['circle', { cx: '18', cy: '15', r: '4', key: '18o8ve' }],
  ['path', { d: 'M14 15a2 2 0 0 0-2-2 2 2 0 0 0-2 2', key: '1ag4bs' }],
  ['path', { d: 'M2.5 13 5 7c.7-1.3 1.4-2 3-2', key: '1hm1gs' }],
  ['path', { d: 'M21.5 13 19 7c-.7-1.3-1.5-2-3-2', key: '1r31ai' }],
]);

export default Glasses;
