import createLucideIcon from '../createLucideIcon';

const Siren = createLucideIcon('Siren', [
  ['path', { d: 'M7 12a5 5 0 0 1 5-5v0a5 5 0 0 1 5 5v6H7v-6Z', key: 'rmc51c' }],
  [
    'path',
    { d: 'M5 20a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v2H5v-2Z', key: 'yyvmjy' },
  ],
  ['path', { d: 'M21 12h1', key: 'jtio3y' }],
  ['path', { d: 'M18.5 4.5 18 5', key: 'g5sp9y' }],
  ['path', { d: 'M2 12h1', key: '1uaihz' }],
  ['path', { d: 'M12 2v1', key: '11qlp1' }],
  ['path', { d: 'm4.929 4.929.707.707', key: '1i51kw' }],
  ['path', { d: 'M12 12v6', key: '3ahymv' }],
]);

export default Siren;
