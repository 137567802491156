import createLucideIcon from '../createLucideIcon';

const Mouse = createLucideIcon('Mouse', [
  [
    'rect',
    { x: '5', y: '2', width: '14', height: '20', rx: '7', key: '11ol66' },
  ],
  ['path', { d: 'M12 6v4', key: '16clxf' }],
]);

export default Mouse;
