import createLucideIcon from '../createLucideIcon';

const ScatterChart = createLucideIcon('ScatterChart', [
  ['circle', { cx: '7.5', cy: '7.5', r: '.5', key: '1x97lo' }],
  ['circle', { cx: '18.5', cy: '5.5', r: '.5', key: '56iowl' }],
  ['circle', { cx: '11.5', cy: '11.5', r: '.5', key: 'm9xkw9' }],
  ['circle', { cx: '7.5', cy: '16.5', r: '.5', key: '14ln9z' }],
  ['circle', { cx: '17.5', cy: '14.5', r: '.5', key: '14qxqt' }],
  ['path', { d: 'M3 3v18h18', key: '1s2lah' }],
]);

export default ScatterChart;
