import createLucideIcon from '../createLucideIcon';

const MessageSquare = createLucideIcon('MessageSquare', [
  [
    'path',
    {
      d: 'M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z',
      key: '1lielz',
    },
  ],
]);

export default MessageSquare;
