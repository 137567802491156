import createLucideIcon from '../createLucideIcon';

const Wallpaper = createLucideIcon('Wallpaper', [
  ['circle', { cx: '8', cy: '9', r: '2', key: 'gjzl9d' }],
  [
    'path',
    {
      d: 'm9 17 6.1-6.1a2 2 0 0 1 2.81.01L22 15V5a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2',
      key: '69xh40',
    },
  ],
  ['path', { d: 'M8 21h8', key: '1ev6f3' }],
  ['path', { d: 'M12 17v4', key: '1riwvh' }],
]);

export default Wallpaper;
