import createLucideIcon from '../createLucideIcon';

const ArrowUpNarrowWide = createLucideIcon('ArrowUpNarrowWide', [
  ['path', { d: 'm3 8 4-4 4 4', key: '11wl7u' }],
  ['path', { d: 'M7 4v16', key: '1glfcx' }],
  ['path', { d: 'M11 12h4', key: 'q8tih4' }],
  ['path', { d: 'M11 16h7', key: 'uosisv' }],
  ['path', { d: 'M11 20h10', key: 'jvxblo' }],
]);

export default ArrowUpNarrowWide;
