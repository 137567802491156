import createLucideIcon from '../createLucideIcon';

const LayoutDashboard = createLucideIcon('LayoutDashboard', [
  ['rect', { width: '7', height: '9', x: '3', y: '3', rx: '1', key: '10lvy0' }],
  [
    'rect',
    { width: '7', height: '5', x: '14', y: '3', rx: '1', key: '16une8' },
  ],
  [
    'rect',
    { width: '7', height: '9', x: '14', y: '12', rx: '1', key: '1hutg5' },
  ],
  [
    'rect',
    { width: '7', height: '5', x: '3', y: '16', rx: '1', key: 'ldoo1y' },
  ],
]);

export default LayoutDashboard;
