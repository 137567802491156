import createLucideIcon from '../createLucideIcon';

const SunSnow = createLucideIcon('SunSnow', [
  ['path', { d: 'M10 9a3 3 0 1 0 0 6', key: '6zmtdl' }],
  ['path', { d: 'M2 12h1', key: '1uaihz' }],
  ['path', { d: 'M14 21V3', key: '1llu3z' }],
  ['path', { d: 'M10 4V3', key: 'pkzwkn' }],
  ['path', { d: 'M10 21v-1', key: '1u8rkd' }],
  ['path', { d: 'm3.64 18.36.7-.7', key: '105rm9' }],
  ['path', { d: 'm4.34 6.34-.7-.7', key: 'd3unjp' }],
  ['path', { d: 'M14 12h8', key: '4f43i9' }],
  ['path', { d: 'm17 4-3 3', key: '15jcng' }],
  ['path', { d: 'm14 17 3 3', key: '6tlq38' }],
  ['path', { d: 'm21 15-3-3 3-3', key: '1nlnje' }],
]);

export default SunSnow;
