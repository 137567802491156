import createLucideIcon from '../createLucideIcon';

const Split = createLucideIcon('Split', [
  ['path', { d: 'M16 3h5v5', key: '1806ms' }],
  ['path', { d: 'M8 3H3v5', key: '15dfkv' }],
  ['path', { d: 'M12 22v-8.3a4 4 0 0 0-1.172-2.872L3 3', key: '1qrqzj' }],
  ['path', { d: 'm15 9 6-6', key: 'ko1vev' }],
]);

export default Split;
