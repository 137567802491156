import { isClient, isWeb } from "@tamagui/constants";
import { getThemes } from "../config.mjs";
import { THEME_CLASSNAME_PREFIX, THEME_NAME_SEPARATOR } from "../constants/constants.mjs";
const emptyState = {
  name: ""
};
function getHasThemeUpdatingProps(props) {
  return !!(props.name || props.componentName || props.inverse || props.reset);
}
let uid = 0;
class ThemeManager {
  constructor(props = {}, parentManager) {
    this.props = props;
    if (uid = (uid + 1) % Number.MAX_VALUE, this.id = uid, parentManager === "root") {
      this.updateStateFromProps(props, !1);
      return;
    }
    if (!parentManager) throw process.env.NODE_ENV !== "production" ? new Error("No parent manager given, this is likely due to duplicated Tamagui dependencies. Check your lockfile for mis-matched versions. It could also be from an error somewhere else in your stack causing Tamagui to recieve undefined context, you can try putting some ErrorBoundary components around other areas of your app, or a Suspense boundary.") : "\u274C 000";
    if (this.parentManager = parentManager, !this.updateStateFromProps(props, !1)) return parentManager;
  }
  id = 0;
  themeListeners = /* @__PURE__ */new Set();
  parentManager = null;
  state = emptyState;
  updateStateFromProps(props = this.props || {}, shouldNotify = !0) {
    if (this.props = props, props.forceTheme) return this.state.theme = props.forceTheme, this.state.name = props.name || "", this.updateState(this.state, !0), this.state;
    const nextState = this.getStateIfChanged(props);
    if (nextState) return this.updateState(nextState, shouldNotify), nextState;
  }
  updateState(nextState, shouldNotify = !0) {
    this.state = nextState, this._allKeys = null, shouldNotify && queueMicrotask(() => {
      this.notify();
    });
  }
  getStateIfChanged(props = this.props, state = this.state, parentManager = this.parentManager) {
    const _ = this.getState(props, parentManager);
    if (state && state !== emptyState && !_) return parentManager?.state;
    if (this.getStateShouldChange(_, state)) return _;
  }
  getStateShouldChange(nextState, state = this.state) {
    return !(!nextState?.theme || nextState.theme === state?.theme);
  }
  getState(props = this.props, parentManager = this.parentManager) {
    return getState(props, parentManager) || null;
  }
  _allKeys = null;
  get allKeys() {
    return this._allKeys ||= /* @__PURE__ */new Set([...(this.parentManager?.allKeys || []), ...Object.keys(this.state.theme || {})]), this._allKeys;
  }
  notify(forced = !1) {
    this.themeListeners.forEach(cb => cb(this.state.name, this, forced)), process.env.NODE_ENV !== "production" && (this._numChangeEventsSent ??= 0, this._numChangeEventsSent++);
  }
  onChangeTheme(cb, debugId) {
    return process.env.NODE_ENV !== "production" && debugId && (this._listeningIds ??= /* @__PURE__ */new Set(), this._listeningIds.add(debugId)), this.themeListeners.add(cb), () => {
      this.themeListeners.delete(cb);
    };
  }
}
function getState(props, manager) {
  if (props.name && props.reset) throw new Error(process.env.NODE_ENV === "production" ? "\u274C004" : "Cannot reset and set a new name at the same time.");
  if (!getHasThemeUpdatingProps(props)) return null;
  const themes = getThemes(),
    [allManagers, componentManagers] = getManagers(manager),
    isDirectParentAComponentTheme = !!manager?.state.isComponent,
    startIndex = props.reset && !isDirectParentAComponentTheme ? 1 : 0;
  let baseManager = allManagers[startIndex],
    parentManager = allManagers[startIndex + 1];
  if (!baseManager && props.reset) return process.env.NODE_ENV !== "production" && console.warn("Cannot reset, no parent theme exists"), null;
  const {
    componentName
  } = props;
  let result = null,
    baseName = baseManager?.state.name || "";
  baseManager?.state.isComponent && (baseName = baseName.replace(/_[A-Z][A-Za-z]+/, ""));
  const nextName = props.reset ? baseName : props.name || "",
    allComponentThemes = componentManagers.map(x => x?.state.name || "");
  isDirectParentAComponentTheme && allComponentThemes.shift();
  const base = baseName.split(THEME_NAME_SEPARATOR),
    max = base.length,
    min = props.componentName && !nextName ? max : 0;
  for (let i = max; i >= min; i--) {
    let prefix = base.slice(0, i).join(THEME_NAME_SEPARATOR);
    props.inverse && (prefix = inverseThemeName(prefix));
    let potentials = [];
    if (prefix && prefix !== baseName && prefix.includes(nextName) && potentials.push(prefix), nextName && potentials.unshift(prefix ? `${prefix}_${nextName}` : nextName), i === 1) {
      const lastSegment = potentials.findIndex(x => !x.includes("_"));
      lastSegment > 0 && potentials.splice(lastSegment, 0, nextName);
    }
    if (componentName && !props.reset) {
      const baseLen = base.length;
      let componentPotentials = [];
      if (nextName && baseLen > 1) {
        const beforeSeparator = base[0];
        componentPotentials.push(`${beforeSeparator}_${nextName}_${componentName}`);
      }
      if (componentPotentials.push(`${prefix}_${componentName}`), nextName) {
        if (i > baseLen) {
          const prefixLessOne = base.slice(0, i - 1).join(THEME_NAME_SEPARATOR);
          if (prefixLessOne) {
            const lessSpecific = `${prefixLessOne}_${nextName}_${componentName}`;
            componentPotentials.unshift(lessSpecific);
          }
        }
        const moreSpecific = `${prefix}_${nextName}_${componentName}`;
        componentPotentials.unshift(moreSpecific);
      }
      potentials = [...componentPotentials, ...potentials, ...allComponentThemes];
    }
    const found = potentials.find(t => t in themes);
    if (process.env.NODE_ENV !== "production" && typeof props.debug == "string" && isClient && console.info(` \u{1F537} [${manager?.id}] getState`, {
      props,
      found,
      potentials,
      baseManager,
      nextName,
      baseName,
      prefix
    }), found) {
      const names = found.split("_"),
        [firstName, ...restNames] = names,
        lastName = names[names.length - 1],
        isComponent = lastName[0] === lastName[0].toUpperCase(),
        scheme = firstName === "light" ? "light" : firstName === "dark" ? "dark" : void 0,
        pre = THEME_CLASSNAME_PREFIX,
        className = isWeb ? `${pre}sub_theme ${pre}${!scheme || !restNames.length ? firstName : restNames.join("_")}` : "",
        parentName = (baseManager || parentManager)?.state?.name;
      result = {
        name: found,
        parentName,
        theme: themes[found],
        className,
        isComponent,
        scheme
      };
      break;
    }
  }
  return process.env.NODE_ENV !== "production" && props.debug === "verbose" && isClient && (console.groupCollapsed("ThemeManager.getState()"), console.info({
    props,
    baseName,
    base,
    min,
    max
  }), console.warn("result", {
    result
  }), console.trace(), console.groupEnd()), result;
}
const inverseThemeName = themeName => themeName.startsWith("light") ? themeName.replace(/^light/, "dark") : themeName.replace(/^dark/, "light");
function getManagers(themeManager) {
  const comp = [],
    all = [];
  let cur = themeManager;
  for (; cur;) all.push(cur), cur.state.isComponent && comp.push(cur), cur = cur.parentManager;
  return [all, comp];
}
export { ThemeManager, getHasThemeUpdatingProps, getManagers };