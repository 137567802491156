import createLucideIcon from '../createLucideIcon';

const CandyOff = createLucideIcon('CandyOff', [
  ['path', { d: 'm8.5 8.5-1 1a4.95 4.95 0 0 0 7 7l1-1', key: '1ff4ui' }],
  [
    'path',
    {
      d: 'M11.843 6.187A4.947 4.947 0 0 1 16.5 7.5a4.947 4.947 0 0 1 1.313 4.657',
      key: '1sbrv4',
    },
  ],
  ['path', { d: 'M14 16.5V14', key: '1maf8j' }],
  ['path', { d: 'M14 6.5v1.843', key: '1a6u6t' }],
  ['path', { d: 'M10 10v7.5', key: '80pj65' }],
  [
    'path',
    {
      d: 'm16 7 1-5 1.367.683A3 3 0 0 0 19.708 3H21v1.292a3 3 0 0 0 .317 1.341L22 7l-5 1',
      key: '11a9mt',
    },
  ],
  [
    'path',
    {
      d: 'm8 17-1 5-1.367-.683A3 3 0 0 0 4.292 21H3v-1.292a3 3 0 0 0-.317-1.341L2 17l5-1',
      key: '3mjmon',
    },
  ],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22', key: 'a6p6uj' }],
]);

export default CandyOff;
