import createLucideIcon from '../createLucideIcon';

const CalendarHeart = createLucideIcon('CalendarHeart', [
  [
    'path',
    {
      d: 'M21 10V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h7',
      key: '1sfrvf',
    },
  ],
  ['path', { d: 'M16 2v4', key: '4m81vk' }],
  ['path', { d: 'M8 2v4', key: '1cmpym' }],
  ['path', { d: 'M3 10h18', key: '8toen8' }],
  [
    'path',
    {
      d: 'M21.29 14.7a2.43 2.43 0 0 0-2.65-.52c-.3.12-.57.3-.8.53l-.34.34-.35-.34a2.43 2.43 0 0 0-2.65-.53c-.3.12-.56.3-.79.53-.95.94-1 2.53.2 3.74L17.5 22l3.6-3.55c1.2-1.21 1.14-2.8.19-3.74Z',
      key: '1t7hil',
    },
  ],
]);

export default CalendarHeart;
