import createLucideIcon from '../createLucideIcon';

const Utensils = createLucideIcon('Utensils', [
  ['path', { d: 'M3 2v7c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2V2', key: 'cjf0a3' }],
  ['path', { d: 'M7 2v20', key: '1473qp' }],
  [
    'path',
    { d: 'M21 15V2v0a5 5 0 0 0-5 5v6c0 1.1.9 2 2 2h3Zm0 0v7', key: '1ogz0v' },
  ],
]);

export default Utensils;
