const createNativeToast = (title, {
    message,
    notificationOptions
  }) => {
    if (!("Notification" in window)) return console.error("This browser does not support notifications"), !1;
    Notification.permission;
    const showNotification = () => new Notification(title, {
      body: message,
      ...notificationOptions
    });
    return Notification.permission === "granted" ? {
      nativeToastRef: showNotification()
    } : (Notification.requestPermission().then(permission => {
      if (permission === "granted") return {
        nativeToastRef: showNotification()
      };
    }), !0);
  },
  hideNativeToast = ref => {
    if (!("Notification" in window)) {
      console.error("This browser does not support notifications");
      return;
    }
    ref && ref.close();
  };
export { createNativeToast, hideNativeToast };