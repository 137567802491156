import { useContext, useEffect } from "react";
import { getConfig } from "../config.mjs";
import { ComponentContext } from "../contexts/ComponentContext.mjs";
import { subscribeToContextGroup, useComponentState } from "../createComponent.mjs";
import { useSplitStyles } from "../helpers/getSplitStyles.mjs";
import { Stack } from "../views/Stack.mjs";
import { useMedia } from "./useMedia.mjs";
import { useThemeWithState } from "./useTheme.mjs";
function useProps(props, opts) {
  const [propsOut, styleOut] = usePropsAndStyle(props, {
    ...opts,
    noExpand: !0,
    noNormalize: !0,
    resolveValues: "none"
  });
  return {
    ...propsOut,
    ...styleOut
  };
}
function useStyle(props, opts) {
  return usePropsAndStyle(props, opts)[1] || {};
}
function usePropsAndStyle(props, opts) {
  const staticConfig = opts?.forComponent?.staticConfig ?? Stack.staticConfig,
    [themeState, theme] = useThemeWithState({
      componentName: staticConfig.componentName
    }),
    componentContext = useContext(ComponentContext),
    {
      state,
      disabled,
      setStateShallow
    } = useComponentState(props, componentContext, staticConfig, getConfig()),
    media = useMedia(),
    splitStyles = useSplitStyles(props, staticConfig, theme, themeState.state?.name || "", state, {
      isAnimated: !1,
      mediaState: media,
      noSkip: !0,
      noMergeStyle: !0,
      noClassNames: !0,
      resolveValues: "auto",
      ...opts
    }, null, componentContext),
    {
      mediaGroups,
      pseudoGroups
    } = splitStyles;
  return useEffect(() => {
    if (!disabled) {
      if (state.unmounted) {
        setStateShallow({
          unmounted: !1
        });
        return;
      }
      return subscribeToContextGroup({
        disabled,
        componentContext,
        setStateShallow,
        state,
        mediaGroups,
        pseudoGroups
      });
    }
  }, [disabled, pseudoGroups ? Object.keys([...pseudoGroups]).join("") : 0, mediaGroups ? Object.keys([...mediaGroups]).join("") : 0]), [splitStyles.viewProps, splitStyles.style || {}, theme, media];
}
export { useProps, usePropsAndStyle, useStyle };