import createLucideIcon from '../createLucideIcon';

const PiSquare = createLucideIcon('PiSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M7 7h10', key: 'udp07y' }],
  ['path', { d: 'M10 7v10', key: 'i1d9ee' }],
  ['path', { d: 'M16 17a2 2 0 0 1-2-2V7', key: 'ftwdc7' }],
]);

export default PiSquare;
