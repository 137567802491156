import createLucideIcon from '../createLucideIcon';

const BarChart4 = createLucideIcon('BarChart4', [
  ['path', { d: 'M3 3v18h18', key: '1s2lah' }],
  ['path', { d: 'M13 17V9', key: '1fwyjl' }],
  ['path', { d: 'M18 17V5', key: 'sfb6ij' }],
  ['path', { d: 'M8 17v-3', key: '17ska0' }],
]);

export default BarChart4;
