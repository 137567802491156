import createLucideIcon from '../createLucideIcon';

const Shovel = createLucideIcon('Shovel', [
  ['path', { d: 'M2 22v-5l5-5 5 5-5 5z', key: '1fh25c' }],
  ['path', { d: 'M9.5 14.5 16 8', key: '1smz5x' }],
  [
    'path',
    {
      d: 'm17 2 5 5-.5.5a3.53 3.53 0 0 1-5 0s0 0 0 0a3.53 3.53 0 0 1 0-5L17 2',
      key: '1q8uv5',
    },
  ],
]);

export default Shovel;
