import createLucideIcon from '../createLucideIcon';

const MessageSquareDashed = createLucideIcon('MessageSquareDashed', [
  ['path', { d: 'M3 6V5c0-1.1.9-2 2-2h2', key: '9usibi' }],
  ['path', { d: 'M11 3h3', key: '1c3ji7' }],
  ['path', { d: 'M18 3h1c1.1 0 2 .9 2 2', key: '19esxn' }],
  ['path', { d: 'M21 9v2', key: 'p14lih' }],
  ['path', { d: 'M21 15c0 1.1-.9 2-2 2h-1', key: '1fo1j8' }],
  ['path', { d: 'M14 17h-3', key: '1w4p2m' }],
  ['path', { d: 'm7 17-4 4v-5', key: 'ph9x1h' }],
  ['path', { d: 'M3 12v-2', key: '856n1q' }],
]);

export default MessageSquareDashed;
