import createLucideIcon from '../createLucideIcon';

const DivideCircle = createLucideIcon('DivideCircle', [
  ['line', { x1: '8', x2: '16', y1: '12', y2: '12', key: '1jonct' }],
  ['line', { x1: '12', x2: '12', y1: '16', y2: '16', key: 'aqc6ln' }],
  ['line', { x1: '12', x2: '12', y1: '8', y2: '8', key: '1mkcni' }],
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
]);

export default DivideCircle;
