import createLucideIcon from '../createLucideIcon';

const Command = createLucideIcon('Command', [
  [
    'path',
    {
      d: 'M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3',
      key: '11bfej',
    },
  ],
]);

export default Command;
