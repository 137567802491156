import createLucideIcon from '../createLucideIcon';

const FoldVertical = createLucideIcon('FoldVertical', [
  ['path', { d: 'M12 22v-6', key: '6o8u61' }],
  ['path', { d: 'M12 8V2', key: '1wkif3' }],
  ['path', { d: 'M4 12H2', key: 'rhcxmi' }],
  ['path', { d: 'M10 12H8', key: 's88cx1' }],
  ['path', { d: 'M16 12h-2', key: '10asgb' }],
  ['path', { d: 'M22 12h-2', key: '14jgyd' }],
  ['path', { d: 'm15 19-3-3-3 3', key: 'e37ymu' }],
  ['path', { d: 'm15 5-3 3-3-3', key: '19d6lf' }],
]);

export default FoldVertical;
