import createLucideIcon from '../createLucideIcon';

const BookKey = createLucideIcon('BookKey', [
  ['path', { d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H14', key: '1gfsgw' }],
  ['path', { d: 'M20 8v14H6.5a2.5 2.5 0 0 1 0-5H20', key: 'zb0ngp' }],
  ['circle', { cx: '14', cy: '8', r: '2', key: 'u49eql' }],
  ['path', { d: 'm20 2-4.5 4.5', key: '1sppr8' }],
  ['path', { d: 'm19 3 1 1', key: 'ze14oc' }],
]);

export default BookKey;
