import createLucideIcon from '../createLucideIcon';

const HeartHandshake = createLucideIcon('HeartHandshake', [
  [
    'path',
    {
      d: 'M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z',
      key: 'c3ymky',
    },
  ],
  [
    'path',
    {
      d: 'M12 5 9.04 7.96a2.17 2.17 0 0 0 0 3.08v0c.82.82 2.13.85 3 .07l2.07-1.9a2.82 2.82 0 0 1 3.79 0l2.96 2.66',
      key: '12sd6o',
    },
  ],
  ['path', { d: 'm18 15-2-2', key: '60u0ii' }],
  ['path', { d: 'm15 18-2-2', key: '6p76be' }],
]);

export default HeartHandshake;
