import createLucideIcon from '../createLucideIcon';

const PanelLeftInactive = createLucideIcon('PanelLeftInactive', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M9 14v1', key: 'askpd8' }],
  ['path', { d: 'M9 19v2', key: '16tejx' }],
  ['path', { d: 'M9 3v2', key: '1noubl' }],
  ['path', { d: 'M9 9v1', key: '19ebxg' }],
]);

export default PanelLeftInactive;
