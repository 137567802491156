import createLucideIcon from '../createLucideIcon';

const PowerOff = createLucideIcon('PowerOff', [
  ['path', { d: 'M18.36 6.64A9 9 0 0 1 20.77 15', key: 'dxknvb' }],
  ['path', { d: 'M6.16 6.16a9 9 0 1 0 12.68 12.68', key: '1x7qb5' }],
  ['path', { d: 'M12 2v4', key: '3427ic' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
]);

export default PowerOff;
