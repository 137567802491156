import createLucideIcon from '../createLucideIcon';

const CigaretteOff = createLucideIcon('CigaretteOff', [
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22', key: 'a6p6uj' }],
  ['path', { d: 'M12 12H2v4h14', key: '91gsaq' }],
  ['path', { d: 'M22 12v4', key: '142cbu' }],
  ['path', { d: 'M18 12h-.5', key: '12ymji' }],
  ['path', { d: 'M7 12v4', key: 'jqww69' }],
  ['path', { d: 'M18 8c0-2.5-2-2.5-2-5', key: '1il607' }],
  ['path', { d: 'M22 8c0-2.5-2-2.5-2-5', key: '1gah44' }],
]);

export default CigaretteOff;
