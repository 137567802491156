import createLucideIcon from '../createLucideIcon';

const HardHat = createLucideIcon('HardHat', [
  [
    'path',
    {
      d: 'M2 18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2z',
      key: '1dej2m',
    },
  ],
  ['path', { d: 'M10 10V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5', key: '1p9q5i' }],
  ['path', { d: 'M4 15v-3a6 6 0 0 1 6-6h0', key: '1uc279' }],
  ['path', { d: 'M14 6h0a6 6 0 0 1 6 6v3', key: '1j9mnm' }],
]);

export default HardHat;
